import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ContentService } from 'src/app/content.service';
import { MDC_DIALOG_DATA, MdcDialogRef, MdcTextField } from '@angular-mdc/web';

@Component({
  selector: 'app-endre-film',
  templateUrl: './endre-film.component.html',
  styleUrls: ['./endre-film.component.scss']
})
export class EndreFilmComponent implements OnInit {
  @ViewChild('search', { static: false, read: MdcTextField}) searchElm: MdcTextField;
  public filmer;
  public forestillinginfo;
  public valgFilmtittel;
  public searchForm= new FormGroup({
    searchField: new FormControl()
  });
  public form = new FormGroup({
    film: new FormControl()
  })
  constructor(
    private contentService: ContentService,
    @Inject(MDC_DIALOG_DATA) private data,
    private dialogRef: MdcDialogRef<EndreFilmComponent>
  ) { }

  ngOnInit() {
    this.forestillinginfo = this.data.forestillinginfo;
    this.dialogRef.beforeClosed().subscribe(res => {
      console.log(res);
      return false;
    })
  }

  sok() {
    function createSearchFilter(field, query) {
      return `{"${field}":"ilike(\\"%${query}%\\")"}`;
    }
    const searchTerm = this.searchForm.value.searchField;
    const currentFilter = createSearchFilter('norsktittel', searchTerm);
    this.contentService.fetchContent('/film?where=' + encodeURI(currentFilter))
      .subscribe(filmer => {
          this.filmer = filmer._items;
      });
  }

  velgFilm(film) {
    this.form.controls.film.setValue(film);
  }

  fjernFilm() {
    this.form.controls.film.setValue(null);
    this.filmer = null;
    this.searchForm.controls.searchField.setValue(null);
    setTimeout(() => {
      this.searchElm.focus()
    });
  }

  lagre(){
    this.contentService.updateContent('/forestilling/' + this.forestillinginfo.id, {
      film_id: this.form.value.film.id
    }, null)
    .subscribe(res => {
      this.dialogRef.close(true);
    })
  }

}
