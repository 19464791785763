import { Component, Inject, OnInit } from '@angular/core';
import { MDC_DIALOG_DATA } from '@angular-mdc/web';
import { ContentService } from '../../../content.service';
import { markupReport, printReport } from '../../../salg/print-stuff';

@Component({
  selector: 'app-z-list',
  templateUrl: './z-list.component.html',
  styleUrls: ['./z-list.component.scss']
})
export class ZListComponent implements OnInit {

  public zlist;
  public rapportData: any;
  public rapportHTML: string;

  constructor(
    @Inject(MDC_DIALOG_DATA) public data,
    private contentService: ContentService
  ) {}

  ngOnInit() {
    this.contentService.fetchContent(`/salg/kasse/${this.data.id}/zrapport`)
      .subscribe( (res: any) => {
        console.log('Rapporter', res);
        this.zlist = res;
      });
  }

  clickShowReport(z) {
    if (z && z.z_rapp_id) {
      this.rapportHTML = 'Henter Z-Rapport...';
      let url = `/salg/kasse/${z.kasse_id}/zrapport/${z.id}`;
      this.contentService.fetchContent(url).subscribe( res => {
        if (res && res.report) {
          this.rapportData = res.report;
          this.rapportHTML = markupReport(res.report);
        }
      });
    } else {
      // klikk på disabla eller datafeil
    }
  }

  clickPrint() {
    printReport(this.rapportData);
  }

  clickBack() {
    delete this.rapportHTML;
    delete this.rapportData;
  }

}