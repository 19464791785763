import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MdcDialogRef, MdcDialogAction } from '@angular-mdc/web';

@Component({
  selector: 'app-selg-gavekort',
  templateUrl: './selg-gavekort.component.html',
  styleUrls: ['./selg-gavekort.component.scss']
})
export class SelgGavekortComponent implements OnInit {

  public numberOfGavekort: number = 1;
  public totalValue: number = 0;

  public gavekortForm = new FormGroup({
    gavekortListe: new FormArray([])
  })
  public form = new FormGroup({
    antall: new FormControl(1),
    verdi: new FormControl(null)
  });

  constructor(
    private dialogRef: MdcDialogRef<SelgGavekortComponent>
  ) { }

  ngOnInit() {
    this.gavekortListe.push(this.form);
    this.gavekortForm.valueChanges.subscribe(
      res => {
        this.updateValues();
      }
    )
  }

  get gavekortListe(): FormArray {
    return (this.gavekortForm.get('gavekortListe') as FormArray);
  }

  addGavekort() {
    const form = new FormGroup({
      antall: new FormControl(1),
      verdi: new FormControl(null)
    })
    this.gavekortListe.push(form);
  }

  updateValues() {
    this.numberOfGavekort = 0;
    this.totalValue = 0;
    for (var i = 0; i < this.gavekortListe.value.length; i++) {
      this.numberOfGavekort += this.gavekortListe.value[i].antall;
      this.totalValue += (this.gavekortListe.value[i].antall * this.gavekortListe.value[i].verdi);
    }
  }

  // reset() {
  //   this.gavekortVerdi.setValue(null);
  // }

  submit() {
    this.onSubmit();
  }

  onSubmit() {
    this.dialogRef.close({
      gavekortListe: this.gavekortForm.value.gavekortListe
    });
  }
}
