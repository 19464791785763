<app-spinner *ngIf="showSpinner"></app-spinner>

<div class="kasse__header" *ngIf="kasse">
  <div>
    <a routerLink="/" class="kasse__backlink">Tilbake til Kinologg</a>
    <h1 mdcHeadline2>{{ maskinist?.fornavn }} {{ maskinist?.etternavn }} sin kasse</h1>
  </div>
  <app-kassevalg
    [spillesteder]="dagens_spillesteder"
    [forestillinger]="dagens_forestillinger"
    [maskinist]="maskinist"
    [kasse]="kasse"
  ></app-kassevalg>
</div>

<div class="kasse__main" *ngIf="kasse">
  <mdc-card class="kasse__dagsinfo">
    <p *ngIf="kasse.aapen">Kassabeholdning ved oppstart {{ currentDate | date:'d.M.yyyy' }}</p>
    <div>
      <mdc-text-field outlined label="Kassebeholdning" type="number" [formControl]="kassasum"></mdc-text-field>
    </div>
    <button *ngIf="!kasse.aapen" mdc-button raised [disabled]="!kassasum.value && kassasum.value != 0" (click)="startSale()">
      <span>Start dagens salg</span>
    </button>

    <p *ngIf="kasse.aapen" class="dagens salg">Dagens salg er startet</p>

    <ng-container *ngIf="kasse.aapen">
      <button mdc-button unelevated *ngIf="canEndDay()" (click)="confirmEndDay()">
        <span>Dagsoppgjør</span>
      </button>
      <button mdc-button class="button-no-bg" *ngIf="! canEndDay()" (click)="confirmEndDay()">
        <span>Dagsoppgjør</span>
      </button>
    </ng-container>

  </mdc-card>

  <div class="kasse__forestillinger">

    <ng-container *ngIf="tidligere_forestillinger?.length">
      <h2 mdcHeadline4>Tidligere uavsluttede forestillinger</h2>
      <mdc-list interactive="false" class="forestillinger__tidligere">
        <mdc-list-item *ngFor="let forestilling of tidligere_forestillinger">
          <span class="list-item-text">{{ forestilling.dato | date: 'd.M.yyyy' }} {{ forestilling.tidspunkt }} {{ forestilling.film.norsktittel }} <span class="film__versjon">{{ forestilling.film.version_subtitle }}</span></span>
          <span class="list-item-text--secondary">Antall: {{forestilling.n_solgt}} Pris: {{forestilling.bill_pris}}</span>
          <button mdc-button unelevated (click)="avsluttForestilling(forestilling)">Avslutt</button>
        </mdc-list-item>
      </mdc-list>
    </ng-container>

    <h2 mdcHeadline4>Aktuelle forestillinger</h2>
    <mdc-list>
      <ng-container *ngFor="let sted of dagens_pr_sted">
        <h4 mdcBody1 class="text__medium-emphasis forestillinger__sted">{{ sted[0].spillested.navn }},
            {{ sted[0].spillested.stedsnavn }}</h4>
        <ng-container *ngFor="let forestilling of sted">
          <mdc-list-item disabled *ngIf="forestilling.avsluttet || ! kasse.aapen">
            <span class="list-item-text">{{ forestilling.tidspunkt }} {{ forestilling.film.norsktittel }}
              <span class="film__versjon">{{ forestilling.film.version_subtitle }}</span>
              <span *ngIf="forestilling.warning_other_date"><b>{{ forestilling.dato | date: 'd.M.yyyy' }}</b></span>
            </span>
            <span class="list-item-text--secondary">Antall: {{forestilling.n_solgt}} Pris: {{forestilling.bill_pris}}</span>
            <span class="list-item-text--secondary" *ngIf="forestilling.avsluttet">Avsluttet</span>
            <button mdc-button class="button-no-bg" *ngIf="! forestilling.avsluttet" (click)="avsluttForestilling(forestilling)">Avslutt</button>
          </mdc-list-item>
          <a mdc-list-item *ngIf="! forestilling.avsluttet && kasse.aapen"
            [routerLink]="['/salg', 'forestilling', forestilling.id]">
            <span class="list-item-text">{{ forestilling.tidspunkt }} {{ forestilling.film.norsktittel }}
              <span class="film__versjon">{{ forestilling.film.version_subtitle }}</span>
              <span *ngIf="forestilling.dato && !forestilling.warning_other_date"> {{ forestilling.dato | date: 'd.M.yyyy' }}</span>
              <span *ngIf="forestilling.dato && forestilling.warning_other_date"><b> NB! {{ forestilling.dato | date: 'd.M.yyyy' }}</b></span>
            </span>
            <span class="list-item-text--secondary">Antall: {{forestilling.n_solgt}} Pris: {{forestilling.bill_pris}}</span>
            <span class="list-item-text--secondary" *ngIf="forestilling.er_avlyst">AVLYST</span>
            <mdc-icon mdcListItemMeta>chevron_right</mdc-icon>
          </a>
        </ng-container>
      </ng-container>
    </mdc-list>
    <button mdc-fab mini class="kasse__forestillinger__legg-til" (click)="leggTilForestilling()">
      <mdc-icon>add</mdc-icon>
    </button>
  </div>

</div>
