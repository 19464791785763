<mdc-dialog>
  <mdc-dialog-container>
    <mdc-dialog-surface>
      <mdc-dialog-title>{{data.title}}</mdc-dialog-title>
      <mdc-dialog-content>{{data.message}}</mdc-dialog-content>
      <mdc-dialog-actions>
        <button mdcDialogButton mdcDialogAction="close">Avbryt</button>
        <button mdcDialogButton mdcDialogAction="accept" default>Ok</button>
      </mdc-dialog-actions>
    </mdc-dialog-surface>
  </mdc-dialog-container>
</mdc-dialog>