<mdc-dialog>
  <mdc-dialog-container>
    <mdc-dialog-surface>
      <mdc-dialog-title>Bekreft</mdc-dialog-title>
      <mdc-dialog-content>Legg til stabsbillett?</mdc-dialog-content>
      <mdc-dialog-actions>
        <button mdc-button class="button-no-bg" mdcDialogAction="close">Avbryt</button>
        <button mdc-button raised default mdcDialogAction="accept">Ok</button>
      </mdc-dialog-actions>
    </mdc-dialog-surface>
  </mdc-dialog-container>
</mdc-dialog>
