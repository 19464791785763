import { Component, Input, ViewChild } from '@angular/core';
import { MdcDialog } from '@angular-mdc/web';
import { Router } from '@angular/router';

import { RefunderBilletterComponent } from '../dialogs/refunder-billetter/refunder-billetter.component';
import { BillettscanLenkeComponent } from '../dialogs/billettscan-lenke/billettscan-lenke.component';
import { XzrapportComponent } from '../../common-components/dialogs/xzrapport/xzrapport.component';
import { ZListComponent } from '../dialogs/z-list/z-list.component';
import { SjekkGavekortComponent } from '../dialogs/sjekk-gavekort/sjekk-gavekort.component';
import { BesoksrapportComponent } from '../dialogs/besoksrapport/besoksrapport.component';


@Component({
  selector: 'app-kassevalg',
  templateUrl: './kassevalg.component.html',
  styleUrls: ['./kassevalg.component.scss']
})
export class KassevalgComponent {


  @ViewChild('moreMenu', {static: false}) menuVC;
  @Input() public disabled = false;
  @Input() public kasse;
  @Input() public spillesteder: Array<any>;
  @Input() public forestillinger: Array<any>;
  @Input() public maskinist;

  constructor(
    private dialog: MdcDialog,
    private router: Router
  ) {}

  refunderBilletter() {
    this.dialog.open(RefunderBilletterComponent).afterClosed()
      .subscribe((res: any) => {
        if (res !== 'close' && res.fid) {
          // Bruk dagens førstkommende forestilling (hvis den finnes) - hvis ikke, ordrens forestilling
          const fid = (this.forestillinger.length ? this.forestillinger[0].id : res.fid);
          const q = {
            refund_what: res.refund_what,
            refund_type: res.refund_type,
            refund_id: res.id
          }
          this.router.navigate(['salg', 'forestilling', fid], { queryParams: q })
        }
      });
  }

  visXrapport() {
    this.dialog.open(XzrapportComponent, { data: { type: 'x' } });
  }

  visZrapporter() {
    this.dialog.open(ZListComponent, {data: {id: this.kasse.id}});
  }

  showBillettscannerlenke(sted) {
    this.dialog.open(BillettscanLenkeComponent, {
      data: {
        spillested_id: sted.id,
        spillested_tittel: `${sted.navn}, ${sted.stedsnavn}`
      }
    });
    // av en eller annen grunn, lukkes ikke menyen av seg selv her...
    this.menuVC.open = false;
  }

  sjekkGavekort() {
    this.dialog.open(SjekkGavekortComponent);
  }

  showBesoksrapportDialog() {
    const data = { maskinist: this.maskinist };
    this.dialog.open(BesoksrapportComponent, { data });
  }

}
