<div class="andre__produkter">

    <h2 mdcHeadline5>Andre billetter</h2>

    <div class="options">
        <button mdc-button outlined [disabled]="disabled" (click)="addStab()" class="button-no-bg">Stabsbillett</button>
        <button mdc-button outlined [disabled]="disabled" (click)="addLedsager()" class="button-no-bg">Ledsager</button>
        <button mdc-button outlined [disabled]="disabled" (click)="betalKampanje()" class="button-no-bg">Kupong</button>
    </div>

</div>