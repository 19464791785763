<section class="reset-passord-page">
  <h1 mdcHeadline1>Kinologg</h1>
  <form [formGroup]="reset" (ngSubmit)="resetPwd()">
    <mdc-form-field fluid>
      <mdc-text-field type="password" outlined label="Velg nytt passord" formControlName="password1"></mdc-text-field>
    </mdc-form-field>
    <mdc-form-field fluid>
      <mdc-text-field type="password" outlined label="Gjenta nytt passord" formControlName="password2"></mdc-text-field>
    </mdc-form-field>
    <div class="login__actions">
      <button
        mdc-button
        type="submit"
        primary
        raised
        [disabled]="!reset.valid"
      >
      Lagre nytt passord</button>
    </div>
    <div class="form-error"></div>
  </form>
</section>
