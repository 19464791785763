import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  login;
  reset;
  showResetPwd = false;
  resetConfirm = false;

  constructor(
    public auth: AuthService,
    private router: Router
  ) { }

  ngOnInit() {

      this.login = new FormGroup({
        username: new FormControl('', Validators.required),
        password: new FormControl('', Validators.required)
      });

      this.reset = new FormGroup({
        username: new FormControl('', Validators.required)
      });

  }

  logIn() {
    this.auth.authenticate(this.login.value.username, this.login.value.password)
      .pipe(
        catchError(this.errorHandler)
      )
      .subscribe( res => {
        this.router.navigate(['/']); // TODO: go to previous location
      });
  }

  toggleReset() {
    this.showResetPwd = !this.showResetPwd;
    this.resetConfirm = false;
  }

  resetPwd() {
    // TODO: generate email with user token if email adress is in system
    this.auth.resetPassword(this.reset.value.username)
      .subscribe( res => {
        if (res['result'] === 'OK') {
          this.resetConfirm = true;
        } else {
          alert('Klarte ikke sende reset passord email :(');
        }
      });
  }

  private errorHandler(error) {
    if (error.statusText === 'UNAUTHORIZED') {
      alert('Feil brukernavn eller passord');
    }
    return throwError(error);
  }

}
