import { Component, Output, EventEmitter, Input } from '@angular/core';
import { MdcDialog } from '@angular-mdc/web';
import { FakturaBetalingComponent } from '../dialogs/faktura-betaling/faktura-betaling.component';
import { TilgodebillettBetalingComponent } from '../dialogs/tilgodebillett-betaling/tilgodebillett-betaling.component';
import { GavekortBetalingComponent } from '../dialogs/gavekort-betaling/gavekort-betaling.component';

@Component({
  selector: 'app-spesial-betaling',
  templateUrl: './spesial-betaling.component.html',
  styleUrls: ['./spesial-betaling.component.scss']
})
export class SpesialBetalingComponent {

  @Input() disabled: boolean;
  @Output() betalt = new EventEmitter<Betaling>();
  @Input() public ordre;

  constructor(
    private dialog: MdcDialog
  ) { }

  betalTilgodebillett() {
    this.dialog.open(TilgodebillettBetalingComponent)
      .afterClosed().subscribe((res: any) => {
        if (res && res.tilgodebillett_kode) {
          this.betalt.emit({
            betalingstype: 'tilgodelapp',
            tilgodebillett_kode: res.tilgodebillett_kode
          });
        }
      });
  }

  betalGavekort() {
    this.dialog.open(GavekortBetalingComponent)
      .afterClosed().subscribe((res: any) => {
        if (res && res.gavekort_kode) {
          this.betalt.emit({
            betalingstype: 'gavekort',
            gavekort_kode: res.gavekort_kode,
            belop: res.gavekort_verdi
          });
        }
      });
  }

  betalFaktura() {
    this.dialog.open(FakturaBetalingComponent)
      .afterClosed().subscribe((res: any) => {
        if (res && res.fakturamottaker && res.fakturaadresse) {
          this.betalt.emit({
            betalingstype: 'faktura',
            fakturamottaker: res.fakturamottaker,
            fakturareferanse: res.fakturareferanse,
            fakturaadresse: res.fakturaadresse,
            skip_billett_print: res.skip_billett_print
          });
        } else {
          // avbrutt / mangler påkrevde data
        }
      });
  }

  har_ikkekorrigerte_transaksjoner(): Boolean {
    if (!this.ordre || !this.ordre.trans) return false;
    // filtrer bort korrigerte faktura-/gavekort-transaksjoner
    return 0 < this.ordre.trans.filter( trans =>
      ( trans.betalingstype === 'faktura' || trans.betalingstype === 'gavekort') &&
      ( trans.er_korrigert !== true && trans.er_korreksjon !== true )
    ).length;
  }

}
