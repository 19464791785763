<mdc-dialog>
  <mdc-dialog-container>
    <mdc-dialog-surface>
      <mdc-dialog-title>Refunder billetter</mdc-dialog-title>
      <mdc-dialog-content>

        <div class="refund__registrer-billett" *ngIf="! ordre">
          <h3>Scann QR-kode</h3>
          <video #scanVideoElm></video>
          <h3>Eller skriv inn billettnummer:</h3>
          <div class="registrer-billett__nummer">
            <form [formGroup]="billettnummerForm">
              <mdc-text-field  outlined label="Nummer" formControlName="billettnummer"></mdc-text-field>
              <button mdc-button primary raised (click)="searchBillett(billettnummer.value)">OK</button>
            </form>
          </div>
          <div *ngIf="billettSearchError" class="billettsearch-error">{{ billettSearchError }}</div>
        </div>

        <ng-container *ngIf="ordre">
          <div class="ordre__forestilling-info">
            <p>{{ spillested.navn }}, {{ spillested.stedsnavn }}</p>
            <p>{{ forestilling.film.norsktittel}}</p>
            <p>{{ forestilling.dato | date: 'd.M.yyyy' }} {{ forestilling.tidspunkt }}</p>
          </div>

          <form [formGroup]="refundTypeForm" class="refund__type" #initialfocuselem>
            <mdc-radio-group formControlName="refund_type" [disabled]="antallUrefunderte < 1">
              <mdc-form-field *ngFor="let rf of refundTypes">
                <mdc-radio [value]="rf"></mdc-radio>
                <label>{{fmtPaymentType(rf)}}</label>
              </mdc-form-field>
            </mdc-radio-group>
          </form>

          <ng-container *ngIf="ordre.billetter.length > 1">
            <p class="ordre__totalsum" *ngIf="ordre_kontantrefundering_mulig">Totalsum å refundere for hele ordren: {{ ordre.sum - tidligereRefundert }} kr</p>
            <p *ngIf="matchUUIDs(ordre.uuid, billettnummer.value)">Scannet kode tilhører hele ordren</p>
            <ul>
              <li *ngFor="let b of ordre.billetter">
                <span class="ordre__enkeltbillett" [ngClass]="{'aktivbillett': matchUUIDs(b.uuid, billettnummer.value)}">
                  <span *ngIf="b.billetttype === 'norgesbillett'">1 Norgesbillett</span>
                  <span *ngIf="b.billetttype === 'ledsager'">1 Ledsagerbillett</span>
                  <span *ngIf="b.billetttype === 'stab'">1 Stabsbillett</span>
                  <span *ngIf="b.billetttype === 'normal'">1 billett: {{ b.belop }} kr</span>
                </span>
                <ng-container *ngIf="matchUUIDs(b.uuid, billettnummer.value)  && ! b.refundert">
                  <button *ngIf="ordre" [disabled]="!b.kontantrefundering_mulig && refundTypeForm.value.refund_type === 'cash'" mdc-button secondary raised (click)="refundTicket(b.uuid)">
                    <span *ngIf="b.billetttype === 'normal' || b.billetttype === 'norgesbillett'">Refunder denne</span>
                    <span *ngIf="b.billetttype === 'ledsager' || b.billetttype === 'stab'">Slett billett</span>
                  </button>
                </ng-container>
                <span *ngIf="b.refundert">Allerede refundert</span>
              </li>
            </ul>
          </ng-container>
          <div *ngIf="ordre.billetter.length === 1">
            Beløp: {{ ordre.sum }} <span *ngIf="ordre.billetter[0].refundert">Allerede refundert</span>
          </div>


        </ng-container>
      </mdc-dialog-content>
      <mdc-dialog-actions>
        <button mdc-button class="button-no-bg" mdcDialogAction="close">Avbryt</button>
        <button *ngIf="ordre && ordre.billetter.length === 1" [disabled]="antallUrefunderte < 1" mdc-button raised (click)="refundTicket(ordre.billetter[0].uuid)">Refunder</button>
        <button *ngIf="ordre && ordre.billetter.length > 1"
          [disabled]="antallUrefunderte < 1 || (!ordre_kontantrefundering_mulig && refundTypeForm.value.refund_type === 'cash')"
          mdc-button raised
          (click)="refundOrdre(ordre.uuid)">Refunder ordre</button>
      </mdc-dialog-actions>
    </mdc-dialog-surface>
  </mdc-dialog-container>
</mdc-dialog>