import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ContentService } from 'src/app/content.service';

@Component({
  selector: 'app-public-ruteplan',
  templateUrl: './public-ruteplan.component.html',
  styleUrls: ['./public-ruteplan.component.scss']
})
export class PublicRuteplanComponent implements OnInit {

  ringId;
  periodOptions;
  periodSelectForm = new FormGroup({
    year: new FormControl( new Date().getFullYear() )
  });

  weekdays = [];
  ring;
  ruteplan;

  constructor(
    private route: ActivatedRoute,
    private contentService: ContentService
  ) {}

  ngOnInit() {
    this.contentService.fetchContent('/public/ruteplan/aar')
      .subscribe( res => this.periodOptions = res );
    this.periodSelectForm.valueChanges
      .subscribe( ch => this.fetchRuteplan() );
    this.route.params.subscribe( params => {
      this.ringId = params.ringId;
      this.fetchRuteplan();
    });
  }

  fetchRuteplan() {
    delete this.ruteplan;
    const year = this.periodSelectForm.value.year || new Date().getFullYear();
    this.contentService.fetchContent(
      `/public/ring/${this.ringId}/ruteplan?from=${year}-01-01&to=${year}-12-31`
    ).subscribe(plan => {
      this.parseRuteplan(plan);
    });
  }

  parseRuteplan(plan) {
    this.ring = plan.ring;
    // spillested_id => sted
    const steder = {};
    plan.spillesteder.forEach(sted => steder[sted.id] = sted);
    // program_id => program_nr
    const progs = {};
    plan.program.forEach(prog => progs[prog.id] = prog.nr);

    // fyll inn programnummer og spillesteder
    plan.ruteplan.forEach( plan => {
      plan.program_nr = progs[plan.program_id] || 'n/a';
      // dager med spillested_id får spillested:
      plan.days.filter(
        day => !!day.spillested_id
      ).forEach(
        day => day.spillested = steder[day.spillested_id]
      );
    });
    this.ruteplan = plan.ruteplan;
    // Finn planen med flest dager og generer dagnavn-array som matcher antall rader
    const startdag = this.toTitleCase(this.ring.startdag || 'fredag');
    const rader = Math.max( ...plan.ruteplan.map( plan => plan.days.length ));
    const dager = ['Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag', 'Søndag'];
    const start = dager.indexOf(startdag);
    this.weekdays.length = 0;
    for (let i = 0; i < rader; i++) {
      this.weekdays.push(
        dager[ (start + i) % 7 ]
      );
    }
  }

  private toTitleCase(str) {
    return str.charAt(0).toUpperCase() + str.substr(1).toLowerCase();
  }

}
