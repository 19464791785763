import { Component, Inject } from '@angular/core';
import { MDC_DIALOG_DATA, MdcDialogRef } from '@angular-mdc/web';
import { FormControl, Validators } from '@angular/forms';
import { ContentService } from 'src/app/content.service';

const NB_ERRORS = [
  'Teknisk feil – prøv igjen senere',/* nett- / server- / parse- / api-problem */
  'Alt vel (vises ikke)',
  'Ugyldig billettkode',
  'Billetten er ikke gyldig enda (fra-dato i fremtid)',
  'Billetten er utdatert (til-dato passert)',
  'Billetten er allerede brukt',
  'Dette skal ikke skje (voucherName mismatch)',
  'Dette skal ikke skje (ugyldig kinoId)'
];

const NB_FORESTILLING = 1; // hardkoda forestilling-id for Norgesbillett-API-et

@Component({
  selector: 'app-kampanje-betaling',
  templateUrl: './kampanje-betaling.component.html',
  styleUrls: ['./kampanje-betaling.component.scss']
})
export class KampanjeBetalingComponent {

  public kodeinput = new FormControl('', [Validators.required]);

  feilmelding: string;
  pending = false;

  constructor(
    private dialogRef: MdcDialogRef<KampanjeBetalingComponent>,
    private contentService: ContentService,
    @Inject(MDC_DIALOG_DATA) private data: any // skal inneholde forestilling_id
  ) {
    this.kodeinput.valueChanges.subscribe( res => this.feilmelding = '' );// tja
    if (!this.data.forestilling_id) console.warn('Mangler forestilling.');
  }

  sjekk() {
    if (this.pending) return;
    this.feilmelding = '';
    const kode = this.kodeinput.value;
    // ikke-norgesbillett-koder inneholder bindestrek
    if (kode.indexOf('-') > -1) {
      // sjekk mot egen backend
      this.pending = true;
      this.contentService.fetchContent(`/salg/kampanje_verify/${kode}/${this.data.forestilling_id}`).subscribe(
        res => {
          this.pending = false;
          if (res.code === 1) {
            this.dialogRef.close({ kb_kode: kode });
          } else if (res.code === 2) {
            this.feilmelding = res.message;
          } else {
            console.warn(`Uhåndtert: ${res.code}`);
          }
        }
      );
    } else {
      // ingen bindestreker - kanskje norgesbillett...
      if (kode.length !== 8) {
        this.feilmelding = 'Norgesbillettkode må være eksakt 8 tegn';
        return;
      }
      this.pending = true;
      this.contentService.fetchContent(`/nb_verify/${kode}/${NB_FORESTILLING}`).subscribe(
        res => {
          this.pending = false;
          if (res.code === 1) {
            // billettkode er gyldig og klar til bruk
            this.dialogRef.close({ nb_kode: kode });
          } else {
            this.feilmelding = NB_ERRORS[res.code];
          }
        }
      )
    }
  }


}
