import { Component, Inject, OnInit } from '@angular/core';
import { MDC_DIALOG_DATA, MdcDialogRef } from '@angular-mdc/web';
import { FormControl } from '@angular/forms';
import { ContentService } from 'src/app/content.service';

@Component({
  selector: 'app-dialog-send-gavekort',
  templateUrl: './dialog-send-gavekort.component.html',
  styleUrls: ['./dialog-send-gavekort.component.scss']
})
export class DialogSendGavekortComponent implements OnInit {
  public gavekort;
  public ordreid;
  public epost = new FormControl();

  constructor(
    @Inject(MDC_DIALOG_DATA) private data,
    private dialogRef: MdcDialogRef<any>,
    private contentService: ContentService
  ) { }

  ngOnInit(): void {
    this.ordreid = this.data.ordreid;
    this.gavekort = this.data.gavekort;
    if (this.gavekort.mottaker) {
      this.epost.setValue(this.gavekort.mottaker.epost);
    }
  }

  sendGavekort() {
    const data = {
      epost: this.epost.value,
      gavekort_id: this.gavekort.id
    };
    this.contentService.postContent('/admin/ordre/' + this.ordreid + '/send_gavekort', data).subscribe( res => {
      if( res.result === 'success') {
        this.dialogRef.close('sendt');
      }
      else {
        alert("Sending feilet");
      }
    })
  }

}
