import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MdcDialog, MdcSnackbar } from '@angular-mdc/web';
import { AuthService } from '../../auth.service';
import { DialogConfirmComponent } from '../dialogs/dialog-confirm/dialog-confirm.component';
import { ContentService } from '../../content.service';
import { DialogVelgMaskinistComponent } from '../dialogs/dialog-velg-maskinist/dialog-velg-maskinist.component';
import { environment } from '../../../environments/environment';


@Component({
  selector: 'app-kjoreplan-valg',
  templateUrl: './kjoreplan-valg.component.html',
  styleUrls: ['./kjoreplan-valg.component.scss']
})
export class KjoreplanValgComponent implements OnInit {
  @Input() public spilledag: any;
  @Output() public valgt = new EventEmitter<any>();

  public sett_selv_enabled = false;
  public sett_annen_enabled = false;
  public adminuser = false;
  public nettsalgUrl = environment.production ? window.origin + "/nettsalg" : 'http://localhost:5000/nettsalg';

  constructor(
    public dialog: MdcDialog,
    private auth: AuthService,
    private contentService: ContentService,
    private snackbar: MdcSnackbar
  ) {}

  ngOnInit() {
    const user = this.auth.getUserInfo();
    this.adminuser = user.adminuser;
    const myId = user.maskinist;
    this.sett_selv_enabled = (
      myId &&
      myId !== this.spilledag.alt_maskinist_id &&
      myId !== this.spilledag.maskinist.id
    );
    this.sett_annen_enabled = (
      myId &&
      myId === this.spilledag.maskinist.id
    );
  }

  clickVelgMegSelv() {
    this.dialog.open( DialogConfirmComponent, {
      data: {
        title: 'Bekreft valg',
        message: 'Vil du gjøre deg selv til alternativ maskinist for denne spilledagen?'
      }
    }).afterClosed().subscribe(res => {
      if (res === 'accept') {
        this.settAlternativMaskinist(this.auth.getUserInfo().maskinist);
      }
    });
  }

  clickVelgAnnen() {
    this.dialog.open( DialogVelgMaskinistComponent ).afterClosed()
      .subscribe( (res: any) => {
        if (res && res.alt_maskinist_id) {
          this.settAlternativMaskinist(res.alt_maskinist_id);
        }
      });
  }

  settAlternativMaskinist(maskinist_id) {
    this.contentService.postContent(`/ruteplan/${this.spilledag.id}/alt_maskinist`, {alt_maskinist_id: maskinist_id})
      .subscribe(res => {
        this.valgt.emit();
      });
  }

  spilledagHarPublisertFilm(dag): Boolean {
    return ( dag && dag.forestillinger && !!dag.forestillinger.find(f => f.film.klar_for_publisering) );
  }

  clickDownloadPoster(id: String) {
    window.open(`${environment.BACKEND_SERVER_URL}/ruteplan/${id}/poster`, '_blank');
  }

  kanSendeEpost(dag): Boolean {
    // TODO: dag.spillested.epost_plakat || dag.spillested.epost_ansvarlig
    return ( this.adminuser && dag.forestillinger.length && !dag.er_avlyst )
  }

  clickSendReminder(dag) {
    this.contentService.postContent(`/ruteplan/${dag.id}/remind`, {})
    .subscribe( res => {
      if (res && res.msg) {
        this.snackbar.open(res.msg, null, { dismiss: true, trailing: true } );
      }
    });
  }

  clickAapneNettsalg(forestilling) {
    window.open(`${this.nettsalgUrl}/${forestilling.id}`, '_blank');
  }

}
