import { LandingssideComponent } from './landingsside/landingsside/landingsside.component';
import { KjoreplanComponent } from './common-components/kjoreplan/kjoreplan.component';
import { LoginComponent } from './login/login.component';
import { AuthGuardService } from './auth-guard.service';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GrunnlagsdataResolverService } from './grunnlagsdata-resolver.service';
import { ResetPassordComponent } from './reset-passord/reset-passord.component';
import { KjoreplanSpillestedComponent } from './common-components/kjoreplan-spillested/kjoreplan-spillested.component';
import { FakturaListeComponent } from './common-components/faktura-liste/faktura-liste.component';
import { PageComponent } from './page/page.component';
import { PublicRuteplanComponent } from './common-components/public-ruteplan/public-ruteplan.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'resetpassword/:token',
    component: ResetPassordComponent
  },
  {
    path: 'ruteplan/:ringId',
    component: PublicRuteplanComponent
  },
  {
    path: 'plan-spillested',
    component: KjoreplanSpillestedComponent
  },
  {
    path: 'plan-spillested/:id',
    component: KjoreplanSpillestedComponent
  },
  {
    path: 'salg',
    loadChildren: () => import('./salg/salg.module').then(m => m.SalgModule),
    canActivate: [AuthGuardService]
  },
  {
    path: '',
    canActivate: [AuthGuardService],
    resolve: {
      grunnlagsdata: GrunnlagsdataResolverService
    },
    component: PageComponent,
    children: [
        {path: '',component: LandingssideComponent},
        {path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)},
        {path: 'ringer', loadChildren: () => import('./ringer/ringer.module').then(m => m.RingerModule)},
        
        {path: 'kjoreplan', component: KjoreplanComponent},
        {path: 'login', component: LoginComponent},
        {path: 'faktura', component: FakturaListeComponent},
      ]
    }
  ];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
