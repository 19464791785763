<div>
  <h2 mdcHeadline4>Ordinære billetter</h2>
  <div class="ticket-quantity__inputs">
    <button #initialfocuselem mdc-button primary raised [disabled]="disabled" (click)="select(1)">1</button>
    <button mdc-button primary raised [disabled]="disabled" (click)="select(2)">2</button>
    <button mdc-button primary raised [disabled]="disabled" (click)="select(3)">3</button>
    <button mdc-button primary raised [disabled]="disabled" (click)="select(4)">4</button>
    <button mdc-button primary raised [disabled]="disabled" (click)="select(5)">5</button>
  </div>
  <form [formGroup]="form" (ngSubmit)="onSubmit()" class="ticket-quantity__form">
    <mdc-text-field outlined dense label="Antall" formControlName="antall" type="number"></mdc-text-field>
  </form>
</div>