<app-spinner *ngIf="pending"></app-spinner>
<mdc-dialog>
    <mdc-dialog-container>
        <mdc-dialog-surface>
            <mdc-dialog-title *ngIf="ordre && modus !== 'kjoreplan'">Bekreft setevalg</mdc-dialog-title>
            <mdc-dialog-content>

                <div class="setevalg__header">
                    <mdc-radio-group *ngIf="seatsOff.length===0 && seatsInOrdre.length > 1" [formControl]="multi_or_single">
                        <mdc-form-field>
                            <mdc-radio [value]="'alle'"></mdc-radio>
                            <label>Flytt alle</label>
                        </mdc-form-field>
                        <mdc-form-field>
                            <mdc-radio [value]="'en'"></mdc-radio>
                            <label>Flytt en</label>
                        </mdc-form-field>
                    </mdc-radio-group>

                    <div>
                        <div class="velg-fler-reminder">
                            <ng-container *ngIf="seatsOff.length === 1">Velg et nytt sete</ng-container>
                            <ng-container *ngIf="seatsOff.length > 1">Velg {{seatsOff.length}} nye seter</ng-container>
                        </div>

                        <p *ngIf="seatsOff.length===0"><span class="seter__tag">{{ currentReservationPrefix }}</span>{{ currentReservationSeats }}</p>

                    </div>
                </div>

                <table class="sal" [class.nohover]="!ordre || modus === 'kjoreplan'">
                    <tr>
                        <td colspan="100" class="lerret">Lerret</td>
                    </tr>
                    <tr *ngFor="let row of info.sal.saldata.rows">
                        <th scope="row" class="row-label">{{row.l}}</th>
                        <ng-container *ngFor="let seat of row.seats">
                            <td *ngIf="seat && seat !== 'noseat'"
                                class="plass"
                                (click)="clickSeat(seat)"
                            >
                                <button class="sete"
                                    [class.sete-i-ordre]="seatInOrdre(seat)"
                                    [class.sete-i-ordre--off]="seatToggledOff(seat)"
                                    [class.sete--opptatt]="seatOccupied(seat)"
                                    [class.sete--utilgjengelig]="seatBlocked(seat)"
                                    [class.sete--handicap]="seat.t == '@'"
                                >
                                    <span *ngIf="!(seat.t == '@')">{{seat.l}}</span>
                                    <span *ngIf="seat.t == '@'"><mdc-icon>accessible</mdc-icon></span>
                                </button>
                            </td>
                            <td *ngIf="!seat || seat === 'noseat'" class="nothing"></td>
                        </ng-container>
                        <th scope="row" class="row-label">{{row.l}}</th>
                    </tr>
                </table>

            </mdc-dialog-content>
            <mdc-dialog-actions>
                <button mdc-button class="button-no-bg" [disabled]="pending || seatsOff.length > 0" mdcDialogAction="accept">OK</button>
            </mdc-dialog-actions>
        </mdc-dialog-surface>
    </mdc-dialog-container>
</mdc-dialog>