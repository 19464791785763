import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-velg-antall-billetter',
  templateUrl: './velg-antall-billetter.component.html',
  styleUrls: ['./velg-antall-billetter.component.scss']
})
export class VelgAntallBilletterComponent implements OnChanges {
  @Output() selected = new EventEmitter<number>();
  @Input() public disabled: boolean;
  @ViewChild('initialfocuselem', {static: false}) initialfocuselem;
  public form = new FormGroup({ antall: new FormControl() });

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.disabled) {
      if (changes.disabled.currentValue) {
        // this.form.disable();
      } else {
        // this.form.enable();
      }
    }
  }

  select(antall) {
    this.form.controls.antall.setValue(antall);
    this.onSubmit();
  }

  reset() {
    this.form.controls.antall.setValue(null);
  }

  initfocus() {
    if (this.initialfocuselem) {
      this.initialfocuselem.elementRef.nativeElement.focus();
    }
  }

  onSubmit() {
    this.selected.emit(this.form.controls.antall.value);
  }


}
