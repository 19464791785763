import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-ledige-billetter-status',
  templateUrl: './ledige-billetter-status.component.html',
  styleUrls: ['./ledige-billetter-status.component.scss']
})
export class LedigeBilletterStatusComponent implements OnInit {
  @Input() public forestillinginfo;
  constructor() { }

  ngOnInit() {
  }

}
