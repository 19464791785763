<mdc-dialog>
  <mdc-dialog-container>
    <mdc-dialog-surface>

      <mdc-dialog-title>Sjekk gavekort</mdc-dialog-title>

      <mdc-dialog-content>

        <div class="gavekort__sjekk-billett">
          <div [class.hide-video]="paused">
            <h3>Scann QR-kode:</h3>
            <video #scanVideoElm></video>
          </div>
          <div [class.hide-input]="resultat?.gavekort_kode">
            <h3>Skriv inn gavekortnummer:</h3>
            <div class="gavekort-sjekk__nummer">
              <mdc-text-field  outlined label="Nummer" [formControl]="gavekortnummer"></mdc-text-field>
            </div>
          </div>
          <div class="melding">
            <div class="invalid" *ngIf="gavekortnummer?.errors?.gavekort_err">{{getFeilmelding()}}</div>
            <div class="invalid" *ngIf="gavekortnummer?.errors?.minlength">Minst 8 tegn</div>
            <div class="invalid" *ngIf="gavekortnummer?.errors?.maxlength">Maks 32 tegn</div>
            <div class="valid" *ngIf="gavekortnummer.valid && resultat?.gavekort_kode">
              <p>Gavekort-kode: <b>{{resultat.gavekort_kode}}</b></p>
              <p class="saldo">Saldo: <b>{{resultat.gavekort_verdi}}</b></p>
              <p>Opprinnelig verdi: <b>{{resultat.gavekort_opprinnelig_verdi}}</b></p>
              <p>Gyldig til <b>{{resultat.gyldig_til | date:'d.M.yyyy'}}</b></p>
            </div>
          </div>
        </div>

      </mdc-dialog-content>

      <mdc-dialog-actions>
        <button mdc-button class="button-no-bg" *ngIf="paused" (click)="startScan()">Scan på nytt</button>
        <button mdc-button raised mdcDialogAction="close">Lukk</button>
      </mdc-dialog-actions>

    </mdc-dialog-surface>
  </mdc-dialog-container>
</mdc-dialog>
