<mdc-dialog cdkTrapFocus>
    <mdc-dialog-container>
        <mdc-dialog-surface>
            <mdc-dialog-title>Tilleggsbeløp:</mdc-dialog-title>
            <mdc-dialog-content [formGroup]="formen">
                <p></p>
                <mdc-text-field #initialfocuselem type="number" outlined label="Kroner" formControlName="kroner" placeholder="0" (keydown.enter)="submit($event)"></mdc-text-field>
            </mdc-dialog-content>
            <mdc-dialog-actions>
                <button mdc-button class="button-no-bg" mdcDialogAction="close">Avbryt</button>
                <button mdc-button raised default mdcDialogAction="close" (click)="submit($event)">Ok</button>
            </mdc-dialog-actions>
        </mdc-dialog-surface>
    </mdc-dialog-container>
</mdc-dialog>