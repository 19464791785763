import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppMaterialModule } from '../material/material.module';

import { SalgRoutingModule } from './salg-routing.module';
import { ForestillingComponent } from './forestilling/forestilling.component';
import { KasseComponent } from './kasse/kasse.component';
import { ReactiveFormsModule } from '@angular/forms';
import { LedigeBilletterStatusComponent } from './ledige-billetter-status/ledige-billetter-status.component';
import { VelgAntallBilletterComponent } from './velg-antall-billetter/velg-antall-billetter.component';
import { OrdreStatusComponent } from './ordre-status/ordre-status.component';
import { RefundStatusComponent } from './refund-status/refund-status.component';
import { SpesialBetalingComponent } from './spesial-betaling/spesial-betaling.component';
import { BetalingComponent } from './betaling/betaling.component';
import { ValgComponent } from './valg/valg.component';
import { BillettloggComponent } from './dialogs/billettlogg/billettlogg.component';
import { BekreftBilletterComponent } from './dialogs/bekreft-billetter/bekreft-billetter.component';
import { RefunderBilletterComponent } from './dialogs/refunder-billetter/refunder-billetter.component';
import { AvlysForestillingComponent } from './dialogs/avlys-forestilling/avlys-forestilling.component';
import { KampanjeBetalingComponent } from './dialogs/kampanje-betaling/kampanje-betaling.component';
import { FakturaBetalingComponent } from './dialogs/faktura-betaling/faktura-betaling.component'
import { GavekortBetalingComponent } from './dialogs/gavekort-betaling/gavekort-betaling.component'

import { LeggTilForestillingComponent } from './dialogs/legg-til-forestilling/legg-til-forestilling.component';
import { FribillettBetalingComponent } from './dialogs/fribillett-betaling/fribillett-betaling.component';
import { TilgodebillettBetalingComponent } from './dialogs/tilgodebillett-betaling/tilgodebillett-betaling.component';
import { SelgGavekortComponent } from './dialogs/selg-gavekort/selg-gavekort.component';
import { EndreFilmComponent } from './dialogs/endre-film/endre-film.component';
import { CommonComponentsModule } from '../common-components/common-components.module';
import { BillettscanLenkeComponent } from './dialogs/billettscan-lenke/billettscan-lenke.component';
import { LeggTilPengerComponent } from './dialogs/legg-til-penger/legg-til-penger.component';
import { KassevalgComponent } from './kassevalg/kassevalg.component';
import { ZListComponent } from './dialogs/z-list/z-list.component';
import { SjekkGavekortComponent } from './dialogs/sjekk-gavekort/sjekk-gavekort.component';
import { BesoksrapportComponent } from './dialogs/besoksrapport/besoksrapport.component';
import { VelgSeteComponent } from './dialogs/velg-sete/velg-sete.component';
import { AndreProdukterComponent } from './andre-produkter/andre-produkter.component';
import { DialogOrdreoppslagComponent } from '../admin/dialogs/dialog-ordreoppslag/dialog-ordreoppslag.component';
import { OppgiBillettmottakerEpostComponent } from './dialogs/oppgi-billettmottaker-epost/oppgi-billettmottaker-epost.component';
import { VippsBetalingComponent } from './dialogs/vipps-betaling/vipps-betaling.component';

@NgModule({
  declarations: [
    ForestillingComponent,
    KasseComponent,
    LedigeBilletterStatusComponent,
    VelgAntallBilletterComponent,
    OrdreStatusComponent,
    RefundStatusComponent,
    SpesialBetalingComponent,
    BetalingComponent,
    ValgComponent,
    BillettloggComponent,
    BekreftBilletterComponent,
    RefunderBilletterComponent,
    AvlysForestillingComponent,
    KampanjeBetalingComponent,
    LeggTilForestillingComponent,
    FakturaBetalingComponent,
    GavekortBetalingComponent,
    FribillettBetalingComponent,
    TilgodebillettBetalingComponent,
    SelgGavekortComponent,
    EndreFilmComponent,
    BillettscanLenkeComponent,
    LeggTilPengerComponent,
    KassevalgComponent,
    ZListComponent,
    SjekkGavekortComponent,
    BesoksrapportComponent,
    VelgSeteComponent,
    AndreProdukterComponent,
    DialogOrdreoppslagComponent,
    OppgiBillettmottakerEpostComponent,
    VippsBetalingComponent
  ],
  imports: [
    CommonModule,
    SalgRoutingModule,
    AppMaterialModule,
    ReactiveFormsModule,
    CommonComponentsModule
  ],
  entryComponents: [
    BillettloggComponent,
    BekreftBilletterComponent,
    KampanjeBetalingComponent,
    FribillettBetalingComponent,
    TilgodebillettBetalingComponent,
    FakturaBetalingComponent,
    GavekortBetalingComponent,
    LeggTilForestillingComponent,
    EndreFilmComponent,
    RefunderBilletterComponent,
    AvlysForestillingComponent,
    SelgGavekortComponent,
    BillettscanLenkeComponent,
    LeggTilPengerComponent,
    ZListComponent,
    SjekkGavekortComponent,
    BesoksrapportComponent,
    VelgSeteComponent,
    DialogOrdreoppslagComponent,
    VippsBetalingComponent
  ]
})
export class SalgModule { }
