import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { ContentService } from 'src/app/content.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MdcDialog } from '@angular-mdc/web';
import { BekreftBilletterComponent } from '../dialogs/bekreft-billetter/bekreft-billetter.component';
import { VelgAntallBilletterComponent } from '../velg-antall-billetter/velg-antall-billetter.component';
import { BetalingComponent } from '../betaling/betaling.component';
import { OrdreStatusComponent } from '../ordre-status/ordre-status.component';
import { RefunderBilletterComponent } from '../dialogs/refunder-billetter/refunder-billetter.component';
import { printOrdre, printVouchers, printRefundReciept } from '../print-stuff';
import { VelgSeteComponent } from '../dialogs/velg-sete/velg-sete.component';
import { OppgiBillettmottakerEpostComponent } from '../dialogs/oppgi-billettmottaker-epost/oppgi-billettmottaker-epost.component';
import { AuthService } from 'src/app/auth.service';

var testordre: Ordre;
// Kommenter inn de neste to linjene for å bruke hjemmelaga ordre-data:
// import * as testdata from  '../testdata/ordre_printklar.json';
// testordre = testdata.ordre as unknown as Ordre;

@Component({
  selector: 'app-forestilling',
  templateUrl: './forestilling.component.html',
  styleUrls: ['./forestilling.component.scss']
})
export class ForestillingComponent implements OnInit, AfterViewInit, OnDestroy {
  public forestillinginfo: Forestillinginfo;
  public forestillingId;
  public currentOrdre: Ordre;
  public ikkeReverserbareTransaksjoner;
  public currentRefund;
  public henterOrdreFraBackend = false;
  public transactionPending = false;
  private autofocusDelay = 200;
  private focusTimer;
  // oppdateringsfrekvens for forestillingens salgstall:
  private autoRefreshDelay = 60 * 1000;
  private refreshTimer;

  @ViewChild(VelgAntallBilletterComponent, { static: false }) private velgAntallBilletterComponent;
  @ViewChild(BetalingComponent, { static: false }) private betalingComponent;
  @ViewChild(OrdreStatusComponent, { static: false }) private ordreStatusComponent;
  @ViewChild('salkartbutton', {static: false}) salkartbutton;

  constructor(
    private contentService: ContentService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MdcDialog,
    public auth: AuthService
  ) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.forestillingId = params.id;
      if (testordre) {
        this.setCurrentOrdre(testordre);
      } else if (localStorage.getItem('currentOrdreId')) {
        this.henterOrdreFraBackend = true;
        this.contentService.fetchContent('/salg/forestilling/' + params.id + '/ordre/' + localStorage.getItem('currentOrdreId'))
          .subscribe(
            res => {
              this.henterOrdreFraBackend = false;
              if (res.ordre.slettet) {
                this.setCurrentOrdre(null);
              } else {
                this.setCurrentOrdre(res.ordre);
              }
            },
            err => {
              this.henterOrdreFraBackend = false;
              this.setCurrentOrdre(null);
            });
      }
      const chk = () => { this.checkExternalRefund(); }
      // FIXME: sørg for at getInfo ikke feiler så stygt på 404? (kun ved refund fra /kasse?)
      this.getInfo(chk, chk);
    })
  }

  setCurrentOrdre(ordre) {
    this.currentOrdre = ordre;
    if (ordre) {
      localStorage.setItem('currentOrdreId', ordre.id);
      this.ikkeReverserbareTransaksjoner = this.currentOrdre.trans.filter(
        transaksjon => {
          const type = transaksjon.betalingstype;
          return (type !== 'kampanje' && type !== 'norgesbillett' && type !== 'stab');
        }
      );
    } else {
      localStorage.removeItem('currentOrdreId');
      this.ikkeReverserbareTransaksjoner = [];
    }
  }

  ngAfterViewInit() {
    if (this.velgAntallBilletterComponent) {
      this.focusVelgAntallBilletter();
    }
  }

  ngOnDestroy() {
    if (this.focusTimer) {
      clearTimeout(this.focusTimer);
    }
    if (this.refreshTimer) {
      clearTimeout(this.refreshTimer);
    }
  }

  // henter forestillingsinfo og starter statuspolling hvis det trengs
  getInfo(onComplete = null, onError=() => {}) {
    if (this.refreshTimer) {
      clearTimeout(this.refreshTimer);
    }
    this.refreshTimer = setTimeout(() => this.getStatus(), this.autoRefreshDelay);
    this.contentService.fetchContent('/salg/forestilling/' + this.forestillingId + '/info')
      .subscribe(forestillinginfo => {
        this.forestillinginfo = forestillinginfo;
        if (onComplete) {
          onComplete();
        }
      }, onError)
  }

  // henter oppdaterte tall og solgte seter
  getStatus(onComplete = null, onError=() => {}) {
    if (this.refreshTimer) {
      clearTimeout(this.refreshTimer);
    }
    this.refreshTimer = setTimeout(() => this.getStatus(), this.autoRefreshDelay);
    this.contentService.fetchContent('/salg/forestilling/' + this.forestillingId + '/status')
      .subscribe((status: Forestillingstatus) => {
        const f = this.forestillinginfo
        f.n_totalt = status.n_totalt;
        f.n_solgt = status.n_solgt;
        f.n_tilgj = status.n_tilgj;
        f.n_per_ordretype = status.n_per_ordretype;
        f.seats_occupied = status.seats_occupied;
        if (onComplete) {
          onComplete();
        }
      }, onError)
  }

  getAgeRating(ageRating: string) {
    let rating: string;
    if(ageRating === "A") {
      rating = "Tillatt for alle";
    }
    else {
      rating = ageRating;
    }
    return rating;
  }

  onSolgtGavekort(gavekortListe: Array<any>) {
    this.placeGavekortOrder(0, gavekortListe);
  }

  onOrdreRequest(antallBilletter: number) {
    const nrRequestedTickets = this.currentOrdre ?
      antallBilletter - this.currentOrdre.billetter.length :
      antallBilletter;

    if (this.forestillinginfo && this.forestillinginfo.n_tilgj < nrRequestedTickets) {
      const ref = this.dialog.open(BekreftBilletterComponent, {
        data: {
          forestillinginfo: this.forestillinginfo,
          antallBilletter: nrRequestedTickets
        }
      });
      ref.afterClosed().subscribe(action => {
        if (action === 'accept') {
          this.placeOrder(antallBilletter);
        }
      })
    } else {
      this.placeOrder(antallBilletter);
    }

  }

  // backend-metoden for denne må endres på slik at man ikke trenger å legge til antallBilletter == 0 her
  placeGavekortOrder(antallBilletter: number, gavekortListe: Array<any>) {
    this.transactionPending = true;
    let req;
    let ordre: any = { antall_billetter: antallBilletter};
    ordre.gavekort = [];
    for (var i = 0; i < gavekortListe.length; i++) {
      for (var j = 0; j < gavekortListe[i].antall; j++) {
        ordre.gavekort.push({verdi: gavekortListe[i].verdi})
      }
    }
    if (this.currentOrdre) {
      req = this.contentService.updateContent('/salg/forestilling/' + this.forestillingId + '/ordre/' + this.currentOrdre.id, ordre, null);
    } else {
      req = this.contentService.postContent('/salg/forestilling/' + this.forestillingId + '/ordre', ordre);
    }
    req.subscribe(res => {
      this.setCurrentOrdre(res.ordre);
      this.getStatus(() => {
        this.transactionPending = false;
        this.focusBetaling();
      });
    });
  }

  placeOrder(antallBilletter: number, billettType: BillettType = 'normal', kode = null) {
    this.transactionPending = true;
    let req;
    let ordre: any = {
      billett_type: billettType
    };
    if (billettType === 'norgesbillett') {
      ordre.nb_kode = kode;
      ordre.antall_billetter = antallBilletter;
    } else if (billettType === 'kampanje') {
      ordre.kb_kode = kode;
      ordre.antall_nye_billetter = antallBilletter;
    } else {
      ordre.antall_billetter = antallBilletter;
    }
    if (this.currentOrdre) {
      req = this.contentService.updateContent('/salg/forestilling/' + this.forestillingId + '/ordre/' + this.currentOrdre.id, ordre, null);
    } else {
      req = this.contentService.postContent('/salg/forestilling/' + this.forestillingId + '/ordre', ordre);
    }
    req.subscribe(res => {
      this.setCurrentOrdre(res.ordre);
      this.getStatus(() => {
        this.transactionPending = false;
        if(this.forestillinginfo.sal) {
          this.focusSalkart();
        }
        else {
          this.focusBetaling();
        }
      });
    });
  }

  public onAddProduct(produkt: any) {// FIXME: typing
    if (produkt.billetttype === 'norgesbillett') {
      this.placeOrder(1, produkt.billetttype, produkt.nb_kode);
    } else if(produkt.billetttype === 'kampanje') {
      this.placeOrder(1, produkt.billetttype, produkt.kb_kode);
    } else {
      let total = 1;
      if (this.currentOrdre && this.currentOrdre.billetter) {
        const countSameType = this.currentOrdre.billetter.filter(
          (billett: Billett) => billett.billetttype === produkt.billetttype
        ).length;
        total += countSameType;
      }
      this.placeOrder(total, produkt.billetttype);
    }
  }

  public onBetalt(betaling) {
    this.transactionPending = true;
    if (betaling.betalingstype === 'vipps') {
      // Ved vipps-betaling er transaksjonen allerede registrert i
      // ordren, så da trenger vi bare å hente ordren på nytt
      this.contentService.fetchContent(
        '/salg/forestilling/' + this.forestillingId + '/ordre/' + this.currentOrdre.id
      ).subscribe(
        res => {
          this.setCurrentOrdre(res.ordre);
          this.transactionPending = false;
          this.focusOrdrestatusIfPaymentDone();
        },
        err => {
          console.error('betaling feilet');
          this.transactionPending = false;
      });
    } else {
      this.contentService.postContent(
        '/salg/forestilling/' + this.forestillingId + '/ordre/' + this.currentOrdre.id, betaling
      ).subscribe(
        res => {
          this.setCurrentOrdre(res.ordre);
          this.transactionPending = false;
          this.focusOrdrestatusIfPaymentDone();
        },
        err => {
          console.error('betaling feilet');
          this.transactionPending = false;
      });
    }
  }

  public onOrdreAvsluttet(modus: string) {
    if (testordre) {
      printOrdre({ forestillinginfo: this.forestillinginfo, ordre: testordre }, false);
    } else if (modus === 'utskrift') {
      this.transactionPending = true;
      this.contentService.postContent(
        `/salg/forestilling/${this.forestillingId}/ordre/${this.currentOrdre.id}/fullfor`, {}
      ).subscribe(res => {
        this.transactionPending = false;
        printOrdre({
          forestillinginfo: this.forestillinginfo,
          ordre: this.currentOrdre
        }, false);
        this.setCurrentOrdre(null);
        this.getStatus();
        this.velgAntallBilletterComponent.reset();
        this.focusVelgAntallBilletter();
      });
    } else if (modus === 'epost') {
      this.dialog.open(OppgiBillettmottakerEpostComponent).afterClosed()
      .subscribe((res: any) => {
        if (!res.epost) return; // avbryt/lukk
        this.transactionPending = true;
        this.contentService.postContent(
          `/salg/forestilling/${this.forestillingId}/ordre/${this.currentOrdre.id}/fullfor`, { epost: res.epost }
        ).subscribe( res => {
          this.transactionPending = false;
          this.setCurrentOrdre(null);
          this.getStatus();
          this.velgAntallBilletterComponent.reset();
          this.focusVelgAntallBilletter();
        });

      });
    }
  }

  public onTransactionCancel(transaction_id) {
    this.transactionPending = true;
    const ordre = this.currentOrdre;
    if (ordre && ordre.trans && ordre.trans.length) {
      this.contentService.deleteContent(
        `/salg/ordre/${ordre.id}/transaksjon/${transaction_id}`
      ).subscribe((res: any) => {
        if (res && res.ordre) {
          this.setCurrentOrdre(res.ordre);
        }
        this.transactionPending = false;
      })
    }
  }

  public onBillettCancel(billett_id) {
    this.transactionPending = true;
    const ordre = this.currentOrdre;
    if (ordre && ordre.billetter && ordre.billetter.length) {
      this.contentService.deleteContent(
        `/salg/ordre/${ordre.id}/billett/${billett_id}`
      ).subscribe((res: any) => {
        if (res && res.ordre) {
          this.setCurrentOrdre(res.ordre);
        } else {
          console.warn('todo: backend');
        }
        this.transactionPending = false;
      })
    }
  }

  public onOrdreCancel() {
    this.transactionPending = true;
    this.contentService.postContent(
      `/salg/forestilling/${this.forestillingId}/ordre/${this.currentOrdre.id}/cancel`, {}
    ).subscribe(res => {
      this.getStatus(() => {
        this.transactionPending = false;
        this.focusVelgAntallBilletter();
      });
    });
    this.setCurrentOrdre(null);
    this.velgAntallBilletterComponent.reset();
  }

  public gjenstaaende() {
    if (this.currentOrdre) {
      const betalt = this.currentOrdre.trans.reduce((total, b) => {
        return total + b.belop
      }, 0);
      return this.currentOrdre.sum - betalt;
    } else {
      return 0;
    }
  }

  // Ser etter url-parametere med refunderings-info
  // Kjøres etter at forestillingsinfo er (forsøkt) lastet første gang
  private checkExternalRefund() {
    const q = this.route.snapshot.queryParamMap;
    if (q.get('refund_what') && q.get('refund_type') && q.get('refund_id')) {
      this.onGotRefundRequest({
        refund_what: q.get('refund_what'),
        refund_type: q.get('refund_type'),
        id: q.get('refund_id')
      });
      // TODO: sørg for å bare fjerne egne parametere?
      this.router.navigate(['.'], { relativeTo: this.route, queryParams: {} });
    }
  }

  public refunderBillett() {
    this.dialog.open(RefunderBilletterComponent).afterClosed()
      .subscribe((res: any) => {
        if (res !== 'close') {
          this.onGotRefundRequest(res);
        }
      });
  }

  onGotRefundRequest(info) {
    this.currentRefund = {};// pending
    this.contentService.fetchContent('/salg/ticketscan/' + info.id)
      .subscribe(
        res => {
          this.currentRefund = info;
          this.currentRefund.res = res;/* DEBUG */
          this.currentRefund.ordre = res.ordre;
          this.currentRefund.forestilling = res.forestilling;
          this.currentRefund.spillested = res.spillested;
          this.currentRefund.antallUrefunderte = res.ordre.billetter.filter(b => !b.refundert).length;
          this.currentRefund.tidligereRefundert = res.ordre.billetter.reduce((acc, b) => {
            if (b.refundert) {
              return acc + b.belop;
            } else {
              return acc;
            }
          }, 0);
        }
      )
  }

  doRefund() {
    const r = this.currentRefund;
    this.currentRefund = null;
    if (r.refund_what === 'ticket') {// refunder enkeltbillett
      this.contentService.postContent(`/salg/ticket/${r.id}/refund`, { refund_type: r.refund_type })
        .subscribe(
          res => {
            if (res.fribilletter && res.fribilletter.length) {
              printVouchers(res.fribilletter, {
                type: 'tilgodebillett',
                ordreid: res.ordre.ordreid,
                kasseid: res.kasse_id,
                date: res.datotid,
                num_tickets: res.fribilletter.length,
                sum: 0
              });
            } else if (res.penger_tilgode) {
              printRefundReciept({
                type: 'cash',
                ordreid: res.ordre.ordreid,
                kasseid: res.kasse_id,
                date: res.datotid,
                num_tickets: 1,
                sum: Math.abs(res.penger_tilgode)
              });
            } else if (res.ordre.trans && res.ordre.trans.length) {// kort
              let sum = res.ordre.trans.reduce((s, t) => s + t.belop, 0);
              printRefundReciept({
                type: 'kort',
                ordreid: res.ordre.ordreid,
                kasseid: res.kasse_id,
                date: res.datotid,
                num_tickets: 1,
                sum: Math.abs(sum)
              });
            } else {
              console.error('Noe gikk galt ved billett-refusjon', res);
            }
            this.getStatus();
          },
          err => {
            console.error('Could not refund ticket', err);
          });

    } else { // refunder ordre

      this.contentService.postContent(`/salg/ordre/${r.ordre.id}/refund`, { refund_type: r.refund_type })
        .subscribe(
          res => {
            if (res.fribilletter && res.fribilletter.length) {
              printVouchers(res.fribilletter, {
                type: 'tilgodebillett',
                ordreid: res.ordre.ordreid,
                kasseid: res.kasse_id,
                date: res.datotid,
                num_tickets: res.fribilletter.length,
                sum: 0
              });
            } else if (res.penger_tilgode) {
              printRefundReciept({
                type: 'cash',
                ordreid: res.ordre.ordreid,
                kasseid: res.kasse_id,
                date: res.datotid,
                num_tickets: res.ant_refundert,
                sum: Math.abs(res.penger_tilgode)
              });
            } else if (res.ant_refundert) {// kort
              let sum = res.ordre.trans.reduce((s, t) => s + t.belop, 0);
              printRefundReciept({
                type: 'kort',
                ordreid: res.ordre.ordreid,
                kasseid: res.kasse_id,
                date: res.datotid,
                num_tickets: res.ant_refundert,
                sum: Math.abs(sum)
              });
            } else {
              console.error('Noe gikk galt ved ordre-refusjon', res);
            }
            this.getStatus();
          },
          err => { console.error('Could not refund ordre', err) });
    }
  }

  openSalkart() {
    if(this.currentOrdre) {
      this.dialog.open(VelgSeteComponent, {
        data: {
          ordre: this.currentOrdre,
          info: this.forestillinginfo
        }
      }).afterClosed().subscribe(res => {
        this.focusBetaling();
      });
    } else {
      this.dialog.open(VelgSeteComponent, {
        data: { info: this.forestillinginfo }
      });
    }
  }

  public onRefundCancel() {
    delete this.currentRefund;
  }

  public goToKasse() {
    if (this.auth.isAdmin()) {
      this.router.navigate(['admin', 'kasse', this.forestillinginfo.spillested_id]);
    }
    else {
      this.router.navigate(['salg','kasse']);
    }
  }

  private focusOrdrestatusIfPaymentDone() {
    if (this.gjenstaaende() <= 0) {
      this.focusOrdreStatus();
    }
  }

  private focusVelgAntallBilletter() {
    this.focusTimer = setTimeout(() => this.velgAntallBilletterComponent.initfocus(), this.autofocusDelay);
  }

  private focusSalkart() {
    if (this.salkartbutton) {
      this.focusTimer = setTimeout(() => this.salkartbutton.elementRef.nativeElement.focus());
    }
  }

  private focusBetaling() {
    this.focusTimer = setTimeout(() => this.betalingComponent.initfocus(), this.autofocusDelay);
  }

  private focusOrdreStatus() {
    this.focusTimer = setTimeout(() => this.ordreStatusComponent.initfocus(), this.autofocusDelay);
  }


}
