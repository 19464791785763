import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LandingssideComponent } from './landingsside/landingsside.component';
import { LandingssideMaskinistComponent } from './landingsside-maskinist/landingsside-maskinist.component';
import { LandingssideAdminComponent } from './landingsside-admin/landingsside-admin.component';
import { AppMaterialModule } from '../material/material.module';
import { CommonComponentsModule } from '../common-components/common-components.module';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [LandingssideComponent, LandingssideMaskinistComponent, LandingssideAdminComponent],
  imports: [
    CommonModule,
    AppMaterialModule,
    CommonComponentsModule,
    RouterModule
  ]
})
export class LandingssideModule { }
