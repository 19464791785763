import { Component, OnInit } from '@angular/core';
import { ContentService } from 'src/app/content.service';
import { AuthService } from 'src/app/auth.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-landingsside-maskinist',
  templateUrl: './landingsside-maskinist.component.html',
  styleUrls: ['./landingsside-maskinist.component.scss']
})
export class LandingssideMaskinistComponent implements OnInit {
  public maskinist;
  public mineforestillinger$;
  public kjoreplan;
  public maskinister;
  public brukerveiledninglenke;
  public henterKjoreplan = false;

  constructor(
    private contentService: ContentService,
    public auth: AuthService
  ) {}

  ngOnInit() {
    this.maskinist = this.auth.getUserInfo();
    this.mineforestillinger$ = this.contentService.fetchContent('/salg/mineforestillinger');
    this.hentMaskinister();
    this.hentKjoreplan();
  }

  hentKjoreplan() {
    this.henterKjoreplan = true;
    this.contentService.fetchContent('/maskinist/' + this.maskinist.maskinist + '/kjoreplan/?limit=7')
      .subscribe((res: any) => {
        this.kjoreplan = res;
        this.henterKjoreplan = false;
      });
  }

  hentBrukerveiledninglenke() {
    let backendurl = environment.production ? window.origin : 'http://localhost:5000';
    this.brukerveiledninglenke = backendurl + '/static/doc/kinologg_brukerveiledning.pdf';
    return this.brukerveiledninglenke;
  }

  hentMaskinister() {
    this.contentService.getSearchResult('/maskinist', 'navn', '', '"aktivpassiv":"true"')
    .subscribe(maskinister => {
      this.maskinister = maskinister._items;
      this.maskinister.forEach(m => {
        this.contentService.lookupPostnummer(m.postnr)
          .subscribe(res => {
            m.poststed = res.result;
          })
      })
    });
  }

}