<mdc-dialog>
  <mdc-dialog-container>
    <mdc-dialog-surface>
      <mdc-dialog-title>Bekreft billettsalg</mdc-dialog-title>
      <mdc-dialog-content>
        Det er solgt maks antall billetter for denne forestillingen. Ønsker du å selge {{ antallBilletter }} til?
        Det vil være {{ (forestillinginfo.n_solgt + antallBilletter) - forestillinginfo.n_totalt }} flere billetter enn grensen.
      </mdc-dialog-content>
      <mdc-dialog-actions>
        <button mdcDialogButton mdcDialogAction="close">Avbryt</button>
        <button mdcDialogButton default mdcDialogAction="accept">Ok</button>
      </mdc-dialog-actions>
    </mdc-dialog-surface>
  </mdc-dialog-container>
</mdc-dialog>