<!-- FIXME: fjern gjenstående/tilbake? -->
<span>{{ gjenstaaende >= 0 ? 'Gjenstående' : 'Tilbake' }}: {{ absGjenstaaende | number:'1.2-2'  }}</span>
<div class="betaling__input">
  <mdc-form-field fluid>
    <mdc-text-field outlined dense label="Betal" [formControl]="sum"></mdc-text-field>
  </mdc-form-field>
  <button class="pluss" [disabled]="disabled" mdc-button raised (click)="addToSum()">+</button>
</div>

<div class="primary-payment">
  <button #initialfocuselem [disabled]="disabled" mdc-button primary raised class="betaling__button" (click)="onBetalt('kortterminal')"><mdc-icon>payment</mdc-icon>Kort</button>
  <button
    *ngIf="show_vipps"
    [disabled]="disabled" mdc-button primary raised class="betaling__button button--vipps" (click)="openVippsBetaling()">
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 163.5 66.1" xml:space="preserve">
      <path d="M28,22l5.1,14.9L38.1,22h5.9l-8.8,22.1h-4.4L22,22H28z"/>
      <path d="M57.3,40.6c3.7,0,5.8-1.8,7.8-4.4c1.1-1.4,2.5-1.7,3.5-0.9c1,0.8,1.1,2.3,0,3.7c-2.9,3.8-6.6,6.1-11.3,6.1
        c-5.1,0-9.6-2.8-12.7-7.7c-0.9-1.3-0.7-2.7,0.3-3.4c1-0.7,2.5-0.4,3.4,1C50.5,38.3,53.5,40.6,57.3,40.6z M64.2,28.3c0,1.8-1.4,3-3,3
        c-1.6,0-3-1.2-3-3s1.4-3,3-3C62.8,25.3,64.2,26.6,64.2,28.3z"/>
      <path d="M78.3,22v3c1.5-2.1,3.8-3.6,7.2-3.6c4.3,0,9.3,3.6,9.3,11.3c0,8.1-4.8,12-9.8,12c-2.6,0-5-1-6.8-3.5v10.6h-5.4V22H78.3z
        M78.3,33c0,4.5,2.6,6.9,5.5,6.9c2.8,0,5.6-2.2,5.6-6.9c0-4.6-2.8-6.8-5.6-6.8C81,26.2,78.3,28.3,78.3,33z"/>
      <path d="M104.3,22v3c1.5-2.1,3.8-3.6,7.2-3.6c4.3,0,9.3,3.6,9.3,11.3c0,8.1-4.8,12-9.8,12c-2.6,0-5-1-6.8-3.5v10.6h-5.4V22H104.3z
        M104.3,33c0,4.5,2.6,6.9,5.5,6.9c2.8,0,5.6-2.2,5.6-6.9c0-4.6-2.8-6.8-5.6-6.8C106.9,26.2,104.3,28.3,104.3,33z"/>
      <path d="M132.3,21.4c4.5,0,7.7,2.1,9.1,7.3l-4.9,0.8c-0.1-2.6-1.7-3.5-4.1-3.5c-1.8,0-3.2,0.8-3.2,2.1c0,1,0.7,2,2.8,2.4l3.7,0.7
        c3.6,0.7,5.6,3.1,5.6,6.3c0,4.8-4.3,7.2-8.4,7.2c-4.3,0-9.1-2.2-9.8-7.6l4.9-0.8c0.3,2.8,2,3.8,4.8,3.8c2.1,0,3.5-0.8,3.5-2.1
        c0-1.2-0.7-2.1-3-2.5l-3.4-0.6c-3.6-0.7-5.8-3.2-5.8-6.4C124.2,23.5,128.7,21.4,132.3,21.4z"/>
    </svg>
  </button>
<button [disabled]="disabled" mdc-button outlined class="betaling__button button-no-bg" (click)="onBetalt('cash')">Kontant</button>
  <!-- <button [disabled]="disabled" mdc-button raised (click)="showMore()">Flere</button> -->
  <app-spesial-betaling class="betaling__button" [ordre]="ordre" [disabled]="disabled" (betalt)="onSpesialbetalt($event)"></app-spesial-betaling>
</div>
