<mdc-dialog cdkTrapFocus>
    <mdc-dialog-container>
        <mdc-dialog-surface>
            <mdc-dialog-title>Oppgi mottakers epost:</mdc-dialog-title>
            <mdc-dialog-content [formGroup]="formen">
                <p></p>
                <mdc-text-field #initialfocuselem type="email" outlined label="Epost" formControlName="epost" placeholder="Skriv inn epost" (keydown.enter)="submit($event)"></mdc-text-field>
            </mdc-dialog-content>
            <mdc-dialog-actions>
                <button mdc-button class="button-no-bg" mdcDialogAction="close">Avbryt</button>
                <button mdc-button raised default (click)="submit($event)" [disabled]="!formen.valid">Send epost</button>
            </mdc-dialog-actions>
        </mdc-dialog-surface>
    </mdc-dialog-container>
</mdc-dialog>