<mdc-dialog>
    <mdc-dialog-container>
        <mdc-dialog-surface>
            <mdc-dialog-content>
                <app-ordreoppslag
                    [forestillingId]="forestillingId">
                </app-ordreoppslag>
            </mdc-dialog-content>
            <mdc-dialog-actions>
                <button mdc-button primary raised (click)="close()">Lukk</button>
            </mdc-dialog-actions>
        </mdc-dialog-surface>
    </mdc-dialog-container>
</mdc-dialog>
    
