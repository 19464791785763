<div *ngIf="fakturaer" class="content-list">
  <ul class="fakturaliste">
    <li *ngFor="let faktura of fakturaer" class="fakturasalg">
      <div class="faktura__forestilling">
        <p class="forestilling__dato">{{ faktura.ruteplan_dato | date:'d.M'}} </p>
        <p class="forestilling__sted">{{ faktura.spillested.stedsnavn }}</p>
        <p class="forestilling__film">{{ faktura.film_tittel }}</p>
      </div>
      <div class="faktura__mottaker">
        <p>{{ faktura.betaling_info.fakturamottaker }}</p>
        <p>{{ faktura.betaling_info.fakturareferanse }}</p>
        <p>{{ faktura.betaling_info.fakturaadresse }}</p>
      </div>
      <div class="faktura__pris">
        <p class="pris__antall">{{ faktura.ordre_antall_billetter }} billetter</p>
        <p class="pris__sum">kr {{ faktura.ordre_totalsum }}</p>
        <button *ngIf="!faktura.betaling_info.fullfort" mdc-button unelevated (click)="markAsFullfort(faktura.id)">Marker som fullført</button>
        <p *ngIf="faktura.betaling_info.fullfort"class="faktura__fullfort"><mdc-icon>check</mdc-icon>Fullført</p>
      </div>
    </li>
  </ul>
  <button mdc-button raised *ngIf="!showAll && !onlyFullforte"  (click)="doShowAll()">VIS ALLE</button>
</div>