<mdc-dialog>
    <mdc-dialog-container>
      <mdc-dialog-surface>
        <mdc-dialog-title>Avslutt forestilling</mdc-dialog-title>
        <mdc-dialog-content *ngIf="forestillinginfo">
          <div class="forestilling__info">
            <p>{{ forestillinginfo?.film_tittel }}</p>
            <p>{{ forestillinginfo?.dato | date: 'd.M.yyyy' }} {{ forestillinginfo?.tidspunkt }}</p>
            <p>{{ forestillinginfo?.spillested_tittel }}</p>
          </div>

          <div class="avslutt-advarsel">
            <p>OBS: Når forestillingen er avsluttet vil det ikke lenger være mulig å selge billetter til den.</p>
          </div>

          <div> 
            <div class="salg--oppsummering kassesalg">
              <p>Oppgjør fra kassa: </p>
              <ng-container *ngIf="lukesalg">
                <p>Antall billetter: {{ lukesalg.antall }}</p>
                <p>Pris: {{ forestillinginfo.bill_pris }}</p>
                <p>Sum: <span *ngIf="lukesalg">{{ lukesalg.antall * forestillinginfo.bill_pris }}</span></p>
              </ng-container>
              <ng-container *ngIf="! lukesalg"><p>0 solgte billetter i kassen</p></ng-container>
            </div>
            <div class="salg--oppsummering nettsalg">
              <p>Nettsalg:</p>
              <ng-container *ngIf="nettsalg">
                <p>Antall billetter: {{ nettsalg.antall }}</p>
                <p>Pris: {{ forestillinginfo.bill_nettpris }}</p>
                <p>Sum: <span *ngIf="nettsalg">{{ nettsalg.antall * forestillinginfo.bill_nettpris }}</span></p>
              </ng-container>
              <ng-container *ngIf="! nettsalg"><p>0 solgte billetter på nett</p></ng-container>
            </div>
            <form [formGroup]="eksterntSalgForm">
              <div class="salg--oppsummering eksternsalg">
                <p>Eksternt salg:</p>
                <mdc-text-field outlined placeholder="Antall" formControlName="antall_billetter"></mdc-text-field>
                <mdc-text-field outlined placeholder="Pris" formControlName="snittpris"></mdc-text-field>
                <mdc-text-field outlined placeholder="Sum" formControlName="salg_totalt"></mdc-text-field>
              </div>
            </form>
          </div>
        </mdc-dialog-content>
        <mdc-dialog-actions>
          <button mdc-button class="button-no-bg" mdcDialogAction="close">Avbryt</button>
          <button mdc-button primary raised default [disabled]="!forestillinginfo || !eksterntSalgForm.valid || !prisOgAntallOK()" (click)="avsluttForestilling()">Avslutt forestilling</button>
        </mdc-dialog-actions>
      </mdc-dialog-surface>
    </mdc-dialog-container>
</mdc-dialog>