import { Component, OnInit, Inject } from '@angular/core';
import { MDC_DIALOG_DATA, MdcDialogRef } from '@angular-mdc/web';
import { ContentService } from 'src/app/content.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-avlys-forestilling',
  templateUrl: './avlys-forestilling.component.html',
  styleUrls: ['./avlys-forestilling.component.scss']
})
export class AvlysForestillingComponent implements OnInit {
  public forestillinginfo: Forestillinginfo;
  public avlysForm = new FormGroup({
    avlyst_grunn: new FormControl(null, Validators.required),
    avlyst_faktureres: new FormControl(false, Validators.required),
    infotekst: new FormControl()
  })
  constructor(
    @Inject(MDC_DIALOG_DATA) private data,
    private contentService: ContentService,
    private dialogRef: MdcDialogRef<AvlysForestillingComponent>
  ) {}

  ngOnInit() {
    this.forestillinginfo = this.data.forestillinginfo;
  }

  avlysForestilling() {
    this.contentService.postContent('/salg/forestilling/' + this.forestillinginfo.id + '/avlys', this.avlysForm.value)
      .subscribe(
        res => {
          this.dialogRef.close(true);
        }

      )
  }

}
