<div>
  <p>Solgt på nett: 
    <span>{{ forestillinginfo?.n_per_ordretype?.nett || 0 }}</span>
  </p>
  <p>Solgt i kasse:
    <span>{{ forestillinginfo?.n_per_ordretype?.luke || 0 }}</span>
  </p>
  <p>Solgt totalt:
    <span>{{ forestillinginfo?.n_solgt || 0 }}</span>
  </p>
  <p>Antall skannede billetter: {{ forestillinginfo?.tickets_scanned || '0'}}</p>
  <p>Antall ledige billetter: {{ forestillinginfo?.n_tilgj || '?' }}</p>
</div>
