<mdc-dialog>
    <mdc-dialog-container>
      <mdc-dialog-surface>
        <mdc-dialog-title>Refundere til kort</mdc-dialog-title>
        <mdc-dialog-content>
            <p>Er du sikker på at du ønsker å refundere ordren tilbake til kort?</p>
            <p>Kunden vil få tilbake pengene på konto, og billettene / gavekortene vil bli ugyldige.</p>
        </mdc-dialog-content>
        <mdc-dialog-actions>
          <button mdc-button class="button-no-bg" mdcDialogAction="close">Avbryt</button>
          <button mdc-button raised (click)="refunder()">Refunder</button>
        </mdc-dialog-actions>
      </mdc-dialog-surface>
    </mdc-dialog-container>
  </mdc-dialog>
