import { Component } from '@angular/core';
import {AuthService} from './auth.service';
import {Router, NavigationEnd} from "@angular/router";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  title = 'Bygdekino';
  showNav = true;

  constructor(
    public auth: AuthService,
    private router: Router
  ) {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (this.router.url.match(/^\/salg/) || this.router.url === '/login' || this.router.url.indexOf('/plan-spillested') === 0 || this.router.url.indexOf('/resetpassword') !== -1 ) {
          this.showNav = false;
        } else {
          this.showNav = true;
        }
      }
    });
  }
}
