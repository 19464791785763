<mdc-dialog>
  <mdc-dialog-container>
    <mdc-dialog-surface>
      <mdc-dialog-title>Betale med gavekort</mdc-dialog-title>
      <mdc-dialog-content>

        <div class="gavekort__betaling-billett">
          <h3>Scann QR-kode</h3>
          <video #scanVideoElm></video>
          <h3>Eller skriv inn gavekortnummer:</h3>
          <div class="gavekort-betaling__nummer">
            <mdc-text-field  outlined label="Nummer" [formControl]="gavekortnummer"></mdc-text-field>
          </div>
          <div class="melding">
            <div class="invalid" *ngIf="gavekortnummer?.errors?.gavekort_err">{{getFeilmelding()}}</div>
            <div class="invalid" *ngIf="gavekortnummer?.errors?.minlength">Minst 8 tegn</div>
            <div class="invalid" *ngIf="gavekortnummer?.errors?.maxlength">Maks 32 tegn</div>
            <div class="valid" *ngIf="resultat && gavekortnummer.valid">
              Saldo: {{ resultat.gavekort_verdi }} kr
              (gyldig til {{ resultat.gyldig_til | date:'d.M.yyyy' }})
            </div>
          </div>
        </div>

      </mdc-dialog-content>
      <mdc-dialog-actions>
        <button mdc-button class="button-no-bg" mdcDialogAction="close">Avbryt</button>
        <button mdc-button raised [disabled]="!gavekortnummer.valid" (click)="betalMedGavekort()">Bruk</button>
      </mdc-dialog-actions>
    </mdc-dialog-surface>
  </mdc-dialog-container>
</mdc-dialog>
