import { MdcDialogRef, MDC_DIALOG_DATA } from '@angular-mdc/web';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'app-dialog-ordreoppslag',
  templateUrl: './dialog-ordreoppslag.component.html',
  styleUrls: ['./dialog-ordreoppslag.component.scss']
})
export class DialogOrdreoppslagComponent implements OnInit {

  public forestillingId: number;

  constructor(
    private dialogRef: MdcDialogRef<DialogOrdreoppslagComponent>,
    @Inject(MDC_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.forestillingId = this.data.forestillingId;
  }

  close() {
    this.dialogRef.close();
  }

}
