<div class="plan-spillested">
    <header class="content-header">
        <h2 mdcHeadline2>Bygdekinoen plan</h2>
    </header>

    <form class="sok-form" [formGroup]="formen">
        <p mdcBody1>Søk etter ditt spillested:</p>
        <div class="spillested-sok">
            <mdc-text-field outlined dense label="Spillested" formControlName='sok'>
            </mdc-text-field>

            <mdc-list *ngIf="spillesteder.length">
                <ng-container *ngFor="let spillested of spillesteder">
                    <mdc-list-item class="spillested-option" [routerLink]="['/plan-spillested', spillested.id]">{{ spillested.stedsnavn }}: {{ spillested.navn }}</mdc-list-item>
                </ng-container>
            </mdc-list>
        </div>
    </form>

    <h3 mdcHeadline3 class="spillestead-title" *ngIf="spillestedInfo">{{spillestedInfo.navn}}</h3>
    <section class="kjoreplan-side">
        <section class="kjoreplan">
            <div class="kjoreplan-item" *ngFor="let item of kjoreplan">
                <div class="kjoreplan-item__header">
                    <div>
                        <h4 mdcHeadline5>{{item.dato | date:'d.M.yyyy' }}</h4>
                        <div *ngIf="item.er_avlyst"><i>Avlyst</i></div>
                        <button mdc-button unelevated *ngIf="spilledagHarPublisertFilm(item) && ! item.er_avlyst" (click)="clickDownloadPoster(item.id)">Plakat</button>
                    </div>
                </div>
                <div class="kjoreplan-item__content">
                    <ng-container *ngIf="! item.er_avlyst">
                        <div class="kjoreplan-item__important">
                            <p *ngIf="item.alt_maskinist">OBS: Denne spilledagen kjøres av {{ item.alt_maskinist.navn }}</p>
                            <p *ngIf="item.er_skolekino">OBS: Skolekino</p>
                            <p *ngIf="item.alt_sted">Nytt sted: {{item.alt_sted}}</p>
                        </div>
                        <div class="kjoreplan-item__shows">
                            <div class="show" [class.upublisert]="!forestilling.film.klar_for_publisering" *ngFor="let forestilling of item.forestillinger">
                                <p class="show__time"><b>{{forestilling.tidspunkt}}</b></p>
                                <div>
                                    <p><b>{{forestilling.film.norsktittel}}</b></p>
                                    <p><span class="content__tag text__medium-emphasis">Tid:</span> {{ forestilling.film.spilletid | minutesToHours }}</p>
                                    <p><span class="content__tag text__medium-emphasis">Alder:</span> {{ forestilling.film.ageRating === 'A' ? 'Tillatt for alle' : forestilling.film.ageRating + ' år' }}</p>
                                    <p><span class="content__tag text__medium-emphasis">Solgte billetter:</span> {{ forestilling.ticket_summary.n_solgt }}</p>
                                </div>
                                <div class="show--avlyst" *ngIf="forestilling.er_avlyst">
                                    <p>Avlyst forestilling</p>
                                    <p>Begrunnelse: {{ forestilling.avlyst_grunn }}</p>
                                </div>
                                <p class="show--upublisert" *ngIf="!forestilling.film.klar_for_publisering && ! forestilling.er_avlyst">Planlagt film</p>
                            </div>
                            <p *ngIf="!item.forestillinger.length"><i>Ingen filmer er lagt til denne spilledagen ennå.</i></p>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="item.er_avlyst">
                        <div>
                            <p>Begrunnelse for avlysning: {{ item.avlyst_grunn }}</p>
                        </div>
                    </ng-container>
                </div>
            </div>
        </section>

        <section class="maskinist-section" *ngIf="spillestedInfo">
            <h4 mdcHeadline5>Fast maskinist</h4>
            <div class="maskinist-info" *ngFor="let ring of ringer">
                <h5 mdcHeadline6>{{ ring.maskinist.navn }}</h5>
                <p mdcBody1>{{ ring.maskinist.telefon1 }}</p>
                <p mdcBody1>{{ ring.maskinist.epost }}</p>
            </div>
        </section>
    </section>
</div>