import { Component, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { MdcDialogRef } from '@angular-mdc/web';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-legg-til-penger',
  templateUrl: './legg-til-penger.component.html',
  styleUrls: ['./legg-til-penger.component.scss']
})
export class LeggTilPengerComponent implements AfterViewInit {

  @ViewChild('initialfocuselem', {static: false}) initialfocuselem;

  public formen = new FormGroup({
    kroner: new FormControl(null, Validators.required)
  })

  constructor(
    private dialogRef: MdcDialogRef<LeggTilPengerComponent>
  ) {}

  ngAfterViewInit() {
    setTimeout(() => this.initfocus(), 200);
  }

  submit(event) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    this.dialogRef.close(this.formen.value);
  }

  initfocus() {
    let el = this.initialfocuselem;
    if (el) {
      if (el.nativeElement) {
        el = el.nativeElement;
      } else if (el.elementRef && el.elementRef.nativeElement) {
        el = el.elementRef.nativeElement;
      }
      if (el.querySelector) {
        el.querySelector('input').focus();
      }
    }
  }

}
