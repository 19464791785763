import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ContentService } from 'src/app/content.service';
import { MdcDialog } from '@angular-mdc/web';
import { BekreftZrapportComponent } from '../../common-components/dialogs/bekreft-zrapport/bekreft-zrapport.component';
import { XzrapportComponent } from '../../common-components/dialogs/xzrapport/xzrapport.component';
import { LeggTilForestillingComponent } from '../dialogs/legg-til-forestilling/legg-til-forestilling.component';
import { AvsluttForestillingComponent } from 'src/app/common-components/dialogs/avslutt-forestilling/avslutt-forestilling.component';
import { forkJoin } from 'rxjs';
import { AuthService } from 'src/app/auth.service';
import { BesoksrapportComponent } from '../dialogs/besoksrapport/besoksrapport.component';

const sortByTidspunkt_ASC = function(a, b) {
  return (a.dato + a.tidspunkt) >= (b.dato + b.tidspunkt) ? 1 : -1
}

@Component({
  selector: 'app-kasse',
  templateUrl: './kasse.component.html',
  styleUrls: ['./kasse.component.scss']
})
export class KasseComponent implements OnInit {

  public currentDate = new Date();
  public saleIsStarted: boolean;
  public dagens_forestillinger;
  public dagens_pr_sted;
  public dagens_spillesteder;
  public tidligere_forestillinger;
  public kasse;
  public maskinist;
  public kassasum = new FormControl();
  public showSpinner = false;

  constructor(
    private auth: AuthService,
    private contentService: ContentService,
    private dialog: MdcDialog
  ) {}

  ngOnInit() {
    this.maskinist = this.auth.getUserInfo();
    this.fetchInfo();
  }

  private fetchInfo(visBesoksrapport = false) {
    let forestillinger_til_kasse_url = `/salg/forestillinger_til_kasse`;
    if (visBesoksrapport) {
      forestillinger_til_kasse_url =  `/salg/forestillinger_til_kasse?include_sales=1`;
    }
    this.showSpinner = true;
    forkJoin([
      this.contentService.fetchContent(forestillinger_til_kasse_url),
      this.contentService.fetchContent(`/salg/uavsluttede_forestillinger`)
    ]).subscribe(data => {
      this.showSpinner = false;
        const dagens = data[0];
        const tidligere = data[1];
        this.kasse = dagens.kasse;
        if (this.kasse.aapen) {
          this.kassasum.setValue(this.kasse.belop);
          this.kassasum.disable();
        } else {
          this.kassasum.setValue(dagens['siste_z_rapp']['utg_veksel']);
          this.kassasum.enable();
        }
        this.tidligere_forestillinger = tidligere.forestillinger;
        this.dagens_forestillinger = [];
        dagens.ringer.forEach( ring => this.dagens_forestillinger.push(...ring.forestillinger) );
        this.dagens_forestillinger.sort(sortByTidspunkt_ASC);
        this.dagens_pr_sted = this.groupForestillingerBySted(this.dagens_forestillinger);
        this.dagens_spillesteder = this.dagens_pr_sted.map(sted => sted[0].spillested);
        if (visBesoksrapport) {// etter z-rapport
          this.showBesoksrapportDialog();
        }
      });
  }

  private groupForestillingerBySted(forestillinger) {
    // gruppér på sted...
    const grouped = {};
    forestillinger.forEach(f => {
      let sid = f.spillested.id
      if (grouped[sid]) {
        grouped[sid].push(f);
      } else {
        grouped[sid] = [f];
      }
    })
    // og sortér på tidspunkt
    return Object.values(grouped).map(
      (sted:Array<any>) => sted.sort(sortByTidspunkt_ASC)
    ).sort((a, b) => sortByTidspunkt_ASC(a[0], b[0]))
  }

  startSale() {
    // do request to server
    this.contentService.postContent('/salg/kasse/start', {
      belop: this.kassasum.value
    }).subscribe(kasse => {
      this.kasse = kasse;
      this.kassasum.disable();
    })
  }

  canEndDay() {
    if (this.dagens_forestillinger.find(f => ! (f.avsluttet || f.er_avlyst))) {
      return false;
    } else {
      return true;
    }
  }

  confirmEndDay() {
    this.dialog.open(BekreftZrapportComponent).afterClosed()
      .subscribe(action => {
        if (action === 'accept') {
          this.endDay();
        }
      });
  }

  endDay() {
    this.dialog.open( XzrapportComponent, {data: {type: 'z'}} )
      .afterClosed().subscribe( closed => {
        this.fetchInfo(true);
      } );
  }

  public leggTilForestilling() {
    const ref = this.dialog.open(LeggTilForestillingComponent, {
      data: {
        maskinist_id: this.maskinist.maskinist
      }
    });
    ref.afterClosed().subscribe(
      res => {
        this.fetchInfo();
      }
    )
  }

  public avsluttForestilling(forestilling) {
    const ref = this.dialog.open(AvsluttForestillingComponent, {
      data: {
        id: forestilling.id
      }
    });
    ref.afterClosed().subscribe(res => {
      if (res !== 'close') {
        this.fetchInfo();
      }
    })
  }

  public showBesoksrapportDialog() {
    const data = {
      dagens: this.dagens_pr_sted,
      maskinist: this.maskinist
    };
    this.dialog.open(BesoksrapportComponent, { data });
  }

}
