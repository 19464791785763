import { Component, OnInit } from '@angular/core';
import {AuthService} from '../auth.service';
import {NavigationEnd, Router} from '@angular/router';

interface Destination {
  label?: string;
  icon?: string;
  route?: string;
  subLinks?: [];
  disabled?: boolean;
}

@Component({
  selector: 'app-navigasjon',
  templateUrl: './navigasjon.component.html',
  styleUrls: ['./navigasjon.component.scss']
})
export class NavigasjonComponent implements OnInit {
  destinations;
  openMenu = false;

  maskinistDestinations = [
    { label: 'Dashboard', icon: 'star', route: '/' },
    { label: 'Kjøreplan', icon: 'view_list', route: '/kjoreplan' },
    { label: 'Ruteplan', icon: 'today', route: '/ringer' },
    { label: 'Spillesteder', icon: 'store_mall_directory', route: '/admin/spillesteder' },
    { label: 'Ordreoppslag', icon: 'poll', route: '/admin/ordreoppslag'}
  ];

  adminDdestinations = [
    { label: 'Dashboard', icon: 'star', route: '/' },
    { label: 'Spillesteder', icon: 'store_mall_directory', route: '/admin/spillesteder' },
    { label: 'Kasse', icon: 'local_activity', route: '/admin/kasse' },
    { label: 'Kampanjer', icon: 'festival', route: '/admin/kampanjer' },
    { label: 'Filmprogrammering', icon: 'movie',
        subLinks: [
            { label: 'Filmprogram', route: '/admin/filmprogrammering' },
            { label: 'Filmer', route: '/admin/filmer' },
            { label: 'Filmdistributører', route: '/admin/filmdistributorer' }
        ]
    },
    { label: 'Ruteplanlegging', icon: 'today',
        subLinks: [
            { label: 'Ruteplan', route: '/ringer' },
            { label: 'Kjøreplan', route: '/kjoreplan' },
            { label: 'Maskinister', route: '/admin/maskinister' }
        ]
    },
    { label: 'Rapporter', icon: 'poll',
        subLinks: [
            { label: 'Besøkstall', route: '/admin/tidligere' },
            { label: 'Kommende forestillinger', route: '/admin/fremtidige'},
            { label: 'Oppgjør med spillesteder', route: '/admin/oppgjor' },
            { label: 'Faktura', route: '/faktura' },
            { label: 'Rapporter til Filmweb', route: '/admin/filmweb'},
            { label: 'SAF-T', route: '/admin/saf-t' },
            { label: 'Salgsrapporter', route: '/admin/z-rapporter' },
            { label: 'Avtaler', route: '/admin/avtaler' },
            { label: 'Ordreoppslag', route: '/admin/ordreoppslag' },
            { label: 'Refunderinger', route: '/admin/refunderinger' },
            { label: 'Betalingsstatus', route: '/admin/betalingsstatus' }
        ]
    },
    { label: 'Admin', icon: 'settings',
        subLinks: [
            { label: 'Globale verdier', route: '/admin/global' },
            { label: 'Brukere', route: '/admin/brukere' }
        ]
    }
  ];

  constructor(
    private router: Router,
    public auth: AuthService
  ) {
      // TODO : make observable of userInfo instead of using router events
      router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.destinations = this.auth.isAdmin() ? this.adminDdestinations : this.maskinistDestinations;
          this.closeNav();
        }
      });
  }

  ngOnInit() {
    this.destinations = this.auth.isAdmin() ? this.adminDdestinations : this.maskinistDestinations;
  }

  logOut() {
    this.auth.logout();
  }

  closeNav() {
    this.openMenu = false;
    const navEl = document.getElementsByClassName('navigation-drawer')[0];
    if (!navEl) {
      return;
    }
    navEl.classList.remove('drawer--open');
  }

  openNav() {
    this.openMenu = true;
    const navEl = document.getElementsByClassName('navigation-drawer')[0];
    if (!navEl) {
      return;
    }
    navEl.classList.add('drawer--open');
  }

  minifyNav() {
    const navEl = document.getElementsByClassName('navigation-drawer')[0];
    if (navEl.classList.contains('drawer--minified')) {
      navEl.classList.remove('drawer--minified');
    } else {
      navEl.classList.add('drawer--minified');
    }

  }

}
