<mdc-dialog>
    <mdc-dialog-container>
      <mdc-dialog-surface>
        <mdc-dialog-title>Send på nytt</mdc-dialog-title>
        <mdc-dialog-content>
            <h4 mdcHeadline5>Gavekort</h4>
            <p>Registrert mottaker:<br/>{{ gavekort.mottaker?.navn }} {{ gavekort.mottaker?.epost }}</p>
            <p>Gyldig til {{ gavekort.gyldig_ts | date: 'd.M.yyyy HH:mm'}}</p>
            <p class="gavekort__uuid">{{ gavekort.uuid }}</p>
            <p class="gavekort__verdi">Originalverdi: <span class="belop">kr {{ gavekort.org_verdi }}</span> Gjenstående: <span class="belop">kr {{ gavekort.verdi }}</span></p>
            <div class="gavekort__mottaker">
              <p>Gavekortet sendes til valgt epostadresse:</p>
                <mdc-form-field fluid>
                    <mdc-text-field [formControl]="epost" label="Epostadresse" outlined></mdc-text-field>
                </mdc-form-field>
            </div>
        </mdc-dialog-content>
        <mdc-dialog-actions>
          <button mdc-button class="button-no-bg" mdcDialogAction="close">Avbryt</button>
          <button mdc-button raised (click)="sendGavekort()">Send gavekort</button>
        </mdc-dialog-actions>
      </mdc-dialog-surface>
    </mdc-dialog-container>
  </mdc-dialog>