import { Component, OnInit, Input } from '@angular/core';
import { ContentService } from '../../content.service';

@Component({
  selector: 'app-faktura-liste',
  templateUrl: './faktura-liste.component.html',
  styleUrls: ['./faktura-liste.component.scss']
})
export class FakturaListeComponent implements OnInit {
  public fakturaer;
  public showAll = false;
  @Input() onlyFullforte: boolean;
  constructor(
    private contentService: ContentService
  ) { }

  ngOnInit() {
    if (this.onlyFullforte) {
      this.contentService.fetchContent('/salg/transaksjon/fakturert/ikkefullfort')
        .subscribe(res => {
          this.fakturaer = res;
        })
    } else {
      this.contentService.fetchContent('/salg/transaksjon/fakturert')
      .subscribe(res => {
        this.fakturaer = res;
      })
    }
  }

  public doShowAll() {
    this.fakturaer = null;
    this.contentService.fetchContent('/salg/transaksjon/fakturert?fetch_all=true')
        .subscribe(res => {
          this.fakturaer = res;
        })
  }

  public markAsFullfort(transaksjon_id) {
    this.contentService.postContent('/salg/transaksjon/' + transaksjon_id + '/fakturert/fullfor', {})
    .subscribe(res => {
      this.fakturaer.find(f => f.id === transaksjon_id).betaling_info.fullfort = true;
    })
  }

}
