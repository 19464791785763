<mdc-dialog>
    <mdc-dialog-container>
        <mdc-dialog-surface>
            <mdc-dialog-title>Billettscanner for {{forestillinginfo.spillested_tittel}}</mdc-dialog-title>
            <mdc-dialog-content>
                <div [innerHTML]="qr | safeHtml"></div>
                <a [href]="url" target="_blank">Åpne i nytt vindu</a>
                <mdc-dialog-actions>
                    <button mdc-button class="button-no-bg" mdcDialogAction="close">Lukk</button>
                </mdc-dialog-actions>
            </mdc-dialog-content>
        </mdc-dialog-surface>
    </mdc-dialog-container>
</mdc-dialog>