<div mdcMenuSurfaceAnchor #moreAnchor>
    <button mdc-button [disabled]="disabled" class="more-menu__button button-no-bg" (click)="moreMenu.open = !moreMenu.open">
        <mdc-icon>more_vert</mdc-icon>
    </button>

    <mdc-menu #moreMenu [anchorElement]="moreAnchor" anchorCorner="bottomStart" defaultFocusState="firstItem" class="valg__meny">
        <mdc-list>
            <mdc-list-item *ngFor="let sted of spillesteder" [disabled]="!spillesteder?.length" (click)="showBillettscannerlenke(sted)">
                <span class="mdc-list-item__text">
                    <span class="mdc-list-item__primary-text">Billettscanner</span>
                    <span class="mdc-list-item__secondary-text">{{sted.navn}}</span>
                </span>
            </mdc-list-item>
            <mdc-list-item (click)="refunderBilletter()">Refundere billetter</mdc-list-item>
            <mdc-list-item (click)="sjekkGavekort()">Sjekk gavekort</mdc-list-item>
            <mdc-list-item (click)="visXrapport()" [disabled]="!kasse.aapen">X-rapport</mdc-list-item>
            <mdc-list-item (click)="visZrapporter()">Z-rapporter</mdc-list-item>
            <mdc-list-item (click)="showBesoksrapportDialog()">Besøksrapport</mdc-list-item>
        </mdc-list>
    </mdc-menu>
</div>
