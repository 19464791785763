import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MdcDialog } from '@angular-mdc/web';
import { LeggTilPengerComponent } from '../dialogs/legg-til-penger/legg-til-penger.component';
import { VippsBetalingComponent } from '../dialogs/vipps-betaling/vipps-betaling.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-betaling',
  templateUrl: './betaling.component.html',
  styleUrls: ['./betaling.component.scss']
})
export class BetalingComponent implements OnInit, OnChanges {
  @Input() public ordre: Ordre;
  @Input() public forestillingId: number;
  @Input() public gjenstaaende: number;
  @Input() public disabled: boolean;
  @Output() public betalt = new EventEmitter<Betaling>();
  @ViewChild('initialfocuselem', {static: false}) initialfocuselem;

  public sum = new FormControl();
  public absGjenstaaende: Number;// evt. 'public Math = Math;' og Math.abs() direkte i templaten?
  public show_vipps;

  constructor(
    private dialog: MdcDialog,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.sum.setValue(this.gjenstaaende.toFixed(2) || 0.00);
    this.absGjenstaaende = Math.abs(this.gjenstaaende || 0);

    this.show_vipps = true;
    //this.route.queryParams.subscribe(params => {
    //  this.show_vipps = Boolean(params.show_vipps);
    //  console.log("show vipps =", this.show_vipps);
    //});
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.gjenstaaende) {
      this.sum.setValue( Math.max(0, changes.gjenstaaende.currentValue).toFixed(2) );
      this.absGjenstaaende = Math.abs( changes.gjenstaaende.currentValue );
    }
    if (changes.disabled) {
      if (changes.disabled.currentValue) {
        this.sum.disable();
      } else {
        this.sum.enable();
      }
    }
  }

  onSpesialbetalt(info) {
    this.betalt.emit(info);
  }

  onBetalt(type: BetalingsType) {
    this.betalt.emit({
      belop: parseInt(this.sum.value),
      betalingstype: type
    });
  }

  openVippsBetaling() {
    this.dialog.open(VippsBetalingComponent, {
      data: {
        belop: parseInt(this.sum.value),
        forestillingId: this.forestillingId,
        ordre: this.ordre
      }
    }).afterClosed().subscribe(res => {
      if (res.updateOrder) {
        this.onBetalt('vipps');
        return;
      }
      console.log(res);
    });
  }

  initfocus() {
    if (this.initialfocuselem) {
      this.initialfocuselem.elementRef.nativeElement.focus();
    }
  }

  addToSum() {
    this.dialog.open(LeggTilPengerComponent).afterClosed()
      .subscribe((res: any) => {
        if (res.kroner && res.kroner > 0) {
          this.sum.setValue( parseFloat( this.sum.value ) + parseFloat(res.kroner) );
        }
        setTimeout(() => this.initfocus(), 200);
      });
  }

}
