<mdc-dialog>
  <mdc-dialog-container>
    <mdc-dialog-surface>
      <mdc-dialog-title>Avlys forestilling</mdc-dialog-title>
      <mdc-dialog-content>
        <div><p class="avlys__warning">Er du sikker på at du vil avlyse forestillingen?</p></div>
        <div>
          <p>{{ forestillinginfo?.film_tittel }}</p>
          <p>{{ forestillinginfo?.dato | date: 'd.M.yyyy' }} {{ forestillinginfo?.tidspunkt }}</p>
          <p>{{ forestillinginfo?.spillested_tittel }}</p>
        </div>

        <div>
          <form [formGroup]="avlysForm">
            <mdc-form-field fluid>
              <mdc-text-field outlined formControlName="avlyst_grunn" label="Avlyst grunn"></mdc-text-field>
            </mdc-form-field>
            <mdc-form-field fluid>
              <mdc-text-field class="w50" outlined formControlName="infotekst" label="Infotekst"></mdc-text-field>
            </mdc-form-field>
            <mdc-form-field>
              <mdc-checkbox formControlName="avlyst_faktureres"></mdc-checkbox>
              <label>Spillestedet skal faktureres</label>
            </mdc-form-field>
          </form>
          <p style="margin-top: 1em;">NB! Eventuelle billetter til forestilingen må refunderes manuelt, enten i din kasse eller på kontoret.</p>
        </div>
      </mdc-dialog-content>
      <mdc-dialog-actions>
        <button mdc-button class="button-no-bg" mdcDialogAction="close">Avbryt</button>
        <button mdc-button raised [disabled]="!avlysForm.valid" (click)="avlysForestilling()">Avlys</button>
      </mdc-dialog-actions>
    </mdc-dialog-surface>
  </mdc-dialog-container>
  </mdc-dialog>