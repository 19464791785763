<div class="ordre__status">
  <div *ngIf="ordre && !ordre.gavekort?.length && !produkter.length">
    <p>Ordren er tom</p>
  </div>
  <div class="ordre__varer">
    <!-- <p *ngIf="ordre.billetter && ordre.billetter.length">
      <span class="vare__tekst">{{ ordre.billetter.length }} billett{{ ordre.billetter.length===1 ? '' : 'er' }}:</span>
      <span class="vare__belop">{{ ordre.sum - gavekortSum() }} kr</span>
    </p> -->
    <p *ngIf="ordre.gavekort && ordre.gavekort.length">
      <span class="vare__tekst">{{ (ordre.gavekort ? ordre.gavekort.length : 0) }} gavekort:</span>
      <span class="vare__belop">{{ gavekortSum() }} kr</span>
    </p>
  </div>
  <div class="ordre__transaksjoner">
    <!-- TMP resirkulering av css/markup? -->
    <div *ngFor="let p of produkter" class="status__ordre-linje ordre-linje__produkt">
      <div class="transaksjon--fjern">
        <button mdc-button class="button-no-bg" [disabled]="disabled" *ngIf="!ordre.avsluttet" (click)="undoProdukt(p)"><mdc-icon>clear</mdc-icon></button>
      </div>
      <p class="transaksjon__tekst">{{ p.label }} <span class="billettinfo" *ngIf="p.info">{{ p.info }}</span></p>
      <p class="transaksjon__belop">{{ p.sum ? p.sum + ' kr' : '' }}</p>
    </div>

    <div *ngFor="let t of transaksjoner" class="status__ordre-linje">
      <ng-container *ngIf="t.er_korreksjon">
          <div class="transaksjon--fjern">
          </div>
          <p class="transaksjon__tekst transaksjon--linjekorreksjon">Korreksjon: {{ fmtPaymentType(t.betalingstype) }}:</p>
          <p class="transaksjon__belop transaksjon--linjekorreksjon">{{ t.belop }} kr</p>
      </ng-container>
      <ng-container *ngIf="!t.er_korreksjon">
          <div class="transaksjon--fjern">
              <button *ngIf="!ordre.avsluttet && !t.er_korrigert" mdc-button class="button-no-bg" [disabled]="disabled" (click)="undoTransaction(t.id)"><mdc-icon>clear</mdc-icon></button>
          </div>
          <p class="transaksjon__tekst">Betalt med {{ fmtPaymentType(t.betalingstype) }}:</p>
          <p class="transaksjon__belop">{{ t.belop }} kr</p>
      </ng-container>
    </div>
  </div>
  <div *ngIf="gavekort_saldo > -1" class="gavekort_saldo">
    Gavekort saldo etter fullført salg: {{ gavekort_saldo }} kr
  </div>
  <div class="ordre__gjenstaaende">
    <div class="gjenstaaende__tekst">{{ gjenstaaende() >= 0 ? 'Å betale' : 'Tilbake'}}:</div>
    <div class="gjenstaaende__belop">{{ absGjenstaaende() | number:'1.2-2' }} kr</div>
  </div>
  <div class="ordre__actions">
    <button #initialfocuselem mdc-button primary raised [disabled]="(!ordre.gavekort?.length && !produkter.length) || disabled || kun_ledsagere || gjenstaaende() > 0" (click)="avsluttMedUtskrift()"
    >Skriv ut</button>
    <button mdc-button raised [disabled]="(!ordre.gavekort?.length && !produkter.length) || disabled || kun_ledsagere || gjenstaaende() > 0" (click)="avsluttMedEpost()"
    >Send epost</button>
    <button mdc-button outlined class="button-no-bg" [disabled]="disabled || !ordre || !ordre.kansellerbar" (click)="cancelOrdre()"
    >Slett ordre</button>
  </div>
</div>
