import { Component, Inject } from '@angular/core';
import { MDC_DIALOG_DATA } from '@angular-mdc/web';
@Component({
  selector: 'app-bekreft-billetter',
  templateUrl: './bekreft-billetter.component.html',
  styleUrls: ['./bekreft-billetter.component.scss']
})
export class BekreftBilletterComponent {
  public forestillinginfo: Forestillinginfo;
  public antallBilletter: number;
  constructor(
    @Inject(MDC_DIALOG_DATA) private data
  ) {
    this.forestillinginfo = data.forestillinginfo;
    this.antallBilletter = data.antallBilletter;
  }

}
