
import { ContentService } from '../../content.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { debounceTime, switchMap } from "rxjs/operators";
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { EMPTY, forkJoin } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-kjoreplan-spillested',
  templateUrl: './kjoreplan-spillested.component.html',
  styleUrls: ['./kjoreplan-spillested.component.scss']
})
export class KjoreplanSpillestedComponent implements OnInit {

  formen;
  spillesteder = [];
  spillestedInfo;
  ringer;
  kjoreplan = [];

  constructor(
    private contentService: ContentService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {

    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    ).subscribe(e => this.hentInfoHvisRutaHarId());

    this.formen = new FormGroup({
      sok: new FormControl()
    });

    this.formen.get('sok').valueChanges.pipe(
      debounceTime(300),
      switchMap((value: any) => {
        if (value) {
          return this.contentService.fetchContent('/public/spillesteder?q=' + value);
        } else {
          this.spillesteder = [];
          return EMPTY;
        }
      })
    ).subscribe(spillesteder => {
      this.spillesteder = spillesteder;
    });

    this.hentInfoHvisRutaHarId();
  }

  hentInfoHvisRutaHarId() {
    const id = this.route.snapshot.params.id;
    if (id) {
      this.getSpillestedInfo(id);
    }
  }

  getSpillestedInfo(spillestedId) {
    this.spillestedInfo = this.kjoreplan = this.ringer = null;
    this.spillesteder = [];
    this.formen.setValue({ sok: '' }, { emitEvent: false });
    forkJoin(
      this.contentService.fetchContent('/public/spillested/' + spillestedId),
      this.contentService.fetchContent('/public/spillested/' + spillestedId + '/kjoreplan')
    ).subscribe(res => {
      this.spillestedInfo = res[0];
      this.kjoreplan = res[1];
      this.ringer = this.spillestedInfo.ringer;
    });
  }

  spilledagHarPublisertFilm(dag): Boolean {
    return ( dag && dag.forestillinger && !!dag.forestillinger.find(f => f.film.klar_for_publisering) );
  }

  clickDownloadPoster(id: string) {
    window.open(`${environment.BACKEND_SERVER_URL}/ruteplan/${id}/poster`, '_blank');
  }

}
