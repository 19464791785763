<div class="mobile-topbar">
    <button class="menu-button" (click)="openNav()"><mdc-icon>menu</mdc-icon></button>
</div>

<nav class="navigation-drawer">
    <mdc-drawer drawer="permanent" class="kinologg-drawer">
      <div class="mobile-topbar"><button class="menu-button" (click)="closeNav()"><mdc-icon>close</mdc-icon></button></div>
      <a class="drawer-header__link" routerLink="/"><mdc-drawer-header title="Kinologg" class="kinologg-drawer__header"></mdc-drawer-header></a>
      <mdc-drawer-content>
        <div class="drawer__user-info">
            <p>{{auth.getSessionInfo()?.user?.fornavn}} {{auth.getSessionInfo()?.user?.etternavn}}</p>
            <button mdc-button class="logout-button" (click)="logOut()">Logg ut</button>
        </div>
        <mdc-list-divider></mdc-list-divider>
        <mdc-list useActivatedClass class="navigation-list">
            <ng-container *ngFor="let item of destinations">
                <a mdc-list-item
                        (selectionChange)="item.visible = !item.visible"
                        [ngClass]="{'list-item--expanded' : item.visible}"
                        class="navigation-list__primary"
                        routerLink="{{item.route}}"
                        *ngIf="item.route; else expandLink">
                    <mdc-icon mdcListItemGraphic *ngIf="item.icon">{{item.icon}}</mdc-icon>
                    <span class="primary__label">{{item.label}}</span>
                    <mdc-icon mdcListItemMeta *ngIf="item.subLinks">expand_more</mdc-icon>
                </a>
                <ng-template #expandLink>
                  <a mdc-list-item
                        (selectionChange)="item.visible = !item.visible"
                        [ngClass]="{'list-item--expanded' : item.visible}"
                        class="navigation-list__primary">
                    <mdc-icon mdcListItemGraphic *ngIf="item.icon">{{item.icon}}</mdc-icon>
                    <span class="primary__label">{{item.label}}</span>
                    <mdc-icon mdcListItemMeta *ngIf="item.subLinks">expand_more</mdc-icon>
                  </a>
                </ng-template>
                <div *ngIf="item.subLinks && item.visible" class="navigation-list__sublinks">
                  <a mdc-list-item *ngFor="let sublink of item.subLinks" routerLink="{{sublink.route}}">
                      {{sublink.label}}
                  </a>
                </div>
            </ng-container>
        </mdc-list>
      </mdc-drawer-content>
      <mdc-list-divider></mdc-list-divider>
      <div class="drawer__minify-expand" (click)="minifyNav()">
        <mdc-icon mdcListItemGraphic class="toggle-expand-icon">first_page</mdc-icon>
      </div>
    </mdc-drawer>
</nav>


