<app-spinner *ngIf="!ruteplan"></app-spinner>

<div *ngIf="ruteplan" class="plan-spillested">
  <header class="content-header">
    <h2 mdcHeadline2>Bygdekinoen ruteplan for {{ ring.navn }}</h2>
  </header>

  <mdc-card class="ruteplan">

    <div class="ruteplan-header">
      <form [formGroup]="periodSelectForm" class="ruteplan__year">
        <mdc-select placeholder="År" formControlName="year" outlined>
          <mdc-menu>
            <mdc-list>
              <mdc-list-item *ngFor="let option of periodOptions" [value]="option">{{option}}</mdc-list-item>
            </mdc-list>
          </mdc-menu>
        </mdc-select>
      </form>

      <div class="legend avlyst">Avlyst</div>
      <div class="legend kjoredag">Kjøredag</div>
      <div class="legend skolekino">Skolekino</div>
      <div class="legend ustandard-sted">Alternativt sted</div>
      <div class="legend alt-maskinist">Annen maskinist</div>
      <div class="legend helligdag">Helligdag</div>
    </div>

    <section class="ruteplan-container" *ngIf="ruteplan?.length">

      <div class="ruteplan-column column-days">
        <div class="ruteplan-column__item column-head"></div>
        <div class="ruteplan-column__item ruteplan__label" *ngFor="let day of weekdays">
          {{ day }}
        </div>
      </div>

      <ng-container *ngFor="let program of ruteplan">
        <div class="ruteplan-column">

          <div class="ruteplan-column__item ruteplan__label column-head">{{ program.program_nr }}</div>

          <div *ngFor="let emptyday of program.emptydays" class="ruteplan-column__item"></div>

          <div *ngFor="let day of program.days" class="ruteplan-column__item" [ngClass]="{
              'avlyst': day.er_avlyst,
              'kjoredag': day.er_kjoredag,
              'skolekino': day.er_skolekino,
              'helligdag': day.er_helligdag,
              'ustandard-sted': day.alt_sted,
              'merknad': day.merknad }"
          >
            <span class="column-item__dato">{{day.dato | date:'d.M'}}</span>
            <span *ngIf="day.spillested_id" class="column-item__spillested">{{ day.spillested?.stedsnavn }}</span>
          </div>
        </div>
      </ng-container>
    </section>
  </mdc-card>
</div>