import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvsluttForestillingComponent } from './dialogs/avslutt-forestilling/avslutt-forestilling.component';
import { AppMaterialModule } from '../material/material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { FakturaListeComponent } from './faktura-liste/faktura-liste.component';
import { DialogConfirmComponent } from './dialogs/dialog-confirm/dialog-confirm.component';
import { DialogVelgMaskinistComponent } from './dialogs/dialog-velg-maskinist/dialog-velg-maskinist.component';
import { KjoreplanComponent } from './kjoreplan/kjoreplan.component';
import { KjoreplanSpillestedComponent } from './kjoreplan-spillested/kjoreplan-spillested.component';
import { KjoreplanValgComponent } from './kjoreplan-valg/kjoreplan-valg.component';
import { MinutesToHoursPipe } from './minutes-to-hours.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { RouterModule } from '@angular/router';
import { PublicRuteplanComponent } from './public-ruteplan/public-ruteplan.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { SalkartComponent } from './salkart/salkart.component';
import { DialogSeSalkartForestillingComponent } from './dialogs/dialog-se-salkart-forestilling/dialog-se-salkart-forestilling.component';
import { OrdreoppslagComponent } from './ordreoppslag/ordreoppslag.component';
import { XzrapportComponent } from './dialogs/xzrapport/xzrapport.component';
import { BekreftZrapportComponent } from './dialogs/bekreft-zrapport/bekreft-zrapport.component';
import { KasseStartStoppComponent } from './kasse-start-stopp/kasse-start-stopp.component';
import { DialogSendGavekortComponent } from './dialogs/dialog-send-gavekort/dialog-send-gavekort.component';
import { DialogRefunderingTilbakemeldingComponent } from './dialogs/dialog-refundering-tilbakemelding/dialog-refundering-tilbakemelding.component';
import { DialogRefunderingComponent } from './dialogs/dialog-refundering/dialog-refundering.component';
import { DialogVelgForestillingComponent } from './dialogs/dialog-velg-forestilling/dialog-velg-forestilling.component';
import { DialogVelgSpillestedComponent } from './dialogs/dialog-velg-spillested/dialog-velg-spillested.component';
import { DialogVelgFilmComponent } from './dialogs/dialog-velg-film/dialog-velg-film.component';
import { DialogGenererKampanjekoderComponent } from './dialogs/dialog-generer-kampanjekoder/dialog-generer-kampanjekoder.component';


@NgModule({
  declarations: [
    AvsluttForestillingComponent,
    FakturaListeComponent,
    DialogConfirmComponent,
    DialogVelgMaskinistComponent,
    KjoreplanComponent,
    KjoreplanSpillestedComponent,
    KjoreplanValgComponent,
    MinutesToHoursPipe,
    SafeHtmlPipe,
    PublicRuteplanComponent,
    SpinnerComponent,
    SalkartComponent,
    DialogSeSalkartForestillingComponent,
    OrdreoppslagComponent,
    XzrapportComponent,
    BekreftZrapportComponent,
    KasseStartStoppComponent,
    DialogSendGavekortComponent,
    DialogRefunderingTilbakemeldingComponent,
    DialogRefunderingComponent,
    DialogVelgForestillingComponent,
    DialogVelgSpillestedComponent,
    DialogVelgFilmComponent,
    DialogGenererKampanjekoderComponent
  ],
  exports: [
    AvsluttForestillingComponent,
    FakturaListeComponent,
    SpinnerComponent,
    MinutesToHoursPipe,
    SafeHtmlPipe,
    SalkartComponent,
    OrdreoppslagComponent,
    KasseStartStoppComponent
  ],
  entryComponents: [
    AvsluttForestillingComponent,
    DialogConfirmComponent,
    DialogVelgMaskinistComponent,
    SalkartComponent,
    DialogSeSalkartForestillingComponent,
    OrdreoppslagComponent,
    XzrapportComponent,
    BekreftZrapportComponent,
    DialogSendGavekortComponent,
    DialogRefunderingTilbakemeldingComponent,
    DialogRefunderingComponent,
    DialogVelgForestillingComponent,
    DialogVelgSpillestedComponent,
    DialogVelgFilmComponent,
    DialogGenererKampanjekoderComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    AppMaterialModule
  ]
})
export class CommonComponentsModule {}
