<section>
    <div class="dashboard__admin" >
        <h2 class="dashboard__heading" mdcHeadline2>Hei, {{ userInfo.fornavn }}</h2>
        <div class="dashboard-group">
            <mdc-card>
                <div class="card__header">
                    <h3 mdcHeadline4>Upubliserte forestillinger</h3>
                    <a routerLink="/admin/filmprogrammering">Gå til filmprogrammeringen</a>
                </div>
                <div class="card__content">
                    <mdc-list *ngIf="upubliserteForestillinger?.length" interactive="false">
                        <mdc-list-item class="forestilling-list-item" *ngFor="let item of upubliserteForestillinger">
                            <div class="list-item-text">{{ item.dato | date:'d.M.yyyy' }}</div>
                            <div class="list-item-text">{{ item.spillested }}</div>
                            <div class="list-item-text">{{ item.film.norsktittel }}</div>
                        </mdc-list-item>
                    </mdc-list>
                    <p *ngIf="!upubliserteForestillinger?.length && !henterForestillinger">Alle forestillinger for de neste 14 dagene er publisert til
                        nettstedet.</p>
                    <app-spinner [inline]="true" *ngIf="!upubliserteForestillinger?.length && henterForestillinger"></app-spinner>
                </div>
            </mdc-card>
            <mdc-card class="card--kasser">
                <div class="card__header">
                    <h3 mdcHeadline4>Åpne kasser</h3>
                </div>
                <div class="card__content">
                    <p *ngIf="aapneKasser?.length" class="p__body">Det finnes {{ aapneKasser.length }} åpne kasser</p>
                    <mdc-data-table  *ngIf="aapneKasser?.length">
                        <table mdcDataTableTable>
                            <thead>
                                <tr mdcDataTableHeaderRow>
                                    <td mdcDataTableHeaderCell>Kasse-ID</td>
                                    <td mdcDataTableHeaderCell>Navn</td>
                                    <td mdcDataTableHeaderCell numeric>Registrert kassebeholdning</td>
                                    <td mdcDataTableHeaderCell>Åpnet</td>
                                </tr>
                            </thead>
                            <tbody MDCDataTableContent>
                                <tr mdcDataTableRow *ngFor="let kasse of aapneKasser">
                                    <td mdcDataTableCell>{{ kasse.kasse_id }}</td>
                                    <td mdcDataTableCell>{{ kasse.fornavn }} {{ kasse.etternavn }}</td>
                                    <td mdcDataTableCell numeric>Kr {{ kasse.belop }}</td>
                                    <td mdcDataTableCell [class.kasse__warning]="kasse.advarsel">{{ kasse.aapnet_ts | date: 'd.M.yyyy HH:mm' }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </mdc-data-table>
                    <p *ngIf="!aapneKasser?.length && !henterKasser">Det finnes ingen åpne kasser.</p>
                    <app-spinner [inline]="true" *ngIf="!aapneKasser?.length && henterKasser"></app-spinner>
                </div>
            </mdc-card>
            <mdc-card class="card--faktura">
                <div class="card__header">
                    <h3 mdcHeadline4>Fakturasalg</h3>
                    <a routerLink="/faktura">Se alle</a>
                </div>
                <div class="card__content">
                    <app-faktura-liste [onlyFullforte]="true"></app-faktura-liste>
                </div>
            </mdc-card>
            <mdc-card>
                <div class="card__header">
                    <h3 mdcHeadline4>Nyttige lenker</h3>
                </div>
                <div class="card__content">
                    <mdc-list>
                        <a mdc-list-item class="link-list-item" href="{{hentBackendUrl()}}/static/doc/kinologg_brukerveiledning.pdf" target="_blank">
                            <div>Brukerveiledning for Kinomaskinister</div>
                            <mdc-icon mdcListItemMeta>chevron_right</mdc-icon>
                        </a>
                        <a mdc-list-item class="link-list-item" routerLink="/plan-spillested" target="_blank">
                            <div>Kjøreplan for spillesteder</div>
                            <mdc-icon mdcListItemMeta>chevron_right</mdc-icon>
                        </a>
                        <a mdc-list-item class="link-list-item" href="{{hentBackendUrl()}}/api/v1/admin/rapporter/spillesteder" target="_blank">
                            <div>Alle aktive spillesteder (CSV-fil)</div>
                            <mdc-icon mdcListItemMeta>chevron_right</mdc-icon>
                        </a>
                    </mdc-list>
                </div>
            </mdc-card>
        </div>
    </div>
</section>