import { Component, OnInit } from '@angular/core';
import { ContentService } from '../../../content.service';
import { AuthService } from '../../../auth.service';
import { MdcDialogRef } from '@angular-mdc/web';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-dialog-velg-maskinist',
  templateUrl: './dialog-velg-maskinist.component.html',
  styleUrls: ['./dialog-velg-maskinist.component.scss']
})
export class DialogVelgMaskinistComponent implements OnInit {

  public andre_maskinister;
  public matches;
  public sokefelt = new FormControl('', [Validators.required, Validators.minLength(1)]);

  constructor(
    private contentService: ContentService,
    private auth: AuthService,
    public dialogRef: MdcDialogRef<DialogVelgMaskinistComponent>
  ) {}

  ngOnInit() {
    // last full maskinist-liste (og filtrert bort en selv + de uten navn)
    this.contentService.fetchAktiveMaskinister().subscribe(
      (res: any) => {
        let myId = this.auth.getUserInfo().maskinist;
        this.andre_maskinister = res._items.filter( m => (
            m.id != myId &&
            m.navn &&
            m.navn.length
        ));
    });
    // oppdater søkeresultat når det skrives i søkefeltet (lowercase match på del av navn)
    this.sokefelt.valueChanges.subscribe(value => {
      let lowval = value.toLowerCase();
      this.matches = this.andre_maskinister.filter(
        m => m.navn.toLowerCase().indexOf(lowval) > -1
      );
    })
  }

  velgMaskinist(maskinist: any) {
    this.dialogRef.close({alt_maskinist_id: maskinist.id});
  }

}
