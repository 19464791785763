import { Component, OnInit, Inject } from '@angular/core';
import { MDC_DIALOG_DATA, MdcDialogRef } from '@angular-mdc/web';
import { FormGroup, FormControl } from '@angular/forms';
import { ContentService } from 'src/app/content.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-avslutt-forestilling',
  templateUrl: './avslutt-forestilling.component.html',
  styleUrls: ['./avslutt-forestilling.component.scss']
})
export class AvsluttForestillingComponent implements OnInit {
  public forestillinginfo;
  private id;
  public lukesalg;
  public nettsalg;
  public eksterntSalgForm = new FormGroup({
    antall_billetter: new FormControl(null),
    snittpris: new FormControl({ value: null, disabled: true }),
    salg_totalt: new FormControl(null)
  });

  constructor(
    @Inject(MDC_DIALOG_DATA) private data,
    private dialogRef: MdcDialogRef<any>,
    private contentService: ContentService
  ) {}

  ngOnInit() {
    this.id = this.data.id;
    forkJoin(
      this.contentService.fetchContent('/forestilling/' + this.id + '/info'),
      this.contentService.fetchContent('/salg/forestilling/' + this.id + '/solgte_billetter')
    )
      .subscribe(res => {
        this.forestillinginfo = res[0]
        this.lukesalg = res[1].find(a => a.ordretype === 'luke');
        this.nettsalg = res[1].find(a => a.ordretype === 'nett');
    })
    this.eksterntSalgForm.valueChanges.subscribe(form => {
      const antall = parseInt(this.eksterntSalgForm.value.antall_billetter);
      // const pris = parseInt(this.eksterntSalgForm.value.snittpris);
      const salg_totalt = parseInt(this.eksterntSalgForm.value.salg_totalt)
      if (!isNaN(salg_totalt) && !isNaN(antall)) {
        const snittpris = salg_totalt / antall;
        this.eksterntSalgForm.controls.snittpris.setValue(snittpris, { emitEvent: false});
      }
    })
  }

  avsluttForestilling() {
    this.contentService.postContent(
      '/salg/forestilling/' + this.forestillinginfo.id + '/avslutt', this.eksterntSalgForm.value
    ).subscribe(
      res => this.dialogRef.close(true)
    )
  }

  /* sjekker om vi har antall OG sum (over 0) - eller ingen av delene */
  prisOgAntallOK() {
    const v = this.eksterntSalgForm.value;

    if (v.antall_billetter==='') { v.antall_billetter=null; }
    if (v.salg_totalt==='') { v.salg_totalt=null; }

    // Begge null er OK
    if (v.antall_billetter===null && v.salg_totalt===null) {
      return true;
    }
    // Ellers må begge være ikke-null og ikke tom streng
    if (v.antall_billetter!==null && v.salg_totalt!==null) {
      return true;
    }
    return false;
  }

}
