import { Component, Inject } from '@angular/core';
import { MDC_DIALOG_DATA } from '@angular-mdc/web';

@Component({
  selector: 'app-dialog-confirm',
  templateUrl: './dialog-confirm.component.html',
  styleUrls: ['./dialog-confirm.component.scss']
})
export class DialogConfirmComponent {

  constructor(
    @Inject(MDC_DIALOG_DATA) public data,
  ) {}

}
