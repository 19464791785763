<mdc-dialog>
  <mdc-dialog-container>
    <mdc-dialog-surface>

      <mdc-dialog-title *ngIf="!rapportHTML">Z-rapporter</mdc-dialog-title>

      <ng-container *ngIf="!rapportHTML">
        <mdc-dialog-content>
          <mdc-list>
            <mdc-list-item *ngFor="let z of zlist" [disabled]="!z.z_rapp_id" (click)="clickShowReport(z)">
              {{z.tidspunkt}}
            </mdc-list-item>
          </mdc-list>
        </mdc-dialog-content>
        <mdc-dialog-actions>
          <button mdcDialogButton mdcDialogAction="close">Lukk</button>
        </mdc-dialog-actions>
      </ng-container>

      <ng-container *ngIf="rapportHTML">
        <mdc-dialog-content>
          <div [innerHTML]="rapportHTML | safeHtml"></div>
        </mdc-dialog-content>
        <mdc-dialog-actions>
          <button mdcDialogButton default primary [disabled]="!this.rapportData" (click)="clickPrint()">Skriv ut</button>
          <button mdcDialogButton (click)="clickBack()">Tilbake</button>
          <button mdcDialogButton mdcDialogAction="close">Lukk</button>
        </mdc-dialog-actions>
      </ng-container>

    </mdc-dialog-surface>
  </mdc-dialog-container>
</mdc-dialog>