import { Component, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ContentService } from 'src/app/content.service';
import { MDC_DIALOG_DATA, MdcDialogRef } from '@angular-mdc/web';

@Component({
  selector: 'app-legg-til-forestilling',
  templateUrl: './legg-til-forestilling.component.html',
  styleUrls: ['./legg-til-forestilling.component.scss']
})
export class LeggTilForestillingComponent {
  public ruteplan:Array<any>;
  public filmer;
  public valgtFilm;
  public searchForm= new FormGroup({
    searchField: new FormControl()
  });
  public form = new FormGroup({
    tidspunkt: new FormControl(null, Validators.required),
    ruteplan_id: new FormControl(null, Validators.required),
    film_id: new FormControl(null, Validators.required)
  })

  constructor(
    private contentService: ContentService,
    @Inject(MDC_DIALOG_DATA) private data,
    private dialogRef: MdcDialogRef<LeggTilForestillingComponent>
  ) {
    this.contentService.fetchContent(`/maskinist/${data.maskinist_id}/dagsplan`)
      .subscribe(dagsplan => {
        this.ruteplan = dagsplan.map( dag => ({id: dag.id, sted: dag.spillested.stedsnavn}) )
        if (this.ruteplan.length) {
          this.form.controls.ruteplan_id.setValue(this.ruteplan[0].id)
        }
      })
  }

  sok() {
    function createSearchFilter(field, query) {
      return `{"${field}":"ilike(\\"%${query}%\\")"}`;
    }
    const searchTerm = this.searchForm.value.searchField;
    const currentFilter = createSearchFilter('norsktittel', searchTerm);
    this.contentService.fetchContent('/film?where=' + encodeURI(currentFilter))
      .subscribe(filmer => {
          this.filmer = filmer._items;
      });
  }

  velgFilm(film) {
    this.valgtFilm = film;
    this.form.controls.film_id.setValue(film.id);
  }

  fjernFilm() {
    this.valgtFilm = null;
    this.form.controls.film_id.setValue(null);
  }

  leggTilForestilling() {
    this.contentService.postContent('/forestilling', this.form.value)
      .subscribe((res: any) => {
        this.dialogRef.close(true);
      });
  }
}
