<div class="betaling__spesial betalingsalternativer">
  <div mdcMenuSurfaceAnchor #betalingAnchor>
    <button [disabled]="disabled" mdc-button outlined [class.button-no-bg]="! disabled" (click)="betalingMenu.open = !betalingMenu.open">
      <span mdcButtonLabel>Flere</span>
      <mdc-icon>expand_more</mdc-icon>
    </button>
    <mdc-menu class="betaling__meny" #betalingMenu [anchorElement]="betalingAnchor" anchorCorner="bottomStart">
      <mdc-list>
        <mdc-list-item [disabled]="disabled" (click)="betalGavekort()">Gavekort</mdc-list-item>
        <mdc-list-item [disabled]="disabled" (click)="betalTilgodebillett()">Tilgodebillett</mdc-list-item>
        <mdc-list-item [disabled]="disabled || har_ikkekorrigerte_transaksjoner()" (click)="betalFaktura()">Faktura</mdc-list-item>
      </mdc-list>
    </mdc-menu>
  </div>
</div>