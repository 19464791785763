<mdc-dialog>
    <mdc-dialog-container>
      <mdc-dialog-surface>
        <mdc-dialog-title>Billettlogg</mdc-dialog-title>
        <mdc-dialog-content>
          <mdc-list interactive="false">
            <mdc-list-item *ngFor="let ordre of ordrer">
              <span class="logg-item__data text__medium-emphasis">{{ ordre.timestamp | date: 'd.M' }}</span>
              <span class="logg-item__data">{{ ordre.timestamp | date: 'HH:mm:ss' }}</span>
              <span class="logg-item__data">{{ ordre.billetter }} billetter</span>
              <span class="logg-item__sum">{{ ordre.sum }} kr</span>
              <button (click)="print(ordre)" mdc-button class="button-no-bg logg-item__print"><mdc-icon>print</mdc-icon></button>
            </mdc-list-item>
          </mdc-list>
        </mdc-dialog-content>
        <mdc-dialog-actions>
          <button mdc-button raised mdcDialogAction="close">Lukk</button>
        </mdc-dialog-actions>
      </mdc-dialog-surface>
    </mdc-dialog-container>
  </mdc-dialog>