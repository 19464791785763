
<mdc-dialog>
  <mdc-dialog-container>
    <mdc-dialog-surface>
      <mdc-dialog-title>Betal med Vipps</mdc-dialog-title>
      <mdc-dialog-content>
        <ng-container *ngIf="!errorMessage">
          <p>Ta imot betaling ved å oppgi kundens telefonnummer eller la dem skanne QR-koden.</p>
          <div class="vipps-betaling">
            <form [formGroup]="form" *ngIf="showNumberForm">
              <div class="vipps-betaling__rad">
                <mdc-form-field fluid>
                  <mdc-text-field outlined dense label="Telefonnummer" formControlName="telefonnr"></mdc-text-field>
                </mdc-form-field>
                <button mdc-button primary raised (click)="initiateVippsWithPhonenumber()">OK</button>
              </div>
            </form>
            <div class="vipps-betaling__rad" *ngIf="!showNumberForm && !loadingQrCode && !userStartedPayment">
              <button mdc-button outlined class="button-no-bg" (click)="clickShowNumberForm()">Oppgi telefonnummer</button>
            </div>
          </div>
          <div class="vipps-betaling--qr">
            <button *ngIf="!loadingQrCode && !qrUrl && !userStartedPayment" mdc-button primary raised (click)="clickShowQrCode()">Vis QR-kode</button>
            <img class="vipps-betaling__qr-kode" *ngIf="qrUrl && !userStartedPayment" src="{{ qrUrl }}" />
          </div>
          <span *ngIf="userStartedPayment && form.value.telefonnr">Registrert telefonnummer {{ form.value.telefonnr }}. Kunden gjennomfører betaling i Vipps-appen.</span>
          <span *ngIf="userStartedPayment && !form.value.telefonnr">Kunden gjennomfører betaling i Vipps-appen</span>
          <app-spinner [inline]="true" *ngIf="loadingQrCode || userStartedPayment"></app-spinner>
        </ng-container>
        <ng-container *ngIf="errorMessage">
          <div class="vipps-betaling__rad vipps-betaling__error">
            <p>{{errorMessage}}</p>
          </div>
        </ng-container>
      </mdc-dialog-content>
      <mdc-dialog-actions>
          <button mdc-button class="button-no-bg" mdcDialogAction="close">Avbryt</button>
        </mdc-dialog-actions>
    </mdc-dialog-surface>
  </mdc-dialog-container>
</mdc-dialog>
