import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {AuthService} from '../auth.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-reset-passord',
  templateUrl: './reset-passord.component.html',
  styleUrls: ['./reset-passord.component.scss']
})
export class ResetPassordComponent implements OnInit {
  reset;

  constructor(
    public auth: AuthService,
    private router: Router
  ) { }

  ngOnInit() {
    this.reset = new FormGroup({
      password1: new FormControl('', Validators.required),
      password2: new FormControl('', Validators.required)
    });
    this.reset.setValidators(this.checkPasswords());
  }

  checkPasswords() {
    return (group: FormGroup): ValidationErrors => {
      const pwd1 = group.controls['password1'];
      const pwd2 = group.controls['password2'];
      if (pwd1.value !== pwd2.value) {
        pwd2.setErrors({notEquivalent: true});
      } else {
        pwd2.setErrors(null);
      }
      return;
    };
  }

  resetPwd() {
    const token = this.router.url.split('/')[2];
    const password = this.reset.value.password1;
    if (password.length < 6) {
      alert('Passord må være minst 6 tegn');
    } else {
      this.auth.setPassword(token, password)
        .subscribe(res => {
          this.router.navigate(['/login']);
        });
    }
  }
}
