<mdc-dialog>
  <mdc-dialog-container>
    <mdc-dialog-surface>
      <mdc-dialog-title>Faktura</mdc-dialog-title>
      <mdc-dialog-content>
        <div class="faktura__form">
          <h2 mdcHeadline5>Skriv inn fakturainformasjon</h2>
          <mdc-text-field outlined [formControl]="formen.controls.fakturamottaker" label="Mottaker"></mdc-text-field>
          <mdc-text-field outlined [formControl]="formen.controls.fakturareferanse" label="Kontaktperson"></mdc-text-field>
          <mdc-text-field outlined [formControl]="formen.controls.fakturaadresse" label="Adresse"></mdc-text-field>
          <mdc-form-field>
            <mdc-checkbox [formControl]="formen.controls.skip_billett_print"></mdc-checkbox>
            <label>Ikke skriv ut billetter</label>
          </mdc-form-field>
        </div>
      </mdc-dialog-content>
      <mdc-dialog-actions>
        <button mdc-button class="button-no-bg" mdcDialogAction="close">Avbryt</button>
        <button mdc-button raised [disabled]="!formen.valid" (click)="submit()">OK</button>
      </mdc-dialog-actions>
    </mdc-dialog-surface>
  </mdc-dialog-container>
</mdc-dialog>
