<div mdcMenuSurfaceAnchor #moreAnchor *ngIf="sett_selv_enabled || sett_annen_enabled || spilledagHarPublisertFilm(spilledag) || (adminuser && kanSendeEpost(spilledag))">
    <button mdc-button class="more-menu__button button-no-bg" (click)="moreMenu.open = !moreMenu.open">
        <mdc-icon>more_vert</mdc-icon>
    </button>

    <mdc-menu #moreMenu [anchorElement]="moreAnchor" anchorCorner="topEnd" class="valg__meny">
        <mdc-list>
            <mdc-list-item *ngIf="sett_selv_enabled" (click)="clickVelgMegSelv()" class="nowrap">Sett meg som alternativ maskinist</mdc-list-item>
            <mdc-list-item *ngIf="sett_annen_enabled" (click)="clickVelgAnnen()" class="nowrap">Velg en alternativ maskinist</mdc-list-item>
            <mdc-list-item *ngIf="spilledagHarPublisertFilm(spilledag)" (click)="clickDownloadPoster(spilledag.id)" class="nowrap">Last ned plakat</mdc-list-item>
            <mdc-list-item *ngIf="kanSendeEpost(spilledag)" (click)="clickSendReminder(spilledag)" class="nowrap">Send påminnelse per epost</mdc-list-item>
            <ng-container *ngFor="let forestilling of spilledag.forestillinger">
                <mdc-list-item (click)="clickAapneNettsalg(forestilling)" class="nowrap">Nettsalg: {{ forestilling.film.norsktittel }}</mdc-list-item>
            </ng-container>
        </mdc-list>
    </mdc-menu>
</div>
