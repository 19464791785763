import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MdcDialog } from '@angular-mdc/web';
import { BillettloggComponent } from '../dialogs/billettlogg/billettlogg.component';
import { RefunderBilletterComponent } from '../dialogs/refunder-billetter/refunder-billetter.component';
import { AvlysForestillingComponent } from '../dialogs/avlys-forestilling/avlys-forestilling.component';
import { AvsluttForestillingComponent } from 'src/app/common-components/dialogs/avslutt-forestilling/avslutt-forestilling.component';
import { Router } from '@angular/router';
import { XzrapportComponent } from '../../common-components/dialogs/xzrapport/xzrapport.component';
import { EndreFilmComponent } from '../dialogs/endre-film/endre-film.component';
import { SelgGavekortComponent } from '../dialogs/selg-gavekort/selg-gavekort.component';
import { SjekkGavekortComponent } from '../dialogs/sjekk-gavekort/sjekk-gavekort.component';
import { BillettscanLenkeComponent } from '../dialogs/billettscan-lenke/billettscan-lenke.component';
import { DialogOrdreoppslagComponent } from 'src/app/admin/dialogs/dialog-ordreoppslag/dialog-ordreoppslag.component';

@Component({
  selector: 'app-valg',
  templateUrl: './valg.component.html',
  styleUrls: ['./valg.component.scss']
})
export class ValgComponent implements OnInit {
  @Input() public disabled: Boolean;
  @Input() public forestillinginfo;
  @Output() public forestillingChanged = new EventEmitter<boolean>();
  @Output() public refundSomething = new EventEmitter<any>();
  @Output() public solgtGavekort = new EventEmitter<any>();
  constructor(
    public dialog: MdcDialog,
    private router: Router
  ) { }

  ngOnInit() {
  }

  openLogg() {
    const ref = this.dialog.open(BillettloggComponent, {
      data: {
        forestillingId: this.forestillinginfo.id
      }
    });
  }

  showBillettscanLink() {
    this.dialog.open(BillettscanLenkeComponent, {
      data: this.forestillinginfo
    })
  }

  openOrdreoppslag() {
    this.dialog.open(DialogOrdreoppslagComponent, {
      data: {
        forestillingId: this.forestillinginfo.id
      }
    })
  }

  selgGavekort() {
    this.dialog.open(SelgGavekortComponent).afterClosed()
      .subscribe((res: any) => {
        if (res !== 'close') {
          if (res.gavekortListe) {
            this.solgtGavekort.emit(res.gavekortListe);
          }
        }
      });
  }

  sjekkGavekort() {
    this.dialog.open(SjekkGavekortComponent);
  }

  visXrapport() {
    this.dialog.open(XzrapportComponent, { data: { type: 'x' } });
  }

  refunderBilletter() {
    this.dialog.open(RefunderBilletterComponent).afterClosed()
      .subscribe((res: any) => {
        if (res !== 'close') {
          this.refundSomething.emit(res);
        }
      });
  }

  avlysForestilling() {
    const ref = this.dialog.open(AvlysForestillingComponent, {
      data: {
        forestillinginfo: this.forestillinginfo
      }
    });
    ref.afterClosed().subscribe(res => {
      if (res && res !== 'close') {
        this.forestillingChanged.emit(true);
      }
    })
  }

  avsluttForestilling() {
    const ref = this.dialog.open(AvsluttForestillingComponent, {
      data: {
        id: this.forestillinginfo.id
      }
    });
    ref.afterClosed().subscribe(res => {
      if (res !== 'close') {
        this.router.navigate(['/salg', 'kasse'])
      }
    })
  }

  endreFilm() {
    const ref = this.dialog.open(EndreFilmComponent, {
      data: {
        forestillinginfo: this.forestillinginfo
      }
    });
    ref.afterClosed().subscribe(res => {
      if (res !== 'close') {
        this.router.navigate(['/salg', 'spillested', this.forestillinginfo.spillested_id])
      }
    })
  }

}
