import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import QrScanner from 'qr-scanner';
import { ContentService } from 'src/app/content.service';
import { MdcDialogRef, MdcDialogAction } from '@angular-mdc/web';
import { fmtPaymentType } from '../../print-stuff'
import { map } from 'rxjs/operators';
QrScanner.WORKER_PATH = '/assets/qr-scanner-worker.min.js';

@Component({
  selector: 'app-gavekort-betaling',
  templateUrl: './gavekort-betaling.component.html',
  styleUrls: ['./gavekort-betaling.component.scss']
})
export class GavekortBetalingComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('scanVideoElm', { static: false }) private scanVideoElm;
  private qrScanner;
  public resultat;

  public gavekortnummer = new FormControl(
    '', {
      updateOn: 'change',/* FIXME - mdc-bug? - oppdateres på blur */
      validators: [Validators.required, Validators.minLength(8), Validators.maxLength(32)],
      asyncValidators: this.sjekkGavekortkode.bind(this)
    }
  );
  constructor(
    private contentService: ContentService,
    private dialogRef: MdcDialogRef<GavekortBetalingComponent>
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.qrScanner.stop();
  }

  ngAfterViewInit() {
    this.qrScanner = new QrScanner(this.scanVideoElm.nativeElement, this.gavekortScanned.bind(this));
    this.qrScanner.start();
  }

  matchUUIDs(full: string, partial: string): boolean {
    return (full.indexOf(partial) === 0);
  }

  sjekkGavekortkode(control) {
    return this.contentService.fetchContent(`/salg/gavekortsjekk/${control.value}`)
      .pipe(
        map(res => {
          this.resultat = res;
          if (res.res === 'OK') {
            return null;
          } else {
            return { gavekort_err: res };
          }
        })
      );
  }

  getFeilmelding() {
    const err = this.gavekortnummer.errors.gavekort_err;
    if (err.res === 'NOTFOUND') {
      return 'Ugyldig gavekortkode'
    } else {
      return err.msg
    }
  }

  gavekortScanned(uuid) {
    if (uuid) {
      this.qrScanner.stop();
      this.gavekortnummer.setValue(uuid);
    }
  }

  betalMedGavekort() {
    this.dialogRef.close({
      gavekort_kode: this.resultat.gavekort_kode,
      gavekort_verdi: this.resultat.gavekort_verdi
    });
  }
}
