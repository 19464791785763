import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth.service';

@Component({
  selector: 'app-landingsside',
  templateUrl: './landingsside.component.html',
  styleUrls: ['./landingsside.component.scss']
})
export class LandingssideComponent implements OnInit {

  public isAdmin = false;
  public isMaskinist = false;
  constructor(private auth: AuthService) { }


  ngOnInit() {
    
    if (this.auth.getUserInfo().adminuser) {
      this.isAdmin = true;
    } else {
      this.isMaskinist = true;
    }
  }
}
