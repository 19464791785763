import { publishLast, refCount, map } from 'rxjs/operators';
import { ContentService } from './content.service';
import { Injectable } from '@angular/core';
import { Resolve } from '../../node_modules/@angular/router';

@Injectable({
  providedIn: 'root'
})
export class GrunnlagsdataResolverService implements Resolve<any> {
  grunnlagsdata;
  grunnlagsdata$;
  constructor(private contentService: ContentService) { }
  resolve() {
    return this.fetchGrunndata();
  }

  private fetchGrunndata() {
    return this.grunnlagsdata$ = this.contentService.fetchContent('/grunndata')
      .pipe(
        map(res => this.grunnlagsdata = res),
        publishLast(),
        refCount()
      );
  }

  public getGrunndata() {
    return this.grunnlagsdata;
  }

  public setGrunndata(grunndata) {
    this.grunnlagsdata = grunndata;
  }

  public updateField(field, value) {
    const item = this.grunnlagsdata._items.find(i => i.key === field);
    const id = item.id;
    if (item.value !== value) {
      return this.contentService.updateContent('/grunndata/' + id, { value: value}, item._etag)
    }
  }
}
