<mdc-dialog>
    <mdc-dialog-container>
      <mdc-dialog-surface>
        <mdc-dialog-title>Refundert til kort</mdc-dialog-title>
        <mdc-dialog-content>
            <div>
                <p>{{ refundering.datotid | date:'d.M.yyyy HH:mm' }}</p>
                <p>Kasse #{{ refundering.kasse_id }}</p>
            </div>
            <div *ngIf="billetter.length">
                <p>Refunderte billetter: {{ refundering.ant_refundert }}</p>
                <p>Ikke-refunderte billetter: {{ refundering.ant_ikke_refundert }}</p>
                <mdc-list interactive="false" class="ordre__billettliste content-list">
                    <mdc-list-item *ngFor="let billett of billetter">
                      <p class="billettliste__uuid">Billett {{billett.uuid}}</p>
                      <p *ngIf="billett.plassref && !billett.slettet">{{billett.plasstxt}}</p>
                      <p *ngIf="!billett.slettet && !billett.refundert">Gyldig</p> 
                      <p *ngIf="billett.refundert">Refundert</p>
                      <p *ngIf="billett.slettet">Ikke gyldig</p>
                    </mdc-list-item>
                  </mdc-list>
            </div>
            <div *ngIf="gavekort.length">
                <p>Refunderte gavekort: {{ refundering.ant_gavekort_refundert }}</p>
                <p>Ikke-refunderte gavekort: {{ refundering.ant_gavekort_ikke_refundert }}</p>
                <div class="originalordre">
                    <h4 mdcHeadline6>Gavekort i ordre {{ refundering.original_ordre?.id }}</h4>
                    <div class="gavekort-liste">
                        <div class="gavekort" *ngFor="let gk of gavekort">
                            <h5>Uuid: {{ gk.uuid }}</h5>
                            <p>Originalverdi: kr {{ gk.org_verdi }}</p>
                            <p>Gjenstående verdi: kr {{ gk.verdi }}</p>
                            <div class="gavekort__refundert" *ngIf="gk.refundert">
                                <p>Refundert</p>
                                <p>Refundert beløp: kr {{ gk.refundert_belop }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mdc-dialog-content>
        <mdc-dialog-actions>
          <button mdc-button raised mdcDialogAction="close">Lukk</button>
        </mdc-dialog-actions>
      </mdc-dialog-surface>
    </mdc-dialog-container>
  </mdc-dialog>