import { Component, Inject } from '@angular/core';
import { MDC_DIALOG_DATA } from '@angular-mdc/web';
import { ContentService } from 'src/app/content.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-velg-sete',
  templateUrl: './velg-sete.component.html',
  styleUrls: ['./velg-sete.component.scss']
})

export class VelgSeteComponent {

  public multi_or_single = new FormControl('alle');

  public info: Forestillinginfo;
  public ordre: Ordre;
  public modus = 'normal';
  public pending = false;// venter på bekreftelse av plassendring

  public currentReservationPrefix: string;// valgt(e) sete(r)
  public currentReservationSeats: string;// Rad X, sete Y...

  public seatsInOrdre: Array<SalSeat>;
  public seatsOff: Array<SalSeat> = [];
  public seatsChanged: Map<SalSeat, SalSeat>;// tmp før det lagres (fra er key)

  private seteById: Map<number, SalSeat>;
  private rowFromSeat: Map<SalSeat, Array<SalSeat>>;
  private rowLabels: Array<string>;
  private grid: Array<Array<SalSeat|string>>;

  constructor(
    @Inject(MDC_DIALOG_DATA) private data,
    private contentService: ContentService
  ) {
    this.info = this.data.info;
    this.ordre = this.data.ordre;
    this.modus = this.data.modus || false;
    // oppslagsverk for seter basert på id og rad basert på sete
    this.seteById = new Map();
    this.rowFromSeat = new Map();
    this.rowLabels = [];
    this.grid = [];
    for (let row of this.info.sal.saldata.rows) {
      this.rowLabels.push(row.l);
      const gridrow = [];
      for (let sete of row.seats) {
        if (sete) {
          gridrow.push(sete);
          this.seteById.set(sete.i, sete);
          this.rowFromSeat.set(sete, gridrow);
        } else {
          gridrow.push(null);
        }
      }
      this.grid.push(gridrow);
    }
    // for oppslag av setene i ordren
    if (this.ordre) {
      this.seatsInOrdre = this.seatsFromOrdre();
      this.updateReservationText();
      if (this.seatsInOrdre.length !== this.ordre.billetter.length) {
        console.warn('Her er det noe galt - fant ikke plassene fra ordren', this.seatsInOrdre);
      }
    } else {// uten ordre, ingen markerte seter
      this.seatsInOrdre = [];
    }
  }

  getBillettBySeatId(id: number): Billett {
    return this.ordre.billetter.find(billett => billett.plassref === id);
  }

  private updateSeats(newOrder:Ordre) {
    this.info.seats_occupied = newOrder.seats_occupied;
    this.info.seats_blocked = newOrder.seats_blocked;
    this.ordre.billetter = newOrder.billetter;
    this.seatsInOrdre = this.seatsFromOrdre();
    this.updateReservationText();
  }

  // /salg/forestilling/<int:forestilling_id>/ordre/<int:ordre_id>/plassendring
  changeSeats(newSeats: Array<any>) {
    this.pending = true;
    this.contentService.postContent(
      `/salg/forestilling/${this.info.id}/ordre/${this.ordre.id}/plassendring`, newSeats
    ).subscribe(res => {
      this.pending = false;
      if (res.res === 'OK') {// backend-ønske: res bør bli noe annet enn OK når det er warnings
        this.updateSeats(res.ordre);
      } else {
        console.warn('hva behager?', res);
      }
    });
  }

  updateReservationText() {
    if (this.ordre.billetter.length < 2) {
      this.currentReservationPrefix = 'Valgt sete: ';
      this.currentReservationSeats = this.ordre.billetter[0].plasstxt;
      return;
    }
    let rows = {};// rows[rad-label] = [sete-labels ...]
    for (let billett of this.ordre.billetter) {
      let sete = this.seteById.get(billett.plassref);
      let row = this.rowLabels[
        this.grid.indexOf(
          this.rowFromSeat.get(sete)
        )
      ];// eh...
      if (!rows[row]) {
        rows[row] = [];
      }
      rows[row].push(sete);
    }
    let rowLabels = Object.keys(rows).sort();// FIXME: trenger spesial-sort for tekst+nummer?
    let out = [];
    for (let row of rowLabels) {
      let seats = rows[row].sort( (a, b) => a.i - b.i ).map( sete => sete.l );
      out.push(row + ', sete ' + this.i18nJoin(seats))
    }
    this.currentReservationPrefix = 'Valgte seter: ';
    this.currentReservationSeats = out.join(' - ') + '.'
  }

  private i18nJoin(list: Array<string>): string {
    if (list.length < 3) {
      return list.join(' og ');
    } else {
      const len = list.length;
      return list.slice(0, len-1).join(', ') + ' og ' + list[len-1];
    }
  }

  seatsFromOrdre() {
    // finn alle setene i ordren og sorter på id
    return this.ordre.billetter
      .filter( billett => this.seteById.has(billett.plassref) )
      .map( billett => this.seteById.get(billett.plassref) )
      .sort( (seteA, seteB) => seteA.i - seteB.i )
      .filter( sete => !this.seatsOff.includes(sete) )
    ;
  }

  seatInOrdre(seat: SalSeat): boolean {
    return this.seatsInOrdre.includes(seat);
  }
  seatToggledOff(seat: SalSeat): boolean {
    return this.seatsOff.includes(seat);
  }

  seatOccupied(seat: SalSeat): boolean {
    return !this.seatInOrdre(seat) && this.info.seats_occupied.includes(seat.i);
  }

  seatBlocked(seat: SalSeat): boolean {
    return this.info.seats_blocked.includes(seat.i);
  }

  seatFree(seat: SalSeat): boolean {
    return !this.seatInOrdre(seat) && !this.info.seats_occupied.includes(seat.i);
  }

  clickSeat(seat: SalSeat) {
    if (!this.ordre) return;// hack for visning uten ordre
    if (this.ordre.billetter.length === 1) {
      // klikk på annet sete?
      let oldSeat = this.seatsInOrdre[0];
      if (seat !== oldSeat) {
        this.changeSeats([{
          billett_id: parseInt(this.getBillettBySeatId(oldSeat.i).id),
          plass_ref: seat.i
        }]);
      }
    } else {
      if (this.multi_or_single.value==='alle') {// forsøk å flytte alle samlet
        if (seat !== this.seatsInOrdre[0]) {
          // flytt alle med start nærmest mulig 'seat'
          this.getFreeSeatsFrom(seat);
        } else {
          // ignorer
        }
      } else if (this.seatInOrdre(seat)) {// toggle av
        this.seatsOff.push(seat);
        this.seatsInOrdre = this.seatsFromOrdre();
      } else if (this.seatsOff.length) {// noe er toggla av, som betyr at det kan toggles PÅ...
        const i = this.seatsOff.indexOf(seat);
        if (i > -1) {// toggle på
          this.seatsOff.splice(i, 1);
          this.seatsInOrdre = this.seatsFromOrdre();
        } else {// flytt
          let oldSeat = this.seatsOff.shift();
          this.changeSeats([{
            billett_id: parseInt(this.getBillettBySeatId(oldSeat.i).id),
            plass_ref: seat.i
          }]);
        }
      }
    }
  }

  // TODO: ta hensyn til smittevernplasser
  getFreeSeatsFrom(seat) {
    const wanted = this.ordre.billetter.length;
    const row = this.rowFromSeat.get(seat);
    let i = row.indexOf(seat);
    const seats = row.slice(i, i + wanted).filter((s:SalSeat|string) => s !== 'noseat');
    const j = wanted - seats.length;// antall manglende seter mot høyre
    if (j > 0 && i >= j) { // trenger fler - sjekk venstre side
      seats.unshift(...row.slice(i-j, i).filter((s:SalSeat|string) => s !== 'noseat'));
    }
    if (seats.length === wanted) {
      this.bookSeats(seats);
      return true;
    } else {
      console.log('not possible?')
      return false;
    }
  }

  // finner ut hvilke billetter som får ny plass og forsøker å reservere
  bookSeats(seats) {
    let keepSeats = this.seatsInOrdre.filter( seat => seats.includes(seat) );
    if (keepSeats.length === seats.length) return;// ingen endring
    let newSeats = seats.filter( seat => !this.seatsInOrdre.includes(seat) );
    let swapSeats = this.seatsInOrdre.filter( seat => !keepSeats.includes(seat) );
    const changes = swapSeats.map( seat => ({
      billett_id: parseInt(this.getBillettBySeatId(seat.i).id),
      plass_ref: newSeats.pop().i
    }) );
    this.changeSeats(changes);
  }

}

