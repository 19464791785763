<div class="forestilling__header">
  <div class="header__top">
    <a href="javascript:void(0)" *ngIf="!(currentOrdre && currentOrdre.trans.length > 0)" (click)="goToKasse()">Tilbake til kasse</a>
    <p *ngIf="currentOrdre && currentOrdre.trans.length > 0">Tilbake til kasse</p>
    <app-valg
      [disabled]="currentRefund || currentOrdre"
      [forestillinginfo]="forestillinginfo"
      (forestillingChanged)="getInfo()"
      (refundSomething)="onGotRefundRequest($event)"
      (solgtGavekort)="onSolgtGavekort($event)"
    ></app-valg>
  </div>
  <div class="header__title">
    <h1 mdcHeadline4>{{ forestillinginfo?.film_tittel || '&nbsp;' }} <span class="film__versjon" *ngIf="forestillinginfo?.film_version_subtittel">{{ forestillinginfo?.film_version_subtittel }}</span></h1>
  </div>
  <div class="header__info">
    <div>
      <p *ngIf="!forestillinginfo?.warning_other_date">{{ forestillinginfo?.dato | date: 'd.M.yyyy' }} {{ forestillinginfo?.tidspunkt || '&nbsp;' }}</p>
      <p *ngIf="forestillinginfo?.warning_other_date"><b> NB {{ forestillinginfo?.dato | date: 'd.M.yyyy' }}</b> {{ forestillinginfo?.tidspunkt || '&nbsp;' }}</p>
      <p>{{ forestillinginfo?.spillested_tittel  || '&nbsp;' }}</p>
      <p class="info_age-price">
        <span>Aldersgrense: {{ getAgeRating(forestillinginfo?.film_ageRating) }}</span>
        <span>Pris: {{ forestillinginfo?.bill_pris }}</span>
      </p>
    </div>
    <app-ledige-billetter-status
      [forestillinginfo]="forestillinginfo"></app-ledige-billetter-status>
    <button *ngIf="forestillinginfo?.sal" class="btn__salkart" (click)="openSalkart()">
      <app-salkart
        [forestillinginfo]="forestillinginfo"
        [mini]="true"
      ></app-salkart>
    </button>

  </div>
  </div>
<div class="forestilling__main" *ngIf="! henterOrdreFraBackend">

  <div class="forestilling__produkter">
    <app-velg-antall-billetter
      [disabled]="currentRefund || transactionPending || !!(forestillinginfo?.er_avlyst || currentOrdre?.gavekort?.length || ikkeReverserbareTransaksjoner?.length)"
      (selected)="onOrdreRequest($event)"
    ></app-velg-antall-billetter>

    <div class="forestilling__andreprodukter">
      <app-andre-produkter
        [forestilling_id]="forestillingId"
        [disabled]="currentRefund || transactionPending || forestillinginfo?.er_avlyst || !!(currentOrdre?.gavekort?.length)"
        (addProduct)="onAddProduct($event)"
        (betalt)="onBetalt($event)"
      ></app-andre-produkter>
    </div>

    <div *ngIf="forestillinginfo?.sal" class="forestilling__salkart">
      <button #salkartbutton mdc-button primary raised
        [disabled]="!currentOrdre || forestillinginfo?.er_avlyst"
        (click)="openSalkart()"
        class="salkart__button"
      >Vis salkart</button>
    </div>
  </div>

  <div class="forestilling__ordre">

    <app-betaling
      [disabled]="transactionPending || !currentOrdre || forestillinginfo?.er_avlyst || gjenstaaende() <= 0"
      [gjenstaaende]="gjenstaaende()"
      [ordre]="currentOrdre"
      [forestillingId]="forestillingId"
      (betalt)="onBetalt($event)"
    ></app-betaling>

    <app-ordre-status *ngIf="currentOrdre && !currentRefund"
      [ordre]="currentOrdre"
      [disabled]="transactionPending"
      (undo_transaksjon)='onTransactionCancel($event)'
      (undo_billett)='onBillettCancel($event)'
      (avbrutt)="onOrdreCancel()"
      (avsluttet)="onOrdreAvsluttet($event)"
    ></app-ordre-status>

    <app-refund-status *ngIf="currentRefund"
      [data]="currentRefund"
      (avbrutt)="onRefundCancel()"
      (avsluttet)="doRefund()"
    ></app-refund-status>

  <div class="forestilling--avlyst" *ngIf="forestillinginfo?.er_avlyst">
    <h3 mdcHeadline3>AVLYST</h3>
    <button mdc-button unelevated (click)="refunderBillett()">Refunder billett</button>
  </div>
  
  </div>
</div>
