import { Component, Input, Output, OnChanges, SimpleChanges } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { fmtPaymentType } from '../print-stuff';

@Component({
  selector: 'app-refund-status',
  templateUrl: './refund-status.component.html',
  styleUrls: ['./refund-status.component.scss']
})

export class RefundStatusComponent implements OnChanges {

  @Output() public avsluttet = new EventEmitter<boolean>();
  @Output() public avbrutt = new EventEmitter<boolean>();

  @Input() public data;

  public fmtPaymentType = fmtPaymentType;

  public cash_sum = 0;
  public num_tickets = 0;
  public refund_as = '?';

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data) {
      // defaultverdier
      this.cash_sum = 0;
      this.num_tickets = 0;
      if (changes.data.currentValue && changes.data.currentValue.ordre) {
        // 'ikke', hvis ordren f.eks. er refundert av noen andre i mellomtiden
        this.refund_as = 'ikke';
        this.calcStuff(changes.data.currentValue);
      } else {
        this.refund_as = '...';// pending
      }
    }
  }

  private calcStuff(data) {
    const urefunderte = data.ordre.billetter.filter(billett => !billett.refundert);
    this.num_tickets = urefunderte.length;
    if (this.num_tickets > 0) {
      // enkeltbillett eller ordre?
      if (data.refund_what === 'ticket') {
        const ticket = urefunderte.find(
          ( billett ) => billett.uuid === data.id
        );
        this.cash_sum = (ticket ? ticket.belop : 0);
        this.num_tickets = (ticket ? 1 : 0);
      } else {// totalsum urefunderte
        this.cash_sum = urefunderte.reduce(
          ( sum, billett ) => sum + billett.belop,
          0
        );
      }
      if (this.cash_sum > 0) {
        switch(data.refund_type) {
          case 'cash':
            this.refund_as = 'kontant';
            break;
          case 'kort':
            this.refund_as = 'kort';
            break;
          default:
            this.refund_as = 'som tilgodebillett' + (this.num_tickets === 1 ? '' : 'er');
        }
      }
    } else {
      // ingen urefunderte
    }
  }

  doRefund() {
    this.avsluttet.emit(true);
  }

  cancelRefund() {
    this.avbrutt.emit();
  }

}