import { Component, OnInit, Inject } from '@angular/core';
import { ContentService } from 'src/app/content.service';
import { printOrdre } from '../../print-stuff';
import { MDC_DIALOG_DATA } from '@angular-mdc/web';
import { forkJoin } from 'rxjs';
@Component({
  selector: 'app-billettlogg',
  templateUrl: './billettlogg.component.html',
  styleUrls: ['./billettlogg.component.scss']
})
export class BillettloggComponent implements OnInit {
  public ordrer = [];
  constructor(
    private contentService: ContentService,
    @Inject(MDC_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.contentService.fetchContent('/salg/forestilling/' + this.data.forestillingId + '/ordre')
      .subscribe(
        res => {
          this.ordrer = res.ordre
        }
      )
  }

  public print(ordre) {
    forkJoin(
      this.contentService.fetchContent('/salg/forestilling/' + this.data.forestillingId + '/info'),
      this.contentService.fetchContentWithQueryParams(
        '/salg/forestilling/' + this.data.forestillingId + '/ordre/' + ordre.id,
        { print_copy: true }
      )
    )
      .subscribe(
        res => {
          if (res[1] && res[1].kopikvittering_mulig) {
            const params = {
              forestillinginfo: res[0],
              ordre: res[1].ordre
            };
            printOrdre(params, true);
          } else {
            alert('Det er allerede skrevet ut en kopi av denne kvitteringen. Det er ikke lov å skrive ut flere.');
          }
        });

  }

}
