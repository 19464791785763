<div mdcMenuSurfaceAnchor #moreAnchor>
    <button mdc-button [disabled]="disabled" class="more-menu__button button-no-bg" (click)="moreMenu.open = !moreMenu.open">
        <mdc-icon>more_vert</mdc-icon>
    </button>

    <mdc-menu #moreMenu [anchorElement]="moreAnchor" anchorCorner="bottomStart" defaultFocusState="firstItem" class="valg__meny">
        <mdc-list>
            <mdc-list-item (click)="selgGavekort()">Selg gavekort</mdc-list-item>
            <mdc-list-item (click)="sjekkGavekort()">Sjekk gavekort</mdc-list-item>
            <mdc-list-item (click)="refunderBilletter()">Refundere billetter</mdc-list-item>
            <mdc-list-item (click)="openOrdreoppslag()">Ordreoppslag</mdc-list-item>
            <mdc-list-item (click)="openLogg()">Kasselogg</mdc-list-item>
            <mdc-list-item (click)="showBillettscanLink()">Billettscanner</mdc-list-item>
            <mdc-list-divider></mdc-list-divider>
            <mdc-list-item (click)="visXrapport()">X-rapport</mdc-list-item>
            <mdc-list-item (click)="avlysForestilling()">Avlys<mdc-icon mdcListItemMeta>warning</mdc-icon></mdc-list-item>
            <mdc-list-item (click)="endreFilm()">Endre film</mdc-list-item>
            <mdc-list-divider></mdc-list-divider>
            <mdc-list-item (click)="avsluttForestilling()">Avslutt forestilling</mdc-list-item>
        </mdc-list>
    </mdc-menu>
</div>
