import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MDC_DIALOG_DATA } from '@angular-mdc/web';
import { ContentService } from 'src/app/content.service';
import { printBesoksrapport } from '../../../salg/print-stuff';


const sortByTidspunkt_ASC = function(a, b) {
  return a.tidspunkt >= b.tidspunkt ? 1 : -1
}

@Component({
  selector: 'app-besoksrapport',
  templateUrl: './besoksrapport.component.html',
  styleUrls: ['./besoksrapport.component.scss']
})
export class BesoksrapportComponent implements OnInit {

  public today = new Date().toISOString().split('T')[0]; // yyyy-mm-dd
  public forestillinger_by_sted;
  public form = new FormGroup({
    dato: new FormControl(this.today, Validators.required),
  });

  constructor(
    @Inject(MDC_DIALOG_DATA) private data,
    private contentService: ContentService
  ) {}

  ngOnInit() {
    // hent data hvis vi ikke ble mata ved oppstart (etter z-rapport) ...
    if (this.data && this.data.dagens) {
      this.forestillinger_by_sted = this.data.dagens;
    } else {
      this.fetchData();
    }
    // ...og når datoen endres i formen
    this.form.valueChanges.subscribe( changes => {
      if (changes && changes.dato) {
        this.fetchData();
      }
    });
  }

  public calcSpillestedTotal(forestillinger) {
    return forestillinger.reduce((total, forestilling) => total + forestilling.n_solgt, 0);
  }

  private fetchData() {

    delete this.forestillinger_by_sted;
    this.form.disable({ emitEvent: false });
    this.form.markAsPristine();

    this.contentService.fetchContent(
      `/salg/mineforestillinger/${this.form.controls.dato.value}`
    ).subscribe( dagens => {
      // lag en komplett, sortert forestillingsliste...
      const forestillinger = [];
      dagens.ringer.forEach( ring => forestillinger.push(...ring.forestillinger) );
      forestillinger.sort(sortByTidspunkt_ASC);
      // ...og gruppér på spillested
      this.forestillinger_by_sted = this.groupForestillingerBySted(forestillinger);
      this.form.enable({ emitEvent: false });
    } );
  }

  private groupForestillingerBySted(forestillinger) {
    // gruppér på sted...
    const grouped = {};
    forestillinger.forEach(f => {
      let sid = f.spillested.id
      if (grouped[sid]) {
        grouped[sid].push(f);
      } else {
        grouped[sid] = [f];
      }
    })
    // og sortér på tidspunkt
    return Object.values(grouped).map(
      (sted:Array<any>) => sted.sort(sortByTidspunkt_ASC)
    ).sort((a, b) => sortByTidspunkt_ASC(a[0], b[0]))
  }

  public print(forestillinger) {
    printBesoksrapport({
      dato: forestillinger[0].dato,
      spillested: forestillinger[0].spillested,
      maskinist: this.data.maskinist,
      forestillinger: forestillinger
    });
  }

}
