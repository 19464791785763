import { Component, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { MdcDialogRef } from '@angular-mdc/web';
import { FormControl, Validators } from '@angular/forms';
import { ContentService } from 'src/app/content.service';
import { map } from 'rxjs/operators';
import QrScanner from 'qr-scanner';
QrScanner.WORKER_PATH = '/assets/qr-scanner-worker.min.js';

@Component({
  selector: 'app-tilgodebillett-betaling',
  templateUrl: './tilgodebillett-betaling.component.html',
  styleUrls: ['./tilgodebillett-betaling.component.scss']
})
export class TilgodebillettBetalingComponent implements OnDestroy, AfterViewInit {

  @ViewChild('scanVideoElm', { static: true }) private scanVideoElm;
  private qrScanner;

  public billettnummer = new FormControl(
    '', {
      updateOn: 'change',/* FIXME - mdc-bug? - oppdateres på blur */
      validators: [Validators.required, Validators.minLength(8), Validators.maxLength(32)],
      asyncValidators: this.sjekkBillettkode.bind(this)
    }
  );
  public tilgodebillett_kode: string;

  constructor(
    private dialogRef: MdcDialogRef<TilgodebillettBetalingComponent>,
    private contentService: ContentService
  ) {}

  ngAfterViewInit() {
    this.qrScanner = new QrScanner(this.scanVideoElm.nativeElement, this.billettScanned.bind(this));
    this.qrScanner.start();
  }

  ngOnDestroy() {
    this.qrScanner.stop();
  }

  private sjekkBillettkode(control) {
    /* {
      "res": "OK", 
      "tilgodebillett_kode": "4755fef1b92f4d578b7c0907082030c6", 
      "gyldig_til": "2020-06-10T14:37:10.291968+02:00"
    } */
    // http://localhost:5000/api/v1/salg/tilgodebillettsjekk/4755fef1b92f4d57
    return this.contentService.fetchContent(`/salg/tilgodebillettsjekk/${control.value}`)
      .pipe(
        map(res => {
          if (res.res === 'OK') {
            this.tilgodebillett_kode = res.tilgodebillett_kode;
            return null;
          } else {
            this.tilgodebillett_kode = null;
            return { tilgodebillett_err: res };
          }
        })
      );
  }

  getFeilmelding() {
    const err = this.billettnummer.errors.tilgodebillett_err;
    if (err.res === 'NOTFOUND') {
      return 'Ugyldig billettkode'
    } else {
      return err.msg
    }
  }

  billettScanned(uuid) {
    if (uuid) {
      this.qrScanner.stop();
      this.billettnummer.setValue(uuid);
    }
  }

  betalMedTilgodebillett() {
    this.dialogRef.close({
      tilgodebillett_kode: this.tilgodebillett_kode
    });
  }

}