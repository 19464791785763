<mdc-dialog>
  <mdc-dialog-container>
    <mdc-dialog-surface>

      <mdc-dialog-title>Besøksrapport</mdc-dialog-title>

      <mdc-dialog-content>

        <form [formGroup]="form" class="dialog-form">
          <mdc-text-field type="date" outlined dense placeholder="Dato" formControlName="dato"></mdc-text-field>
        </form>

        <p *ngIf="forestillinger_by_sted?.length === 0">
          Ingen forestillinger denne dagen
        </p>

        <mdc-list *ngIf="forestillinger_by_sted?.length" class="steder">
          <ng-container *ngFor="let forestillinger of forestillinger_by_sted">
            <p mdc-list-item class="text__medium-emphasis">
              {{ forestillinger[0].spillested.navn }},
              {{ forestillinger[0].spillested.stedsnavn }}
              (totalt antall: {{ calcSpillestedTotal(forestillinger) }})
            </p>
            <button mdc-button raised primary (click)="print(forestillinger)">Skriv ut</button>
          </ng-container>
        </mdc-list>

        <app-spinner *ngIf="!forestillinger_by_sted"></app-spinner>

      </mdc-dialog-content>

      <mdc-dialog-actions>
        <button mdc-button class="button-no-bg" mdcDialogAction="close">Lukk</button>
      </mdc-dialog-actions>

    </mdc-dialog-surface>
  </mdc-dialog-container>
</mdc-dialog>