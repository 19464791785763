import { Component, OnInit } from '@angular/core';
import { MdcDialogRef } from '@angular-mdc/web';

@Component({
  selector: 'app-dialog-refundering',
  templateUrl: './dialog-refundering.component.html',
  styleUrls: ['./dialog-refundering.component.scss']
})
export class DialogRefunderingComponent implements OnInit {

  constructor(private dialogRef: MdcDialogRef<any>) { }

  ngOnInit(): void {
  }

  refunder() {
    this.dialogRef.close('refund');
  }

}
