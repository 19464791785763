<mdc-dialog>
  <mdc-dialog-container>
    <mdc-dialog-surface>
      <mdc-dialog-title>Gjennomfør dagsoppgjør</mdc-dialog-title>
      <mdc-dialog-content>Ønsker du å fullføre dagens salg og lukke kassen?</mdc-dialog-content>
      <mdc-dialog-actions>
        <button mdc-button class="button-no-bg" mdcDialogAction="close">Avbryt</button>
        <button mdc-button raised mdcDialogAction="accept">Ja, lukk kasse</button>
      </mdc-dialog-actions>
    </mdc-dialog-surface>
  </mdc-dialog-container>
</mdc-dialog>