<mdc-dialog>
    <mdc-dialog-container>
        <mdc-dialog-surface>
            <mdc-dialog-title>Endre film</mdc-dialog-title>
            <mdc-dialog-content>
                <div class="sok" *ngIf="! form.value.film">
                  <form [formGroup]="searchForm" (ngSubmit)="sok()">
                    <p>Søk etter film:</p>
                    <div  class="nyforestilling__sok">
                      <mdc-text-field #search outlined dense label="Søk" formControlName="searchField"></mdc-text-field>
                      <button mdc-button unelevated type="submit">Søk</button>
                    </div>
  
                  </form>
                  <section class="film-list">
                    <mdc-list>
                      <mdc-list-item class="liste__film" (click)="velgFilm(film)" *ngFor="let film of filmer">
                          <span>{{film.norsktittel}}</span><span class="film_versjon">{{ film.version_subtitle }}</span>
                      </mdc-list-item>
  
                    </mdc-list>
                    <div mdcSubtitle1 *ngIf="filmer && filmer.length === 0" class="no-match">Ingen treff matcher ditt søk</div>
                    </section>
                  </div>
                  <div *ngIf="form.value.film">
                      Valgt film: {{ form.value.film.norsktittel }} <span class="film_versjon">{{ form.value.film.version_subtitle }}</span><button mdc-button class="button-no-bg" (click)="fjernFilm()"><mdc-icon>clear</mdc-icon></button>
                  </div>
            </mdc-dialog-content>
            <mdc-dialog-actions>
              <button mdc-button class="button-no-bg" mdcDialogAction="close">Avbryt</button>
              <button *ngIf="form.value.film" mdc-button raised (click)="lagre()">ENDRE</button>
            </mdc-dialog-actions>
            
        </mdc-dialog-surface>
    </mdc-dialog-container>
  </mdc-dialog>
  
  