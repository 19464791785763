import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})

export class AuthGuardService implements CanActivate {
  constructor(public auth: AuthService, public router: Router) {}
  public urlBeforeLogin;

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot) {
    return this.auth.isAuthenticated()
      .pipe(
        map(isAuthenticated => {
          if (!isAuthenticated) {
            this.urlBeforeLogin = state.url;
            this.router.navigate(['/login']);
            return false;
          } else {
            return true;
          }
        })
      );
  }
}
