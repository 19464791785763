import { Component } from '@angular/core';
import { MdcDialogRef } from '@angular-mdc/web';
import { FormControl, Validators, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-faktura-betaling',
  templateUrl: './faktura-betaling.component.html',
  styleUrls: ['./faktura-betaling.component.scss']
})
export class FakturaBetalingComponent {
  
  public formen = new FormGroup({
    fakturamottaker: new FormControl('', {updateOn: 'change', validators: Validators.required}),
    fakturareferanse: new FormControl(),
    fakturaadresse: new FormControl('', {updateOn: 'change', validators: Validators.required}),
    skip_billett_print: new FormControl(false)
  })

  constructor(
    private dialogRef: MdcDialogRef<FakturaBetalingComponent>
  ) {}

  submit() {
    this.dialogRef.close(this.formen.value);
  }

}
