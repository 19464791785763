import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { AuthService } from 'src/app/auth.service';
import { ContentService } from 'src/app/content.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-landingsside-admin',
  templateUrl: './landingsside-admin.component.html',
  styleUrls: ['./landingsside-admin.component.scss']
})
export class LandingssideAdminComponent implements OnInit {
  public userInfo;
  public upubliserteForestillinger = [];
  public aapneKasser;
  public brukerveiledninglenke;

  public henterForestillinger = false;
  public henterKasser = false;

  constructor(
    private auth: AuthService,
    private contentService: ContentService) { }

  ngOnInit() {
    this.userInfo = this.auth.getUserInfo();
    this.hentAapneKasser();
    this.hentUpubliserteForestillinger();
  }

  hentUpubliserteForestillinger() {
    this.henterForestillinger = true;
    this.contentService.fetchContent('/forestilling/upublisert')
      .subscribe(res => {
        this.upubliserteForestillinger = res;
        this.henterForestillinger = false;
      });
  }

  hentAapneKasser() {
    this.henterKasser = true;
    this.contentService.fetchContent('/admin/kasser/aapne')
      .subscribe(res => {
        this.aapneKasser = res;
        this.henterKasser = false;
      })
  }

  hentBackendUrl() {
    let backendurl = environment.production ? window.origin : 'http://localhost:5000';
    return backendurl;
  }
}
