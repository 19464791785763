import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppMaterialModule } from './material/material.module';

import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NavigasjonComponent } from './navigasjon/navigasjon.component';
import { LoginComponent } from './login/login.component';
import { ResetPassordComponent } from './reset-passord/reset-passord.component';
import { SalgModule } from './salg/salg.module';
import { CommonComponentsModule } from './common-components/common-components.module';

/* BUGSNAG */
import Bugsnag from '@bugsnag/js'
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular'
import { ErrorHandler } from '@angular/core';
import { PageComponent } from './page/page.component';
import { LandingssideModule } from './landingsside/landingsside.module';

const bugsnagClient = Bugsnag.start({
  apiKey: environment.bugsnagApiKey,
  releaseStage: environment.bugsnagStage
});

export function errorHandlerFactory() {
  return new BugsnagErrorHandler(bugsnagClient)
}

@NgModule({
  declarations: [
    AppComponent,
    NavigasjonComponent,
    LoginComponent,
    ResetPassordComponent,
    PageComponent
  ],
  imports: [
    BrowserModule,
    LandingssideModule,
    AppRoutingModule,
    AppMaterialModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SalgModule,
    CommonComponentsModule
  ],
  providers: [
    { provide: ErrorHandler, useFactory: errorHandlerFactory }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
