<mdc-dialog>
  <mdc-dialog-container>
    <mdc-dialog-surface>
      <mdc-dialog-title>Tilgodebillett</mdc-dialog-title>
      <mdc-dialog-content>
        <h3>Scann QR-kode</h3>
        <video #scanVideoElm></video>
        <h3>Eller skriv inn billettnummer:</h3>
        <div class="tilgodebillett__kode">
          <mdc-text-field outlined [formControl]="billettnummer" label="Nummer"></mdc-text-field>
        </div>
        <div class="melding">
          <div class="invalid" *ngIf="billettnummer?.errors?.tilgodebillett_err">{{getFeilmelding()}}</div>
          <div class="invalid" *ngIf="billettnummer?.errors?.minlength">Minst 8 tegn</div>
          <div class="invalid" *ngIf="billettnummer?.errors?.maxlength">Maks 32 tegn</div>
          <div class="valid" *ngIf="billettnummer.valid && tilgodebillett_kode">OK: {{tilgodebillett_kode}}</div>
        </div>
      </mdc-dialog-content>
      <mdc-dialog-actions>
        <button mdc-button class="button-no-bg" mdcDialogAction="close">Avbryt</button>
        <button mdc-button raised [disabled]="!billettnummer.valid" (click)="betalMedTilgodebillett()">Bruk</button>
      </mdc-dialog-actions>
    </mdc-dialog-surface>
  </mdc-dialog-container>
</mdc-dialog>
  