import { MDC_DIALOG_DATA } from '@angular-mdc/web';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'app-dialog-refundering-tilbakemelding',
  templateUrl: './dialog-refundering-tilbakemelding.component.html',
  styleUrls: ['./dialog-refundering-tilbakemelding.component.scss']
})
export class DialogRefunderingTilbakemeldingComponent implements OnInit {
  public billetter;
  public gavekort;
  public refundering;

  constructor(@Inject(MDC_DIALOG_DATA) private data) { }

  ngOnInit(): void {
    this.refundering = this.data;
    this.billetter = this.refundering.original_ordre.billetter;
    this.gavekort = this.refundering.original_ordre.gavekort;
  }

}
