<mdc-dialog>
  <mdc-dialog-container>
    <mdc-dialog-surface>
<!-- FIXME?      <mdc-dialog-title>{{rapportData.rapport}}</mdc-dialog-title> -->
      <mdc-dialog-content>
        <div [innerHTML]="rapportHTML | safeHtml"></div>
      </mdc-dialog-content>
      <mdc-dialog-actions>
        <button mdc-button class="button-no-bg" mdcDialogAction="close">Lukk</button>
        <button mdc-button raised [disabled]="!rapportData" (click)="print()">Skriv ut</button>
      </mdc-dialog-actions>
    </mdc-dialog-surface>
  </mdc-dialog-container>
</mdc-dialog>