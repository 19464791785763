import { NgModule } from '@angular/core';
import {
  MdcCardModule,
  MdcFabModule,
  MdcFormFieldModule,
  MdcRadioModule,
  MdcTextFieldModule,
  MdcButtonModule,
  MdcDrawerModule,
  MdcIconModule,
  MdcListModule,
  MdcTypographyModule,
  MdcSelectModule,
  MdcCheckboxModule,
  MdcDialogModule,
  MdcTabBarModule,
  MdcSnackbarModule,
  MDCDataTableModule,
  MdcIconButtonModule
} from '@angular-mdc/web';

@NgModule({
  exports: [
    MdcCardModule,
    MdcFabModule,
    MdcFormFieldModule,
    MdcRadioModule,
    MdcTextFieldModule,
    MdcButtonModule,
    MdcDrawerModule,
    MdcIconModule,
    MdcListModule,
    MdcTypographyModule,
    MdcSelectModule,
    MdcCheckboxModule,
    MdcDialogModule,
    MdcTabBarModule,
    MdcSnackbarModule,
    MDCDataTableModule,
    MdcIconButtonModule
  ]
})
export class AppMaterialModule { }
