import { Component, Input, OnInit, OnChanges } from '@angular/core';

@Component({
  selector: 'app-salkart',
  templateUrl: './salkart.component.html',
  styleUrls: ['./salkart.component.scss']
})
export class SalkartComponent implements OnInit, OnChanges {

  @Input() forestillinginfo: Forestillinginfo;
  @Input() mini = false;

  public grid: Array<Array<SalSeat|string>> = [];

  constructor() {}

  ngOnInit() {
    this.makeGrid();
  }

  ngOnChanges() {
    this.makeGrid();
  }

  makeGrid() {
    const sal = this.forestillinginfo.sal;
    this.grid.length = 0;
    if (!sal || !sal.saldata) {
      return;
    }
    for (let row of sal.saldata.rows) {
      const gridrow = [];
      for (let sete of row.seats) {
        if (sete) {
          gridrow.push(sete);
        } else {
          gridrow.push(null);
        }
      }
      this.grid.push(gridrow);
    }
  }

  getViewBox() {
    if (this.grid.length) {
      return `0 0 ${this.grid[0].length} ${this.grid.length}`;
    } else {
      return '0 0 1 1';
    }
  }

  seatOccupied(seat) {
    return this.forestillinginfo.seats_occupied.includes(seat.i);
  }

  seatBlocked(seat) {
    return this.forestillinginfo.seats_blocked.includes(seat.i);
  }

  seatToType(seat) {
    if (seat && seat !== 'noseat') {
      const re = ['seat', 'sid-' + seat.i];
      if (seat.t === '@') {
        re.push('wheelchair');
      }
      if (this.seatOccupied(seat)) {
        re.push('occupied');
      }
      if (this.seatBlocked(seat)) {
        re.push('blocked');
      }
      return re.join(' ');
    } else {
      return 'noseat'
    }
  }

}
