import { Component, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ContentService } from 'src/app/content.service';
import { MdcDialogAction } from '@angular-mdc/web';
import { map } from 'rxjs/operators';
import QrScanner from 'qr-scanner';
QrScanner.WORKER_PATH = '/assets/qr-scanner-worker.min.js';

@Component({
  selector: 'app-sjekk-gavekort',
  templateUrl: './sjekk-gavekort.component.html',
  styleUrls: ['./sjekk-gavekort.component.scss']
})
export class SjekkGavekortComponent implements AfterViewInit, OnDestroy {

  @ViewChild('scanVideoElm', { static: false }) private scanVideoElm;

  public resultat;
  public paused = false;
  public gavekortnummer = new FormControl(
    '', {
      updateOn: 'change',/* FIXME - mdc-bug? - oppdateres på blur */
      validators: [Validators.required, Validators.minLength(8), Validators.maxLength(32)],
      asyncValidators: this.sjekkGavekortkode.bind(this)
    }
  );
  private qrScanner;

  constructor(
    private contentService: ContentService
  ) {}

  ngAfterViewInit() {
    this.qrScanner = new QrScanner(this.scanVideoElm.nativeElement, this.gavekortScanned.bind(this));
    this.startScan(true);
  }

  ngOnDestroy() {
    this.qrScanner.destroy();
  }

  matchUUIDs(full: string, partial: string): boolean {
    return (full.indexOf(partial) === 0);
  }

  sjekkGavekortkode(control) {
    return this.contentService.fetchContent(`/salg/gavekortsjekk/${control.value}`)
      .pipe(
        map(res => {
          this.resultat = res;
          if (res.res === 'OK') {
            this.pauseScan();
            return null;
          } else {
            return { gavekort_err: res };
          }
        })
      );
  }

  getFeilmelding() {
    const err = this.gavekortnummer.errors.gavekort_err;
    if (err.res === 'NOTFOUND') {
      return 'Ugyldig gavekortkode'
    } else {
      return err.msg
    }
  }

  pauseScan() {
    this.paused = true;
    this.qrScanner.pause();
  }

  startScan(initial = false) {
    if (!initial) {
      delete this.resultat;
      this.gavekortnummer.setValue('');
    }
    this.paused = false;
    this.qrScanner.start();
  }

  gavekortScanned(uuid) {
    if (uuid) {
      this.pauseScan();
      this.gavekortnummer.setValue(uuid);
    }
  }

}
