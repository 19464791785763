import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minutesToHours'
})
export class MinutesToHoursPipe implements PipeTransform {

  transform(value: number): string {
    const hours = Math.floor((value / 60));
    const minutes = value % 60;
    let formattedString = hours ? hours + 't ' : '';
    formattedString += minutes + 'm';
    return formattedString;
  }
}
