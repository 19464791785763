import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MdcDialog } from '@angular-mdc/web/dialog';

import { KampanjeBetalingComponent } from '../dialogs/kampanje-betaling/kampanje-betaling.component';
import { FribillettBetalingComponent } from '../dialogs/fribillett-betaling/fribillett-betaling.component';

// FIXME: flytt typing?
interface Produkt {
  billetttype: BillettType /* ugrei modell */
  nb_kode?: string
  kb_kode?: string
}

@Component({
  selector: 'app-andre-produkter',
  templateUrl: './andre-produkter.component.html',
  styleUrls: ['./andre-produkter.component.scss']
})
export class AndreProdukterComponent {

  @Input() forestilling_id: string;
  @Input() disabled: boolean;
  @Output() betalt = new EventEmitter<Betaling>();// trenger vi denne til gavekort?
  @Output() addProduct = new EventEmitter<Produkt>();

  constructor(
    private dialog: MdcDialog
  ) {}

  addStab() {// FIXME: rename FribillettBetalingComponent til StabBetalingComponent
    this.dialog.open(FribillettBetalingComponent)
      .afterClosed().subscribe(action => {
        if (action === 'accept') {
          this.addProduct.emit({ billetttype: 'stab' });
        }
      });
  }

  addLedsager() {
    this.addProduct.emit({ billetttype: 'ledsager' });
  }

  betalKampanje() {
    this.dialog.open(KampanjeBetalingComponent, { data: { forestilling_id: this.forestilling_id } })
      .afterClosed().subscribe((res: any) => {
        if (!res) {
          console.warn('Uhåndtert tilstand');
        } else if (res.nb_kode) {
          this.addProduct.emit({
            billetttype: 'norgesbillett',
            nb_kode: res.nb_kode
          });
        } else if (res.kb_kode) {
          //throw 'ikke implementert';
          this.addProduct.emit({
            billetttype: 'kampanje',
            kb_kode: res.kb_kode
          })
        }
      });
  }

}


