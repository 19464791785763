<svg xmlns="http://www.w3.org/2000/svg"
    *ngIf="grid?.length"
    [class.minimodus]="mini"
    [attr.viewBox]="getViewBox()" width="100%" height="100%">
    <g *ngFor="let row of grid; let y = index" class="row">
        <rect *ngFor="let seat of row; let x = index"
            [attr.class]="seatToType(seat)"
            [attr.x]="x"
            [attr.y]="y"
            width="0.9"
            height="0.9"
        />
    </g>
</svg>
