import { Component, Inject } from '@angular/core';
import { MDC_DIALOG_DATA } from '@angular-mdc/web';

import { environment } from '../../../../environments/environment';
import QRCode from 'qrcode';

@Component({
  selector: 'app-billettscan-lenke',
  templateUrl: './billettscan-lenke.component.html',
  styleUrls: ['./billettscan-lenke.component.scss']
})
export class BillettscanLenkeComponent {

  public url: String
  public qr: String;

  // Forventer dataobjekt med spillested_id og spillested_tittel
  constructor(
    @Inject(MDC_DIALOG_DATA) public forestillinginfo: Forestillinginfo
  ) {
    let url = environment.production ? window.origin + '/billettscan/index.html' : 'http://localhost:8570';
    this.url = url + '?spillested=' + this.forestillinginfo.spillested_id;
    QRCode.toString(
      this.url, { type: 'svg', errorCorrectionLevel: 'H', mode: 'alphanumeric' }
    ).then(
      svg => this.qr = svg
    );
  }

}