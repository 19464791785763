<mdc-dialog>
  <mdc-dialog-container>
    <mdc-dialog-surface>
      <mdc-dialog-title>Kupong</mdc-dialog-title>
      <mdc-dialog-content>
        <h2>Norgesbillett eller kampanje</h2>
        <div class="kampanje__kode">
          <mdc-text-field outlined [formControl]="kodeinput" label="Kode" (keydown.ENTER)="sjekk()"></mdc-text-field>
        </div>
        <div class="melding">
          <div class="invalid" *ngIf="feilmelding">{{ feilmelding }}</div>
        </div>
        <app-spinner *ngIf="pending" [inline]="true"></app-spinner>
      </mdc-dialog-content>
      <mdc-dialog-actions>
        <button mdc-button class="button-no-bg" mdcDialogAction="close">Avbryt</button>
        <button mdc-button raised [disabled]="!kodeinput.valid" (click)="sjekk()">OK</button>
      </mdc-dialog-actions>
    </mdc-dialog-surface>
  </mdc-dialog-container>
</mdc-dialog>
