
import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { MdcDialogRef } from '@angular-mdc/web';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-oppgi-billettmottaker-epost',
  templateUrl: './oppgi-billettmottaker-epost.component.html',
  styleUrls: ['./oppgi-billettmottaker-epost.component.scss']
})
export class OppgiBillettmottakerEpostComponent implements AfterViewInit {

  @ViewChild('initialfocuselem', {static: false}) initialfocuselem;

  public formen = new FormGroup({
    epost: new FormControl('', [Validators.required, Validators.email])
  })

  constructor(
    private dialogRef: MdcDialogRef<OppgiBillettmottakerEpostComponent>
  ) {}

  ngAfterViewInit() {
    setTimeout(() => this.initfocus(), 200);
  }

  submit(event) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    this.dialogRef.close( this.formen.value );
  }

  initfocus() {
    let el = this.initialfocuselem;
    if (el) {
      if (el.nativeElement) {
        el = el.nativeElement;
      } else if (el.elementRef && el.elementRef.nativeElement) {
        el = el.elementRef.nativeElement;
      }
      if (el.querySelector) {
        el.querySelector('input').focus();
      }
    }
  }

}
