<mdc-dialog>
  <mdc-dialog-container>
    <mdc-dialog-surface>
      <mdc-dialog-title>Legg til forestilling</mdc-dialog-title>
      <mdc-dialog-content>

        <div *ngIf="ruteplan?.length == 1">
          Sted: {{ ruteplan[0].sted }}
        </div>
        <div *ngIf="ruteplan?.length > 1" [formGroup]="form">
          Sted:
          <mdc-select class="form__element" outlined placeholder="Sted" formControlName="ruteplan_id">
            <mdc-menu>
              <mdc-list>
                <mdc-list-item [value]=""></mdc-list-item>
                <mdc-list-item *ngFor="let dag of ruteplan" [value]="dag.id">{{ dag.sted }}</mdc-list-item>
              </mdc-list>
            </mdc-menu>
          </mdc-select>
        </div>

        <div class="sok" *ngIf="!form.value.film_id">

          <form [formGroup]="searchForm" (ngSubmit)="sok()">
            <p>Søk etter film:</p>
            <div class="nyforestilling__sok">
              <mdc-text-field outlined dense label="Søk" formControlName="searchField"></mdc-text-field>
              <button mdc-button unelevated type="submit">Søk</button>
            </div>
          </form>

          <section class="film-list">
            <mdc-list>
              <mdc-list-item class="liste__film" (click)="velgFilm(film)" *ngFor="let film of filmer">
                <span>{{film.norsktittel}}</span><span class="film_versjon">{{ film.version_subtitle }}</span>
              </mdc-list-item>
            </mdc-list>
            <div mdcSubtitle1 *ngIf="filmer && filmer.length === 0" class="no-match">Ingen treff matcher ditt søk</div>
          </section>

        </div>

        <div *ngIf="form.value.film_id">
          <h2 class="nyforestilling__valgtfilm">
            <button mdc-button class="button-no-bg" (click)="fjernFilm()">
              <mdc-icon>clear</mdc-icon>
            </button> {{ valgtFilm.norsktittel }} <span class="film_versjon">{{ valgtFilm.version_subtitle }}</span>
          </h2>
          <form [formGroup]="form" (ngSubmit)="leggTilForestilling()">
            <div class="nyforestilling__tidspunkt">
              <mdc-text-field outlined dense label="Tidspunkt" type="time" formControlName="tidspunkt"></mdc-text-field>
              <button mdc-button raised [disabled]="!form.valid" type="submit">Legg til</button>
            </div>
          </form>
        </div>

      </mdc-dialog-content>

      <mdc-dialog-actions>
        <button mdc-button class="button-no-bg" mdcDialogAction="close">Avbryt</button>
      </mdc-dialog-actions>

    </mdc-dialog-surface>
  </mdc-dialog-container>
</mdc-dialog>