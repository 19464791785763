
<mdc-dialog>
    <mdc-dialog-container>
      <mdc-dialog-surface>
        <mdc-dialog-title>Selg gavekort</mdc-dialog-title>
        <mdc-dialog-content>
          <form [formGroup]="gavekortForm">
            <div formArrayName="gavekortListe">
              <ng-container *ngFor="let gavekort of gavekortListe.controls; let i = index;" [formGroupName]="i">
                <div (ngSubmit)="onSubmit()">
                  <mdc-text-field outlined [formControl]="gavekort.controls['antall']" label="Antall" type="number" (onkeypress)="updateValues()"></mdc-text-field> stk &nbsp;
                  <mdc-text-field outlined [formControl]="gavekort.controls['verdi']" label="Verdi" type="number" (onkeypress)="updateValues()"></mdc-text-field> kr
                </div>
              </ng-container>
              <button mdc-button unelevated (click)="addGavekort()"><mdc-icon>add</mdc-icon> Legg til gavekort</button>
            </div>
          </form>
          <div class="gavekort__status">
            <p>Valgt {{numberOfGavekort}} gavekort til en totalverdi av kr {{totalValue}}</p>
          </div>
        </mdc-dialog-content>
        <mdc-dialog-actions>
            <button mdc-button class="button-no-bg" mdcDialogAction="close">Avbryt</button>
            <button mdc-button raised (click)="submit()">OK</button>
          </mdc-dialog-actions>
      </mdc-dialog-surface>
    </mdc-dialog-container>
</mdc-dialog>
