<section class="login-page">
  <header>
      <h1 mdcHeadline1>Kinologg</h1>
  </header>

  <form *ngIf="!showResetPwd" [formGroup]="login" (ngSubmit)="logIn()">
      <section class="login-form">
          <mdc-text-field outlined label="Brukernavn" formControlName="username"></mdc-text-field>
          <mdc-text-field type="password" outlined label="Passord" formControlName="password"></mdc-text-field>
          <div class="login__actions">
              <button mdc-button type="submit" primary raised>Logg inn</button>
          </div>
          <div class="form-error"></div>
      </section>
  </form>

  <div class="password-reset">
    <button mdc-button class="button-no-bg" (click)="toggleReset()">{{showResetPwd ? 'Tilbake' : 'Glemt Passord'}}</button>
  </div>

  <section *ngIf="showResetPwd && !resetConfirm">
    <form [formGroup]="reset" (ngSubmit)="resetPwd()">
      <mdc-text-field type="text" outlined label="Brukernavn" formControlName="username"></mdc-text-field>
      <div class="login__actions">
        <button
          mdc-button
          type="submit"
          primary
          raised
          [disabled]="!reset.valid"
        >Send reset-passord lenke</button>
      </div>
      <div class="form-error"></div>
    </form>
  </section>
  <section  *ngIf="resetConfirm">
    <h3>Epost med instruksjoner sendt hvis brukernavnet er gyldig. Sjekk spamfolder osv.</h3>
  </section>

  <a mdcBody1 class="link-for-spillested" routerLink="/plan-spillested">Oversikt for spillesteder</a>
</section>