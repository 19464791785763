import {environment} from '../environments/environment';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';


// import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  constructor(
    private http: HttpClient
  ) {}

  public getSearchResult(url: string, field, query, modifier?) {
    let filter;
    if (modifier) {
      filter = `{"${field}":"ilike(\\"%${query}%\\")",${modifier}}`;
    } else {
      filter = `{"${field}":"ilike(\\"%${query}%\\")"}`;
    }

    const httpOptions = {
      withCredentials: true,
      params: {where: filter, sort: field}
    };

    return this.http.get<any>(environment.BACKEND_SERVER_URL + url, httpOptions);

  }

  private errorHandler(err) {
    if (err.status === 401) {
      window.location.reload(); // trenger vi force reload her?
    } else {
      if (err.error && err.error.message) {
        alert(err.error.message);
      } else if (err.error && err.error._issues) {
        alert(
          Object.entries(err.error._issues).map(entry => {
            return entry[0] + ' : ' + entry[1];
          }).join('\n')
        );
      } else {
        alert('Feil på url: ' + err.url + '\n Status code: ' + err.status + '\n' + err.message);
      }
    }
    return throwError(err);
  }

  public sessionInfo() {
    return this.http.get(environment.BACKEND_SERVER_URL + '/sessioninfo', {withCredentials: true})
      .pipe(
        catchError(this.errorHandler)
      );
  }

  public login(userInfo) {
    const content = {
      username: userInfo.username,
      password: userInfo.password
    };
    const httpOptions = {
      withCredentials: true
    };
    return this.http.post(environment.BACKEND_SERVER_URL + '/login', content, httpOptions);
  }

  public resetPassword(username) {
    const content = {
      username: username
    };
    const httpOptions = {
      withCredentials: true
    };
    return this.http.post(environment.BACKEND_SERVER_URL + '/iforgot', content, httpOptions)
      .pipe(
        catchError(this.errorHandler)
      );
  }

  public setPassword(token, password) {
    const content = {
      token: token,
      password: password,
    };
    const httpOptions = {
      withCredentials: true
    };
    return this.http.post(environment.BACKEND_SERVER_URL + '/resetpassword', content, httpOptions)
      .pipe(
        catchError(this.errorHandler)
      );
  }

  public logout() {
    return this.http.get(environment.BACKEND_SERVER_URL + '/logout', {withCredentials: true})
      .pipe(
        catchError(this.errorHandler)
      );
  }

  public fetchAktiveSpillesteder() {
    const params: any = { aktiv: true};
    return this.http.get<any>(environment.BACKEND_SERVER_URL + '/spillested', {params: params, withCredentials: true})
      .pipe(
        catchError(this.errorHandler)
      );
  }

  public fetchAktiveMaskinister() {
    const params: any = {
      max_results: 2000,/* ignoreres? vi får max 1000 */
      sort: 'navn',
      where: '{"aktivpassiv": true}',
      projection: '{"id": 1, "navn": 1, "nr": 1}'
    }
    return this.http.get<any>(
      environment.BACKEND_SERVER_URL + '/maskinist?', { withCredentials: true, params }
    )
      .pipe(
        catchError(this.errorHandler)
      );
  }

  public fetchContentWithQueryParams(url, data) {
    return this.http.get<any>(environment.BACKEND_SERVER_URL + url, {params: data, withCredentials: true})
      .pipe(
        catchError(this.errorHandler)
      );
  }

  // fetchcontent for filmbestilling without error-pipe
  public checkBestilling(id) {
    return this.http.get<any>(environment.BACKEND_SERVER_URL + '/filmbestilling/' + id, {withCredentials: true});
  }

  public fetchContent(url: string) {
    // const backend = environment.backendServerUrl ? environment.backendServerUrl : '';
    return this.http.get<any>(environment.BACKEND_SERVER_URL + url, {withCredentials: true})
      .pipe(
        catchError(this.errorHandler)
      );
  }

  public postContent(url: string, content) {
    return this.http.post(environment.BACKEND_SERVER_URL + url, content, {withCredentials: true})
      .pipe(
        tap((res: any) => {
          if (res && res.warnings && res.warnings.length) {
            if (res.warnings[0].txt) {
              res.warnings = res.warnings.map(warning => warning.txt)
            } else {
              // kortrefunderingsadvarsler kommer som array med strenger
            }
            alert(res.warnings.join('\n'));
          }
        }),
        catchError(this.errorHandler)
      );
  }

  public putContent(url: string, content) {
    return this.http.put(environment.BACKEND_SERVER_URL + url, content, {withCredentials: true})
      .pipe(
        catchError(this.errorHandler)
      );
  }

  public deleteContent(url: string) {
    return this.http.delete(environment.BACKEND_SERVER_URL + url, {withCredentials: true})
      .pipe(
        catchError(this.errorHandler)
      );
  }

  public addFilmToProgram(fromDate, toDate, filmId) {
    return this.http.post(environment.BACKEND_SERVER_URL + '/ring/rutefilmprogram/add_film',
    { from: fromDate, to: toDate, film_id: filmId }, {withCredentials: true})
      .pipe(
        catchError(this.errorHandler)
      );
  }

  public undoAddFilmToProgram(batchId) {
    return this.http.post(environment.BACKEND_SERVER_URL + '/ring/rutefilmprogram/undo/' + batchId, {}, {withCredentials: true})
      .pipe(
        catchError(this.errorHandler)
      );
  }

  public removeFilmFromProgram(fromDate, toDate, filmId) {
    return this.http.post(environment.BACKEND_SERVER_URL + '/ring/rutefilmprogram/removefilm_from_all',
    { from: fromDate, to: toDate, film_id: filmId }, {withCredentials: true})
      .pipe(
        catchError(this.errorHandler)
      );
  }

  public updateContent(url: string, content, eTag: string) {
    let httpOptions;
    if (eTag) {
      httpOptions = {
        withCredentials: true,
        headers: new HttpHeaders({
          'If-Match': eTag
          // ,
          // 'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8'
          // 'Content-Type': // 'application/json',
          // 'application/x-www-form-urlencoded; charset=UTF-8'
        })
      };
    } else {
        httpOptions = {withCredentials: true};
    }
    return this.http.patch(environment.BACKEND_SERVER_URL + url, content, httpOptions)
      .pipe(
        catchError(this.errorHandler)
      );
  }

  public lookupPostnummer(postnummer: string) {
    const bringUrl = 'https://api.bring.com/shippingguide/api/postalCode.json?clientUrl=www.ravn.no&pnr=' + postnummer;
    return this.http.get<any>(bringUrl);
  }

}
