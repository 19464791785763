<div class="ordre__status">
  <p class="ordre__billetter">Refundering: {{ num_tickets }} billett{{( num_tickets === 1 ? '' : 'er' )}}: {{ cash_sum }}</p>
  <p class="refund_som">Refunderes {{ refund_as }}</p>
  <div class="ordre__gjenstaaende">
    <p>Kontant tilbake: {{ data.refund_type === 'cash' ? cash_sum : 0 }}</p>
  </div>
  <div class="ordre__actions">
    <button mdc-button primary raised [disabled]="false" (click)="doRefund()"
    >Fullfør og skriv ut</button>
    <button mdc-button class="button-no-bg" outlined (click)="cancelRefund()">Avbryt</button>
  </div>
</div>