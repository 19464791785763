<mdc-dialog>
  <mdc-dialog-container>
    <mdc-dialog-surface>
      <mdc-dialog-title>Finn alternativ maskinist</mdc-dialog-title>
      <mdc-dialog-content>
        <div class="maskinist__sok">
          <mdc-text-field outlined [formControl]="sokefelt" label="Skriv et navn"></mdc-text-field>
        </div>
        <ul *ngIf="matches?.length" class="matches-list">
          <!-- [primary]="matches.length===1 ? '' : null" -->
          <li *ngFor="let maskinist of matches">
            {{maskinist.navn}}
            <button mdcButton (click)="velgMaskinist(maskinist)">Velg</button>
          </li>
        </ul>
      </mdc-dialog-content>
      <mdc-dialog-actions>
        <button mdcDialogButton mdcDialogAction="close">Avbryt</button>
      </mdc-dialog-actions>
    </mdc-dialog-surface>
  </mdc-dialog-container>
</mdc-dialog>