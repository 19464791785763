import { Component, OnChanges, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { fmtPaymentType, ordreToProduktliste } from '../print-stuff';

@Component({
  selector: 'app-ordre-status',
  templateUrl: './ordre-status.component.html',
  styleUrls: ['./ordre-status.component.scss']
})
export class OrdreStatusComponent implements OnChanges {
  @Output() public avsluttet = new EventEmitter<string>();
  @Output() public avbrutt = new EventEmitter<boolean>();
  @Output() public undo_transaksjon = new EventEmitter<string>(); // transaksjons-id
  @Output() public undo_billett = new EventEmitter<string>(); // billett-id

  @Input() public disabled = false;
  @Input() public ordre: Ordre;

  @ViewChild('initialfocuselem', {static: false}) initialfocuselem;

  public transaksjoner: any;
  public produkter: Array<any>;// FIXME: typing
  public kun_ledsagere = false;

  public fmtPaymentType = fmtPaymentType;

  public gavekort_saldo = -1;// -1 betyr 'ingen gavekortbetalinger'

  ngOnChanges(changes: SimpleChanges) {
    if (changes.ordre) {
      this.updateProdukter(this.ordre);
      this.updateTransaksjoner(this.ordre);
    }
  }

  private updateTransaksjoner(ordre: Ordre) {
    if (ordre.trans) {
      // filtrer bort transaksjoner brukerne ikke vil se
      this.transaksjoner = ordre.trans
        // internt bokholderi
        .filter( trans => trans.belop !== 0)
        // norgesbilletter, stab og ledsager
        .filter( trans =>
          trans.betalingstype !== 'norgesbillett' &&
          trans.betalingstype !== 'ledsager' &&
          trans.betalingstype !== 'stab'
        )
      ;
      // regn ut gavekortsaldo hvis det finnes betalinger med gavekort
      const gk = this.transaksjoner.filter( trans =>
        trans.betalingstype === 'gavekort' &&
        trans.er_korreksjon !== true &&
        trans.er_korrigert  !== true
      );
      if (gk.length) {
        this.gavekort_saldo = gk.reduce( (saldo, trans) => saldo + trans.betaling_info.rest_verdi - trans.belop, 0);
      } else {
        this.gavekort_saldo = -1;
      }
    } else {
      this.transaksjoner = [];
      this.gavekort_saldo = -1;
    }
  }

  private updateProdukter( ordre: Ordre ) {
    this.produkter = ordreToProduktliste(ordre, true);
    if (this.produkter.length === 1 && this.produkter[0].billetter[0].billetttype === 'ledsager') {
      this.kun_ledsagere = true;
    } else {
      this.kun_ledsagere = false;
    }
  }

  gjenstaaende() {
    if (this.ordre.trans) {
      const registrertBetalt = this.ordre.trans.reduce( (total, t) => total + t.belop, 0 );
      return this.ordre.sum - registrertBetalt;
    } else {
      return this.ordre.sum;
    }
  }

  gavekortSum() {
    if (this.ordre.gavekort) {
      return this.ordre.gavekort.reduce( (total, gk) => total + gk.verdi, 0);
    } else {
      return 0;
    }
  }

  absGjenstaaende() {
    return Math.abs(this.gjenstaaende());
  }

  avsluttMedUtskrift() {
    this.avsluttet.emit('utskrift');
  }

  avsluttMedEpost() {
    this.avsluttet.emit('epost');
  }

  cancelOrdre() {
    this.avbrutt.emit();
  }

  undoProdukt(produkt: any) {
    produkt.billetter.forEach(
      billett => this.undo_billett.emit( billett.id )
    );
  }

  undoTransaction(id) {
    this.undo_transaksjon.emit(id);
  }

  initfocus() {
    if (this.initialfocuselem) {
      this.initialfocuselem.elementRef.nativeElement.focus();
    }
  }

}
